import React, { FC } from "react";

import { IconContext } from "react-icons";
import { FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import { NurtureConfig } from "components/Nurture/interfaces";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type NurtureListItemProps = {
  nurtureConfig?: NurtureConfig;
  type: string;
  label: string;
};

const NurtureListItem: FC<NurtureListItemProps> = ({
  nurtureConfig,
  type,
  label,
}) => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const iconColor = nurtureConfig?.active
    ? "#3199cd"
    : "rgba(49, 153, 205, 0.3)";

  return (
    <li className="flex flex-row content-items-stretch border-b  border-opacity-20 border-text">
      <div className="flex flex-row flex-grow items-center">
        <IconContext.Provider
          value={{
            className: "mr-2",
            color: iconColor,
            size: "0.7em",
          }}
        >
          <div>
            <FaCircle />
          </div>
        </IconContext.Provider>
        {label}
      </div>
      <div className="flex items-center w-1/4 p-4">
        <Link
          className="w-full"
          to={`/${selectedMarket.subdomain}/${selectedCampaign.slug}/${type}`}
        >
          <Button text="Edit" appearance="primary" />
        </Link>
      </div>
    </li>
  );
};

export default NurtureListItem;
