import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { Currencies, Locales, Market } from "interfaces";
import { sortBy } from "lodash";

import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { CurrenciesContext } from "context/CurrenciesContext";
import { LocalesContext } from "context/LocalesContext";
import { MarketsContext } from "context/MarketsContext";

import { useAuth } from "../AuthProvider";

const GlobalContextWrapper: FC = ({ children }) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [markets, setMarkets] = useState<Market[]>([]);
  const [locales, setLocales] = useState<Locales>({});
  const [currencies, setCurrencies] = useState<Currencies>({});
  const { axios } = useAuth();

  const fetchMarketsRequest = (source: CancelTokenSource) => {
    return axios.get("/api/markets", {
      cancelToken: source.token,
    });
  };

  const fetchLocalesRequest = (source: CancelTokenSource) => {
    return axios.get("/api/markets/locales", {
      cancelToken: source.token,
    });
  };

  const fetchCurrenciesRequest = (source: CancelTokenSource) => {
    return axios.get("/api/markets/currencies", {
      cancelToken: source.token,
    });
  };

  const nameSorter = (market: { name: string }) => market.name.toLowerCase();

  const loadGlobals = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { markets: marketsResult },
        },
        {
          data: { locales: localesResult },
        },
        {
          data: { currencies: currenciesResult },
        },
      ] = await Promise.all([
        fetchMarketsRequest(source),
        fetchLocalesRequest(source),
        fetchCurrenciesRequest(source),
      ]);

      setMarkets(sortBy(marketsResult, [nameSorter]));
      setLocales(localesResult);
      setCurrencies(currenciesResult);

      setLoading(false);
    } catch (e) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();

    loadGlobals(source);

    return () => source.cancel();
  }, []);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <MarketsContext.Provider value={{ state: markets, setMarkets: setMarkets }}>
      <LocalesContext.Provider value={locales}>
        <CurrenciesContext.Provider value={currencies}>
          {children}
        </CurrenciesContext.Provider>
      </LocalesContext.Provider>
    </MarketsContext.Provider>
  );
};

export default GlobalContextWrapper;
