import React, { Dispatch, FC, SetStateAction } from "react";

import { ArchivedConfiguration, LiveConfiguration } from "interfaces";
import { useHistory } from "react-router";

import Button from "components/Button";
import { NoItemsWarning, TableHead } from "components/Table";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import EditingInfo from "../EditingInfo/EditingInfo";

type LiveConfigurationTableProps = {
  configuration: LiveConfiguration | null;
  hasDraftConfiguration: boolean;
  setBaseConfiguration: Dispatch<
    SetStateAction<LiveConfiguration | ArchivedConfiguration | null>
  >;
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
};

const LiveConfigurationTable: FC<LiveConfigurationTableProps> = ({
  configuration,
  hasDraftConfiguration,
  setBaseConfiguration,
  setShowCreateModal,
}) => {
  if (!configuration) {
    return (
      <NoItemsWarning>
        <p>There is no live configuration at the moment.</p>
        <p>Publish a draft to make it live.</p>
      </NoItemsWarning>
    );
  }

  const history = useHistory();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const handlePreview = (configurationId: string) => {
    history.push(
      `/${selectedMarket.subdomain}/${selectedCampaign.slug}/configuration/${configurationId}`
    );
  };

  const handleOnClick = () => {
    setBaseConfiguration(configuration);
    setShowCreateModal(true);
  };

  return (
    <table className="configurations-table">
      <TableHead headers={["Name", "Published by", "Created by", "", ""]} />
      <tbody>
        <tr className="table-row">
          <td className="table-primary-cell">{configuration.name}</td>
          <td className="table-secondary-cell">
            <EditingInfo
              email={configuration.published_by_email}
              date={configuration.published_at}
            />
          </td>
          <td className="table-secondary-cell">
            <EditingInfo
              email={configuration.created_by_email}
              date={configuration.created_at}
            />
          </td>

          <td className="table-secondary-cell">
            {!hasDraftConfiguration && (
              <Button
                appearance="secondary"
                text="Copy as a new draft"
                handleOnClick={handleOnClick}
              />
            )}
          </td>
          <td className="table-secondary-cell">
            <Button
              appearance="primary"
              text="Preview"
              handleOnClick={() => handlePreview(configuration.id)}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LiveConfigurationTable;
