import React, { FC } from "react";

const ArrowDownIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M7 9.5L11.6464 14.1464C11.8417 14.3417 12.1583 14.3417 12.3536 14.1464L17 9.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowDownIcon;
