import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { Controller, useFormContext } from "react-hook-form";

import Dropdown from "components/Dropdown/Dropdown";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

import { useAuth } from "../../../../../AuthProvider";

type SchemaEligibilityCheckEditorProps = {
  fieldPath: string;
  label: string;
};
interface DropdownOption {
  label: string;
  value: string;
}
const SchemaShareNetworksEditor: FC<SchemaEligibilityCheckEditorProps> = ({
  fieldPath,
  label,
}) => {
  const { axios } = useAuth();

  const { control, getValues } = useFormContext();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedMarket] = useSelectedMarketContext();

  const [configurableShareNetworks, setConfigurableShareNetworks] = useState<
    DropdownOption[]
  >([]);

  if (!axios || !selectedMarket)
    return <ErrorAlert message="Context is invalid" />;

  const fetchShareNetworks = async (source: CancelTokenSource) => {
    try {
      const {
        data: { share_networks },
      } = await axios.get("/api/share_networks", {
        params: {
          region_key: selectedMarket.region_key,
        },
        cancelToken: source.token,
      });

      if (!share_networks) return;
      setConfigurableShareNetworks(share_networks);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
      }
    }
  };

  const loadConfigurables = async (source: CancelTokenSource) => {
    await fetchShareNetworks(source);
    setLoading(false);
  };

  const selectedShareNetworks =
    getValues(fieldPath)?.map((shareNetwork: string) => {
      return configurableShareNetworks.find(
        (configurableShareNetwork) =>
          configurableShareNetwork.value === shareNetwork
      );
    }) || [];

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurables(source);

    return () => source.cancel();
  }, []);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Controller
      control={control}
      name={fieldPath}
      key={fieldPath}
      render={({ field: { onChange } }) => (
        <Dropdown
          isMulti={true}
          label={label}
          id={fieldPath}
          items={configurableShareNetworks}
          value={selectedShareNetworks}
          onChange={(selectedShareNetworks) =>
            onChange(
              selectedShareNetworks.map(
                (selectedShareNetwork: DropdownOption) =>
                  selectedShareNetwork.value
              )
            )
          }
        />
      )}
    />
  );
};

export default SchemaShareNetworksEditor;
