import React from "react";

import { EditorComponent } from "interfaces";

import "components/CampaignConfigurationEditor/StepEditor/Editors";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import schema from "../../schema.json";

const PartnerDashboardContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "partner_dashboard";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <ContentEditorWrapper
      nextLocale={locale}
      contentPath={contentPath}
      goToPreviousLocale={goToPreviousLocale}
    >
      <SchemaRenderer
        schema={schema}
        flowId={flowId}
        journeyId={journeyId}
        section={"content"}
        locale={locale}
      />
    </ContentEditorWrapper>
  );
};

export default PartnerDashboardContentEditor;
