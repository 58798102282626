import { Flow } from "interfaces";

import RewardManagementEditor from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardManagementEditor";
import {
  AutomaticallyRedirectFriendEditor,
  AutomaticallyRedirectFriendSettingsEditor,
  ClickToClaimFriendFulfilmentExpiredSettingsEditor,
  ClickToClaimFriendOptionConfirmationSettingsEditor,
  ClickToClaimFriendOptionSelectorSettingsEditor,
  ClickToClaimFriendRewardClaimedSettingsEditor,
  ClickToClaimFulfilmentExpiredContentEditor,
  ClickToClaimOptionConfirmationContentEditor,
  ClickToClaimOptionSelectorContentEditor,
  ClickToClaimReferrerFulfilmentExpiredSettingsEditor,
  ClickToClaimReferrerOptionConfirmationSettingsEditor,
  ClickToClaimReferrerOptionSelectorSettingsEditor,
  ClickToClaimReferrerRewardClaimedSettingsEditor,
  ClickToClaimRewardAlreadyClaimedContentEditor,
  ClickToClaimRewardClaimedContentEditor,
  ClickToClaimSharedContentEditor,
  ClickToRedirectFriendSettingsEditor,
  FriendJourneyCaptureAdditionalFriendDetailsContentEditor,
  FriendJourneyCaptureAdditionalFriendDetailsSettingsEditor,
  FriendJourneyCaptureFriendDetailsContentEditor,
  FriendJourneyCaptureFriendDetailsSettingsEditor,
  FriendJourneyIneligibleContentEditor,
  FriendJourneyReferralLimitContentEditor,
  FriendJourneyReferralLimitSettingsEditor,
  FriendJourneySharedSettingsEditor,
  FriendJourneySuccessfulRegistrationContentEditor,
  FriendJourneySuccessfulRegistrationSettingsEditor,
  LookupUserContentEditor,
  PartnerDashboardContentEditor,
  PartnerJourneySharedSettingsEditor,
  RedirectFriendToClientJourneyClickToRedirectFriendEditor,
  ReferrerJourneyCaptureReferrerDetailsContentEditor,
  ReferrerJourneyPageDisabledContentEditor,
  ReferrerJourneyReferrerDashboardContentEditor,
  ReferrerJourneyReferrerDashboardSettingsEditor,
  ReferrerJourneySeamlessLoginFailureContentEditor,
  ReferrerJourneySharedContentEditor,
  ReferrerJourneySharedSettingsEditor,
  ReferrerJourneySplashPageContentEditor,
  SharedFlowSettingsEditor,
  SiteContentEditor,
  SiteSettingsEditor,
} from "components/CampaignConfigurationEditor/StepEditor/Editors";
import StepEditor from "components/CampaignConfigurationEditor/StepEditor/StepEditor";
import { ICONS } from "components/Icon/Icon";

import ContinueToClientSiteContentEditor from "../components/CampaignConfigurationEditor/StepEditor/Editors/FriendJourney/ContinueToClientSiteEditor/ContinueToClientSiteContentEditor/ContinueToClientSiteContentEditor";
import IneligibleSettingsEditor from "../components/CampaignConfigurationEditor/StepEditor/Editors/FriendJourney/IneligibleEditor/IneligibleSettingsEditor/IneligibleSettingsEditor";
import PartnerDashboardSettingsEditor from "../components/CampaignConfigurationEditor/StepEditor/Editors/PartnerJourney/PartnerDashboard/PartnerDashboardSettingsEditor/PartnerDashboardSettingsEditor";

const Flows: Flow[] = [
  {
    id: "referrer_journey",
    name: "Referrer journey",
    path: "/referrer-journey",
    groups: [
      {
        id: "page_disabled",
        stepId: "page_disabled",
        name: "Page disabled",
        path: "/page-disabled",
        isOptional: true,
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneyPageDisabledContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "seamless_login_failure",
        stepId: "seamless_login_failure",
        name: "Seamless login failure",
        path: "/seamless-login-failure",
        isOptional: true,
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneySeamlessLoginFailureContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "splash_page",
        stepId: "splash_page",
        name: "Splash page",
        path: "/splash-page",
        isOptional: true,
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneySplashPageContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "registration",
        stepId: "capture_referrer_details",
        name: "Registration",
        path: "/registration",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneyCaptureReferrerDetailsContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "dashboard",
        stepId: "referrer_dashboard",
        name: "Dashboard",
        path: "/dashboard",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneyReferrerDashboardContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ReferrerJourneyReferrerDashboardSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "shared",
        name: "Shared",
        path: "/shared",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ReferrerJourneySharedContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ReferrerJourneySharedSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
    ],
  },
  {
    id: "friend_journey",
    name: "Friend journey",
    path: "/friend-journey",
    groups: [
      {
        id: "registration",
        stepId: "capture_friend_details",
        name: "Registration",
        path: "/registration",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: FriendJourneyCaptureFriendDetailsContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: FriendJourneyCaptureFriendDetailsSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "capture_additional_friend_details",
        stepId: "capture_additional_friend_details",
        name: "Capture additional friend details",
        path: "/capture-additional-friend-details",
        isOptional: true,
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: FriendJourneyCaptureAdditionalFriendDetailsContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component:
              FriendJourneyCaptureAdditionalFriendDetailsSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "successful-registration",
        stepId: "successful_friend_registration",
        name: "Successful Registration",
        path: "/successful-registration",
        component: StepEditor,
        isOptional: true,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: FriendJourneySuccessfulRegistrationContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: FriendJourneySuccessfulRegistrationSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "continue-to-client-site",
        stepId: "continue_to_client_site",
        name: "Continue to client site",
        path: "/continue-to-client-site",
        component: StepEditor,
        isOptional: true,
        hasPreview: false,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ContinueToClientSiteContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "ineligible",
        stepId: "ineligible_for_voucher",
        name: "Ineligible for voucher",
        path: "/ineligible-for-voucher",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: FriendJourneyIneligibleContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: IneligibleSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "referral-limit",
        stepId: "referral_limit_reached",
        name: "Referral limit reached",
        path: "/referral-limit-reached",
        isOptional: true,
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: FriendJourneyReferralLimitContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: FriendJourneyReferralLimitSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "shared",
        name: "Shared",
        path: "/shared",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "settings",
            path: "/settings",
            component: FriendJourneySharedSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
    ],
  },
  {
    id: "redirect_friend_to_client_journey",
    name: "Redirect friend to client",
    path: "/redirect-friend-to-client",
    groups: [
      {
        id: "click_to_redirect_friend",
        name: "Click to redirect friend",
        path: "/click-to-redirect-friend",
        stepId: "click_to_redirect_friend",
        component: StepEditor,
        hasPreview: true,
        isOptional: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: RedirectFriendToClientJourneyClickToRedirectFriendEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToRedirectFriendSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "automatically_redirect_friend",
        name: "Automatically redirect friend",
        path: "/automatically-redirect-friend",
        stepId: "automatically_redirect_friend",
        component: StepEditor,
        hasPreview: false,
        isOptional: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: AutomaticallyRedirectFriendEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: AutomaticallyRedirectFriendSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
    ],
  },
  {
    id: "click_to_claim_referrer",
    name: "Click to claim - Referrer",
    path: "/click-to-claim-referrer",
    groups: [
      {
        id: "reward-management",
        name: "Reward management",
        path: "/reward-management",
        component: RewardManagementEditor,
        hasPreview: false,
        stepEditors: [],
        icon: ICONS.REWARD,
      },
      {
        id: "fulfilment-expired",
        stepId: "fulfilment_expired",
        name: "Fulfilment expired",
        path: "/fulfilment-expired",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimFulfilmentExpiredContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimReferrerFulfilmentExpiredSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "option-selector",
        stepId: "options_selector",
        name: "Option selector",
        path: "/option-selector",
        component: StepEditor,
        hasPreview: true,
        requiredPreviewStates: ["reward_type_id"],
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimOptionSelectorContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimReferrerOptionSelectorSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "option-confirmation",
        stepId: "option_confirmation",
        name: "Option confirmation",
        path: "/option-confirmation",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimOptionConfirmationContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimReferrerOptionConfirmationSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "reward-claimed",
        stepId: "reward_emailed",
        name: "Reward claimed",
        path: "/reward-claimed",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimRewardClaimedContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimReferrerRewardClaimedSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "reward-already-claimed",
        stepId: "reward_emailed_returning",
        name: "Reward already claimed",
        path: "/reward-already-claimed",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimRewardAlreadyClaimedContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "shared",
        name: "Shared",
        path: "/shared",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimSharedContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
    ],
  },
  {
    id: "click_to_claim_friend",
    name: "Click to claim - Friend",
    path: "/click-to-claim-friend",
    groups: [
      {
        id: "reward-management",
        name: "Reward management",
        path: "/reward-management",
        component: RewardManagementEditor,
        hasPreview: false,
        stepEditors: [],
        icon: ICONS.REWARD,
      },
      {
        id: "fulfilment-expired",
        stepId: "fulfilment_expired",
        name: "Fulfilment expired",
        path: "/fulfilment-expired",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimFulfilmentExpiredContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimFriendFulfilmentExpiredSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "option-selector",
        stepId: "options_selector",
        name: "Option selector",
        path: "/option-selector",
        component: StepEditor,
        hasPreview: true,
        requiredPreviewStates: ["reward_type_id"],
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimOptionSelectorContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimFriendOptionSelectorSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "option-confirmation",
        stepId: "option_confirmation",
        name: "Option confirmation",
        path: "/option-confirmation",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimOptionConfirmationContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimFriendOptionConfirmationSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "reward-claimed",
        stepId: "reward_claimed",
        name: "Reward claimed",
        path: "/reward-claimed",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimRewardClaimedContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: ClickToClaimFriendRewardClaimedSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
      {
        id: "reward-already-claimed",
        stepId: "reward_already_claimed",
        name: "Reward already claimed",
        path: "/reward-already-claimed",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimRewardAlreadyClaimedContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
      {
        id: "shared",
        name: "Shared",
        path: "/shared",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: ClickToClaimSharedContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
      },
    ],
  },
  {
    id: "user_lookup_journey",
    name: "Share by code - User lookup",
    path: "/user-lookup",
    groups: [
      {
        id: "lookup-user",
        stepId: "lookup_user",
        name: "Lookup user",
        path: "/lookup-user",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: LookupUserContentEditor,
            icon: ICONS.CONTENT,
          },
        ],
        icon: ICONS.REWARD,
      },
    ],
  },
  {
    id: "partner_journey",
    name: "Partner Journey",
    path: "/partner-journey",
    groups: [
      {
        id: "partner-dashboard",
        stepId: "partner_dashboard",
        name: "Partner dashboard",
        path: "/partner-dashboard",
        component: StepEditor,
        hasPreview: true,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: PartnerDashboardContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: PartnerDashboardSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
        icon: ICONS.REWARD,
      },
      {
        id: "shared",
        name: "Shared",
        path: "/shared",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "settings",
            path: "/settings",
            component: PartnerJourneySharedSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
      },
    ],
  },
  {
    id: "wrapper_site",
    name: "Wrapper Site",
    path: "/wrapper-site",
    groups: [
      {
        id: "site",
        stepId: "site",
        name: "Site",
        path: "/site",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "content",
            path: "/content",
            component: SiteContentEditor,
            icon: ICONS.CONTENT,
          },
          {
            type: "settings",
            path: "/settings",
            component: SiteSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
        icon: ICONS.REWARD,
      },
    ],
  },
  {
    id: "shared",
    name: "Shared",
    path: "/shared",
    groups: [
      {
        id: "settings",
        stepId: "settings",
        name: "Settings",
        path: "",
        component: StepEditor,
        hasPreview: false,
        stepEditors: [
          {
            type: "settings",
            path: "/settings",
            component: SharedFlowSettingsEditor,
            icon: ICONS.SETTINGS,
          },
        ],
        icon: ICONS.REWARD,
      },
    ],
  },
];

export default Flows;
