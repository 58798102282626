import React, { FC } from "react";

import { SelectField } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type FieldWithFontWeightOptionsProps = {
  id: string;
  label: string;
};

const fontWeights = [
  "normal",
  "bold",
  "bolder",
  "lighter",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
].map((value) => ({
  label: value,
  value: value,
}));

const FieldWithFontWeightOptions: FC<FieldWithFontWeightOptionsProps> = ({
  id,
  label,
}) => {
  return <SelectField options={fontWeights} fieldId={id} label={label} />;
};

export default FieldWithFontWeightOptions;
