{
  "redirect_friend_to_client_journey": {
    "click_to_redirect_friend": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true
          },
          "body": {
            "type": "string",
            "long": true,
            "required": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true
            }
          },
          "form": {
            "caption": {
              "type": "string",
              "required": true
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              },
              "url": {
                "type": "string",
                "required": true
              }
            },
            "fine_print": {
              "type": "string",
              "required": true
            }
          }
        }
      },
      "settings": {
        "voucher_distribution": {
          "type": "voucher_distribution"
        }
      }
    },
    "automatically_redirect_friend": {
      "content": {
        "locale": {
          "url": {
            "type": "string",
            "required": true
          },
          "remove_bp_parameters_from_url": {
            "type": "boolean",
            "required": true
          }
        }
      },
      "settings": {
        "voucher_distribution": {
          "type": "voucher_distribution"
        }
      }
    }
  }
}
