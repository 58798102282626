{
  "click_to_claim_friend": {
    "fulfilment_expired": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "options_selector": {
      "settings": {
        "intro_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "option_confirmation": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "reward_claimed": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    }
  }
}
