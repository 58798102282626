import React, { FC, ReactNode, useEffect, useState } from "react";

import { cloneDeep } from "lodash";
import { useFormContext } from "react-hook-form";

import Modal from "./Modal";

type ContentEditorWrapperProps = {
  children: ReactNode;
  contentPath: string;
  nextLocale: string;
  goToPreviousLocale: () => void;
};

const ContentEditorWrapper: FC<ContentEditorWrapperProps> = ({
  children,
  contentPath,
  nextLocale,
  goToPreviousLocale,
}) => {
  const { getValues, setValue } = useFormContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const localesAvailable = Object.keys(getValues(contentPath));
  const contentPathToNextLocale = `${contentPath}.${nextLocale}`;
  const initializeContent = (baseLocale: string) => {
    const baseStepContent = cloneDeep(
      getValues(`${contentPath}.${baseLocale}`)
    );
    setValue(contentPathToNextLocale, baseStepContent);
  };

  const hasContentForNextLocale = !!getValues(contentPathToNextLocale);

  useEffect(() => {
    if (!hasContentForNextLocale) {
      setShowModal(true);
    }
  }, [nextLocale]);

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        localesAvailable={localesAvailable}
        nextLocale={nextLocale}
        initializeContent={initializeContent}
        goToPreviousLocale={goToPreviousLocale}
      />
      {hasContentForNextLocale && children}
    </>
  );
};

export default ContentEditorWrapper;
