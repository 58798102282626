import React, { FC, useEffect } from "react";

import { OptionTypeBase } from "interfaces";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import IdentifierTypes from "constants/IdentifierTypesConstant";

import Dropdown from "components/Dropdown/Dropdown";
import { Switch } from "components/Switch/Switch";

type TrackReferralOptionsEditorProps = {
  propertyPath: string;
  customFieldPath: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  displaySettings: any;
};

const TrackReferralOptionsEditor: FC<TrackReferralOptionsEditorProps> = ({
  propertyPath,
  customFieldPath,
  displaySettings,
}) => {
  const watchedValue = useWatch({ name: propertyPath });

  const identifierPropPath = `${propertyPath}.identifier_type`;
  const customFieldKeyPropPath = `${propertyPath}.custom_field_key`;
  const enabledPropPath = `${propertyPath}.enabled`;

  const { control, getValues, unregister } = useFormContext();
  const identifierDropdownItems = Object.entries(IdentifierTypes).map(
    (type) => ({
      value: type[0],
      label: type[1],
    })
  );
  const selectedIdentifierDropdownItem =
    identifierDropdownItems.find(
      (identifierType) => identifierType.value === getValues(identifierPropPath)
    ) || null;

  useEffect(() => {
    if (
      selectedIdentifierDropdownItem?.value !== "friend_custom_field_value" &&
      !!getValues(customFieldKeyPropPath)
    ) {
      unregister(customFieldKeyPropPath);
    }
  }, [watchedValue]);

  const customFields = getValues(customFieldPath) || {};
  const customFieldsDropdownItems = Object.entries(customFields).map(
    (type) => ({
      value: type[1].key,
      label: type[1].key,
    })
  );

  const customFieldsSelectedDropdownItem =
    customFieldsDropdownItems.find(
      (identifierType) =>
        identifierType.value === getValues(customFieldKeyPropPath)
    ) || null;

  return (
    <>
      <Controller
        control={control}
        key={enabledPropPath}
        name={enabledPropPath}
        render={({ field: { onChange, value } }) => {
          const isSelected = displaySettings?._reverse ? !value : value;

          return (
            <Switch
              id={enabledPropPath}
              isSelected={isSelected}
              onChange={(isSelected) => {
                if (displaySettings?._reverse) return onChange(!isSelected);

                onChange(isSelected);
              }}
            >
              {displaySettings?.enabled?._label}
            </Switch>
          );
        }}
      />

      <Controller
        control={control}
        name={identifierPropPath}
        key={identifierPropPath}
        render={({ field: { onChange } }) => (
          <Dropdown
            id={identifierPropPath}
            value={selectedIdentifierDropdownItem}
            items={identifierDropdownItems}
            onChange={(e) => onChange((e as OptionTypeBase).value)}
            label={displaySettings?.identifier_type?._label}
          />
        )}
      />

      {selectedIdentifierDropdownItem?.value == "friend_custom_field_value" && (
        <Controller
          control={control}
          name={customFieldKeyPropPath}
          key={customFieldKeyPropPath}
          render={({ field: { onChange } }) => (
            <Dropdown
              id={customFieldKeyPropPath}
              value={customFieldsSelectedDropdownItem}
              items={customFieldsDropdownItems}
              onChange={(e) => onChange((e as OptionTypeBase).value)}
              label={displaySettings?.custom_field_key?._label}
            />
          )}
        />
      )}
    </>
  );
};

export default TrackReferralOptionsEditor;
