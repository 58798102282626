import { FC, ReactElement, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";

type CaptureReferrerDetailsEditorWrapperProps = {
  children: ReactElement;
};

const CaptureReferrerDetailsEditorWrapper: FC<
  CaptureReferrerDetailsEditorWrapperProps
> = ({ children }) => {
  const { setPreviewStates } = usePreviewStates();

  useEffect(() => {
    setPreviewStates([
      {
        label: "Default",
        value: {},
      },
      {
        label: "Show sign in link emailed message",
        value: { show_sign_in_link_emailed_message: true },
      },
      {
        label: "Show eligibility check rejection message",
        value: { show_eligibility_check_rejection_message: true },
      },
    ]);

    return () => setPreviewStates([]);
  }, []);

  return children;
};

export default CaptureReferrerDetailsEditorWrapper;
