import React from "react";

import { UseSelectedMarket, useSelectedMarket } from "hooks/useSelectedMarket";
import { Market } from "interfaces";

import { createGenericContext } from "./createGenericContext";

interface Props {
  children: React.ReactNode;
  value?: Market | null;
}

// Generate context
const [useSelectedMarketContext, SelectedMarketContextProvider] =
  createGenericContext<UseSelectedMarket>();

// Generate provider
const SelectedMarketProvider = ({
  children,
  value,
}: Props): React.ReactElement => {
  const [selectedMarket, setSelectedMarket] = useSelectedMarket(value || null);

  return (
    <SelectedMarketContextProvider value={[selectedMarket, setSelectedMarket]}>
      {children}
    </SelectedMarketContextProvider>
  );
};

export { useSelectedMarketContext, SelectedMarketProvider };
