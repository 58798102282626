import React, { FC, createContext, useState } from "react";

import { HeaderDrawer } from "interfaces";

export type HeaderState = {
  showDrawer: boolean;
  setShowDrawer: (state: boolean) => void;
  headerDrawer: HeaderDrawer | null;
  setHeaderDrawer: (state: HeaderDrawer | null) => void;
};

export const HeaderDrawerContext = createContext<HeaderState>({
  showDrawer: false,
  setShowDrawer: () => console.warn("default context"),
  headerDrawer: null,
  setHeaderDrawer: () => console.warn("default context"),
});

export const HeaderDrawerProvider: FC = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [headerDrawer, setHeaderDrawer] = useState<HeaderDrawer | null>(null);

  return (
    <HeaderDrawerContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        headerDrawer,
        setHeaderDrawer,
      }}
    >
      {children}
    </HeaderDrawerContext.Provider>
  );
};
