import { format, fromUnixTime, parseISO } from "date-fns";

const DisplayFormat = "dd.MM.yyyy HH:mm";

export const hoursInSeconds = (hours: number | null): number | null => {
  let result = null;
  if (hours) {
    result = hours * 3600;
  }

  return result;
};

export const secondsInHours = (seconds: number | null): number | null => {
  let result = null;
  if (seconds) {
    result = seconds / 3600;
  }

  return result;
};

export const toFormattedDate = (timestamp: number | string): string => {
  let date: Date;

  if (typeof timestamp === "string") {
    date = parseISO(timestamp);
  } else if (typeof timestamp === "number") {
    date = fromUnixTime(timestamp);
  } else {
    throw new Error("timestamp should be either a string or a number");
  }

  return format(date, DisplayFormat);
};
