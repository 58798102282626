import { ActionsType } from "interfaces";

const actionTypeClasses: {
  text: {
    positive: string;
    medium: string;
    error: string;
  };
} = {
  text: {
    positive: "text-actions-positive",
    medium: "text-actions-medium",
    error: "text-actions-error",
  },
};

export const getActionTypeClass = (
  classType: "text",
  color: ActionsType
): string => actionTypeClasses[classType][color];
