import React from "react";

import { EditorComponent } from "interfaces";

import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import schema from "../../schema.json";

const SiteSettingsEditor: EditorComponent = ({ flowId }) => {
  const journeyId = "site";
  const section = "settings";

  return (
    <>
      <SchemaRenderer
        schema={schema}
        flowId={flowId}
        journeyId={journeyId}
        section={section}
        locale={""}
        includeShared={false}
      />
    </>
  );
};

export default SiteSettingsEditor;
