import React, { FC } from "react";

import { CreatableSelectField } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type FieldWithTextDecorationOptionsProps = {
  id: string;
  label: string;
};

const textDecorations = ["none", "underline"].map((value) => ({
  label: value,
  value: value,
}));

const FieldWithTextDecorationOptions: FC<
  FieldWithTextDecorationOptionsProps
> = ({ id, label }) => {
  return (
    <CreatableSelectField
      options={textDecorations}
      fieldId={id}
      label={label}
    />
  );
};

export default FieldWithTextDecorationOptions;
