import React, { FC, useEffect, useRef, useState } from "react";

import usePreviewStates from "hooks/usePreviewStates";
import { EditorsGroup, OptionTypeBase } from "interfaces";
import { v4 as uuidv4 } from "uuid";

import {
  Container,
  DeviceSelector,
  Nav,
} from "components/CampaignConfigurationEditor/StepEditor/Preview/PreviewEmbed";
import Picker from "components/Picker/Picker";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type PreviewEmbedProps = {
  flowId: string;
  step: EditorsGroup;
  currentLocale: string;
};

const PreviewEmbed: FC<PreviewEmbedProps> = ({
  flowId,
  step,
  currentLocale,
}) => {
  const containerRef = useRef<HTMLElement>(null);

  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  const [elementId, setElementId] = useState(`bp_div_${uuidv4()}`);

  const refreshElementId = () => {
    setElementId(`bp_div_${uuidv4()}`);
  };

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const bpElement = document.createElement("div");
      bpElement.setAttribute("id", elementId);
      container.appendChild(bpElement);

      if (isReady()) loadEmbeddedPreview();

      return () => container.removeChild(bpElement);
    }
  }, [containerRef.current, elementId]);

  if (!selectedMarket || !selectedCampaign || !selectedCampaignConfiguration) {
    throw new Error("Something went wrong - context not set correctly");
  }
  const { previewStates } = usePreviewStates();

  const [selectedPreviewState, setSelectedPreviewState] = useState<number>(0);

  const loadEmbeddedPreview = () => {
    if (!window.BuyapowaPlatform) return;

    new window.BuyapowaPlatform.Embed({
      endpoint: selectedMarket.endpoint,
      market: selectedMarket.subdomain,
      campaign: selectedCampaign.slug,
      locale: currentLocale,
      flow: {
        name: flowId,
        params: {},
      },
      preview: {
        configuration_id: selectedCampaignConfiguration.id,
        step: step.stepId,
        state: previewStates[selectedPreviewState]?.value,
      },
    }).render(elementId, "bp_error");
  };

  const isReady = (): boolean => {
    if (!window.BuyapowaPlatform) return false;
    if (!step.stepId) return false;
    if (!step.requiredPreviewStates) return true;
    if (!previewStates[selectedPreviewState]?.value) return false;

    return step.requiredPreviewStates.every((requiredPreviewState) => {
      return Object.keys(previewStates[selectedPreviewState]?.value).includes(
        requiredPreviewState
      );
    });
  };

  const pickerValue: OptionTypeBase = previewStates[selectedPreviewState];

  const onSelectedPreviewStateChange = (
    selectedPreviewState: OptionTypeBase
  ) => {
    console.log("selectedPreviewState is a", typeof selectedPreviewState);
    setSelectedPreviewState(previewStates.indexOf(selectedPreviewState));
  };

  useEffect(() => {
    refreshElementId();
  }, [
    currentLocale,
    selectedCampaignConfiguration,
    selectedPreviewState,
    previewStates,
  ]);

  if (!isReady()) return null;

  return (
    <Container>
      <Nav>
        {previewStates.length > 0 && (
          <div className="w-75">
            <Picker
              id="preview-state"
              label={"Select a preview state"}
              value={pickerValue}
              items={previewStates}
              onChange={onSelectedPreviewStateChange}
            />
          </div>
        )}
        <DeviceSelector />
      </Nav>

      <div id="bp_error" className="hidden text-center">
        There was an error loading the script
      </div>

      <div ref={containerRef} className="w-full text-text"></div>
    </Container>
  );
};

export default PreviewEmbed;
