import React, { FC, useState } from "react";

import Axios from "axios";
import copy from "copy-to-clipboard";
import { Market, RunaRewardOption } from "interfaces";
import toast from "react-hot-toast";
import { IconContext } from "react-icons";
import { FaCircle } from "react-icons/fa";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ErrorToast from "components/ErrorToast";
import { CancelIcon } from "components/Icon";
import TooltipWrapper from "components/TooltipWrapper";

type RewardOptionListItemProps = {
  market: Market;
  rewardOption: RunaRewardOption;
  onDelete: () => void;
};

const RewardOptionListItem: FC<RewardOptionListItemProps> = ({
  market,
  rewardOption,
  onDelete,
}) => {
  const { axios } = useAuth();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  if (!axios) {
    throw new Error("Context isn't set correctly");
  }

  const handleCopyUrl = (): void => {
    copy(rewardOption.image_url);
    toast.success("Link copied to clipboard");
  };

  const deleteRewardOption = async () => {
    await axios.delete(
      `/api/markets/${market.id}/reward_options/${rewardOption.id}`,
      {
        params: {
          region_key: market.region_key,
        },
        cancelToken: Axios.CancelToken.source().token,
      }
    );

    onDelete();
  };

  const handleDelete = () => {
    setShowConfirmationModal(false);

    toast.promise(deleteRewardOption(), {
      loading: "Deleting...",
      success: "Deleted reward option",
      error: (e) => <ErrorToast error={e} />,
    });
  };

  const iconColor = `rgba(49, 153, 205, ${rewardOption.in_use ? 1 : 0.2})`;
  return (
    <div>
      <ConfirmationModal
        title="Are you sure?"
        positiveButtonText="Retain reward"
        negativeButtonText="Delete reward"
        setToggle={setShowConfirmationModal}
        showModal={showConfirmationModal}
        handlePositiveAction={() => setShowConfirmationModal(false)}
        handleNegativeAction={handleDelete}
      >
        <p className="text-text">
          You will need to reconfigure this reward once it has been deleted.
        </p>
      </ConfirmationModal>
      <li className="p-4 mxy-4 mb-4 grid grid-cols-8 gap-4 border-solid border border-backgroundSecondary hover:border-actions-positive hover:shadow-md rounded-xl list-none">
        <div className="product-logo-container col-span-2 row-span-3">
          <img
            className={"product-logo"}
            src={rewardOption.image_url}
            alt={`logo for ${rewardOption.name}`}
          />
        </div>
        <div className="col-span-5 flex-row inline-flex items-center">
          <span className="col-span-1 font-bold mr-3">Status:</span>
          <span className="col-span-1 brand-primary mr-2">
            <IconContext.Provider
              value={{
                color: iconColor,
                size: "0.7em",
              }}
            >
              <FaCircle />
            </IconContext.Provider>
          </span>
          <span className="col-span-1">
            {rewardOption.in_use ? "In use" : "Not in use"}
          </span>
        </div>
        <div className="col-span-1 row-span-3 self-center justify-self-center">
          <TooltipWrapper
            show={rewardOption.in_use}
            text="You cannot delete a reward option that is in use."
            placement="top"
          >
            <div
              data-testid={`${rewardOption.code}-remove-button`}
              onClick={() => setShowConfirmationModal(!rewardOption.in_use)}
              className={`${rewardOption.in_use ? "opacity-40" : "cursor-pointer opacity-40 hover:opacity-100"}`}
            >
              <CancelIcon size={24} />
            </div>
          </TooltipWrapper>
        </div>
        <div className="col-span-2">
          <div className="font-bold">Product name:</div>
          <div className="">{rewardOption.name}</div>
        </div>
        <div className="col-span-3">
          <div className="font-bold">Country:</div>
          <div>{rewardOption.country}</div>
        </div>
        <div className="col-span-2">
          <div className="font-bold">Product code:</div>
          <div>{rewardOption.code}</div>
        </div>
        <div className="col-span-1">
          <div className="font-bold">Value:</div>
          <div>{rewardOption.formatted_amount}</div>
        </div>
        <div className="col-span-2">
          <div className="font-bold">Image URL:</div>
          <div className="text-actions-positive inline-flex flex-col w-50">
            <Button
              testId={`${rewardOption.code}-copy-button`}
              appearance="link"
              handleOnClick={handleCopyUrl}
              text="Copy image URL"
              role="button"
              justifyContent="start"
            />
          </div>
        </div>
      </li>
    </div>
  );
};

export default RewardOptionListItem;
