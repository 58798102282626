import React from "react";

import { useRadioGroup } from "@react-aria/radio";
import { useRadioGroupState } from "@react-stately/radio";
import { SpectrumRadioGroupProps } from "@react-types/radio";
import cn from "classnames";

import { RadioContext } from "components/Radio/context";

export const TickerGroup: React.FC<SpectrumRadioGroupProps> = (props) => {
  const { id, label, children } = props;
  const radioState = { ...props };
  radioState.orientation = "horizontal";
  const state = useRadioGroupState(radioState);
  const { radioGroupProps, labelProps } = useRadioGroup(radioState, state);
  const labelId = `${id}_label`;

  return (
    <fieldset {...radioGroupProps} aria-labelledby={labelId}>
      <label {...labelProps} id={labelId} className="text-text font-bold">
        {label}
      </label>

      <div
        className={cn(
          "flex flex-col",
          "mt-2.5 p-2.5",
          "space-y-2.5",
          "text-text text-sm",
          "box-border",
          "drop-shadow-[0_0_10px_rgba(0,0,0,0.1)]",
          "border border-surfaceTertiary",
          "rounded"
        )}
      >
        <RadioContext.Provider value={{ state }}>
          {children}
        </RadioContext.Provider>
      </div>
    </fieldset>
  );
};
