import React, { FC } from "react";

import { HiOutlineExclamationCircle } from "react-icons/hi";

type NoItemsWarningProps = {
  children: React.ReactNode;
};

const NoItemsWarning: FC<NoItemsWarningProps> = ({ children }) => (
  <div className="font-normal text-medium text-text flex flex-col items-center mt-5">
    <HiOutlineExclamationCircle className="my-4 h-full w-7" />
    {children}
  </div>
);

export default NoItemsWarning;
