import React, { FC } from "react";

import { HeaderDrawerNav } from "components/Header";
import Logo from "components/Logo";

const TopBar: FC = () => (
  <div className="flex justify-between items-center h-17.5 px-5 text-text bg-backgroundPrimary">
    <div className="flex-1"></div>
    <div className="flex-1 flex justify-center">
      <Logo />
    </div>
    <div className="flex-1 flex justify-end h-full">
      <HeaderDrawerNav />
    </div>
  </div>
);

export default TopBar;
