import React, { Dispatch, FC, SetStateAction } from "react";

import Button from "components/Button";
import ModalContainer from "components/ModalContainer/ModalContainer";

type ConfirmationModalProps = {
  title: string;
  positiveButtonText: string;
  negativeButtonText: string;
  setToggle: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  handlePositiveAction: () => void;
  handleNegativeAction: () => void;
  children: React.ReactNode;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  positiveButtonText,
  negativeButtonText,
  setToggle,
  showModal,
  handlePositiveAction,
  handleNegativeAction,
  children,
}) => (
  <ModalContainer showModal={showModal} setShowModal={setToggle} title={title}>
    {children}
    <div className="flex flex-col space-y-4 mt-5">
      <div className="w-full">
        <Button
          testId="modal-positive-button"
          appearance="primary"
          handleOnClick={handlePositiveAction}
          text={positiveButtonText}
          role="button"
        />
      </div>
      <div className="w-full">
        <Button
          testId="modal-negative-button"
          appearance="danger"
          handleOnClick={handleNegativeAction}
          text={negativeButtonText}
          role="button"
        />
      </div>
    </div>
  </ModalContainer>
);

export default ConfirmationModal;
