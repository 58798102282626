import React, { FC } from "react";

const BodyWrapper: FC = ({ children }) => (
  <div className="relative flex-grow">
    <div className="container mx-auto flex flex-col">
      <div className="flex flex-col mt-7.5 mb-10">{children}</div>
    </div>
  </div>
);

export default BodyWrapper;
