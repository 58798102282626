import React, { FC } from "react";

import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";

import DomainForm from "components/WrapperSite/WrapperSiteEditor/DomainConfiguration/DomainForm/DomainForm";
import DomainInfo from "components/WrapperSite/WrapperSiteEditor/DomainConfiguration/DomainInfo/DomainInfo";

const DomainConfiguration: FC = () => {
  const { domainConfig } = useWrapperSiteEditor();

  return (
    <div className="flex flex-col bg-backgroundPrimary my-10 py-7.5 px-5 rounded-xl">
      <h2 className="font-medium text-color text-2xl mb-10">
        Domain configuration
      </h2>
      {domainConfig.domain ? <DomainInfo /> : <DomainForm />}
    </div>
  );
};

export default DomainConfiguration;
