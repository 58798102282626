import React from "react";

import { EditorComponent } from "interfaces";

import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";
import ErrorAlert from "components/ErrorAlert";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import schema from "../../schema.json";

const PartnerDashboardSettingsEditor: EditorComponent = ({ flowId }) => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign)
    return <ErrorAlert message="Context is invalid" />;

  const journeyId = "partner_dashboard";
  const section = "settings";

  return (
    <>
      <SchemaRenderer
        schema={schema}
        flowId={flowId}
        journeyId={journeyId}
        section={section}
        locale={""}
        includeShared={false}
      />
    </>
  );
};

export default PartnerDashboardSettingsEditor;
