import React, { FC } from "react";

import { Accordion } from "components/Accordion/Accordion";

export type ConfigurationStepsProps = {
  isExpandedByDefault: boolean;
};

const ConfigurationSteps: FC<ConfigurationStepsProps> = ({
  isExpandedByDefault,
}) => (
  <Accordion
    label="Configuration steps"
    isExpandedByDefault={isExpandedByDefault}
  >
    <div className="flex flex-col w-full">
      <span className="mb-5">1. Enter a domain</span>
      <span className="mb-1">
        2. Once the page reloads and shows you the DNS records, you should
        provide them to the client.
      </span>
      <span className="text-sm text-text text-opacity-80 mb-5">
        Note: If any of the records are missing you can wait a few seconds and
        reload the page.
      </span>
      <span className="mb-5">
        3. After the client has added the records, come back to this page to
        verify the status. DNS can take up to 24 hours to fully propagate so
        don&apos;t panic if it takes awhile, just come back a little later and
        check again.
      </span>
      <span className="mb-1">
        4. Once all the records show as &lsquo;Issued&rsquo; below and
        you&apos;ve published the wrapper site content, you&apos;ll be able to
        visit the domain to see your new wrapper site.
      </span>
    </div>
  </Accordion>
);

export default ConfigurationSteps;
