import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { CancelTokenSource } from "axios";
import { ArchivedConfiguration, LiveConfiguration } from "interfaces";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorToast from "components/ErrorToast";
import { InputField } from "components/Form";
import ModalContainer from "components/ModalContainer/ModalContainer";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type CreateCampaignConfigurationModalProps = {
  baseConfiguration: LiveConfiguration | ArchivedConfiguration | null;
  setBaseConfiguration: Dispatch<
    SetStateAction<LiveConfiguration | ArchivedConfiguration | null>
  >;
  showCreateModal: boolean;
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
  loadConfigurations: (source: CancelTokenSource | null) => Promise<void>;
  sourceToken: CancelTokenSource | null;
};

const CreateCampaignConfigurationModal: FC<
  CreateCampaignConfigurationModalProps
> = ({
  baseConfiguration,
  setBaseConfiguration,
  setShowCreateModal,
  showCreateModal,
  loadConfigurations,
  sourceToken,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  const [name, setName] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const createNewConfiguration = async () => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      throw new Error("Something went wrong - context not set correctly");
    }

    try {
      await axios.post("/api/campaign_configurations", {
        region_key: selectedMarket?.region_key,
        campaign_id: selectedCampaign?.id,
        name: name,
        base_configuration_id: baseConfiguration?.id,
      });
    } finally {
      loadConfigurations(sourceToken);
      setShowCreateModal(false);
      setBaseConfiguration(null);
    }
  };

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const creatingNewConfiguration = createNewConfiguration();

    toast.promise(creatingNewConfiguration, {
      loading: "Creating a new draft...",
      success: "New draft successfully created",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  return (
    <ModalContainer
      showModal={showCreateModal}
      setShowModal={setShowCreateModal}
    >
      <form className="w-full space-y-4" onSubmit={handleCreate}>
        <InputField
          useNewStyle={true}
          label="New draft configuration name"
          id="draft_configuration_name"
          placeholder="Enter a name"
          value={name}
          handleInputChange={handleInputChange}
        />
        <Button
          appearance="primary"
          text="Create new draft"
          disabled={name.length < 1}
          role="button"
          type="submit"
        />
      </form>
    </ModalContainer>
  );
};

export default CreateCampaignConfigurationModal;
