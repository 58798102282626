import React, { FC, createContext, useState } from "react";

import { WrapperSiteDomainConfig } from "components/WrapperSite/interfaces";

export type WrapperSiteEditorState = {
  reloadDomain: boolean;
  setReloadDomain: (state: boolean) => void;
  domainConfig: WrapperSiteDomainConfig;
  setDomainConfig: (state: WrapperSiteDomainConfig) => void;
};

export const WrapperSiteEditorContext = createContext<WrapperSiteEditorState>({
  reloadDomain: false,
  setReloadDomain: () => console.warn("default context"),
  domainConfig: {},
  setDomainConfig: () => console.warn("default context"),
});

export const WrapperSiteEditorProvider: FC = ({ children }) => {
  const [reloadDomain, setReloadDomain] = useState(false);
  const [domainConfig, setDomainConfig] = useState<WrapperSiteDomainConfig>({});

  return (
    <WrapperSiteEditorContext.Provider
      value={{
        reloadDomain,
        setReloadDomain,
        domainConfig,
        setDomainConfig,
      }}
    >
      {children}
    </WrapperSiteEditorContext.Provider>
  );
};
