import React, { FC, useEffect } from "react";

import { isUndefined } from "lodash";

export type DropdownFieldProps = {
  showLabel?: boolean;
  label: string;
  id: string;
  displayEmptyOption?: boolean;
  emptyOptionLabel?: string;
  items: {
    id: string;
    name: string;
  }[];
  value: string;
  preSelected?: boolean;
  handleInputChange?: (e: React.ChangeEvent<HTMLSelectElement> | Event) => void;
};

const DropdownField: FC<DropdownFieldProps> = ({
  showLabel = true,
  label,
  id,
  displayEmptyOption = true,
  emptyOptionLabel = "- Select -",
  items,
  value,
  preSelected = false,
  handleInputChange,
}) => {
  const newEventChangeSimulated = (checked: boolean, itemId: string) => {
    const event = new Event("change", { bubbles: true });

    Object.defineProperty(event, "target", {
      writable: false,
      value: { id, value: !checked ? null : itemId },
    });

    return event;
  };

  useEffect(() => {
    if (preSelected && !!items.length) {
      const event = newEventChangeSimulated(
        true,
        !isUndefined(value) ? value : items[0] && items[0].id
      );

      handleInputChange && handleInputChange(event);
    }
  }, []);

  return (
    <div className="col-span-6 sm:col-span-3">
      <div>
        {showLabel && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <select
          id={id}
          className="pr-10 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          onChange={handleInputChange}
          value={value}
          aria-label={label}
          aria-labelledby={id}
        >
          {displayEmptyOption && (
            <option key={`no-${id}`} value="">
              {emptyOptionLabel}
            </option>
          )}
          {items.map((item, ix) => (
            <option key={`${ix}-${item.id}`} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownField;
