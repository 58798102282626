import React, { FC, useEffect } from "react";

import { CampaignConfigurationValue } from "interfaces";
import { cloneDeep } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const FormWrapper: FC = ({ children }) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (
    !selectedMarket ||
    !selectedCampaign ||
    !selectedCampaignConfiguration ||
    !axios
  ) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const methods = useForm({
    defaultValues: selectedCampaignConfiguration.value,
  });

  const {
    formState: { dirtyFields, isSubmitted },
  } = methods;

  const saveConfig = async (formData: Record<string, unknown>) => {
    const {
      data: { campaign_configuration },
    } = await axios.put(
      `/api/campaign_configurations/${selectedCampaignConfiguration.id}`,
      {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        version: selectedCampaignConfiguration.version,
        value: formData,
      }
    );

    setSelectedCampaignConfiguration(campaign_configuration);
  };

  const clearIdFieldFromRewardOptions = (
    sanitizeFormData: Record<string, CampaignConfigurationValue>,
    flowId: "click_to_claim_referrer" | "click_to_claim_friend"
  ) => {
    sanitizeFormData[flowId]?.shared.settings.reward_types.map(
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (rewardType: any) => {
        const updatedRewardOptions = rewardType.reward_options.map(
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          (rewardOption: any) => {
            delete rewardOption.id;
            return rewardOption;
          }
        );
        return {
          ...rewardType,
          reward_options: updatedRewardOptions,
        };
      }
    );
  };

  const clearIdFieldFromCustomFields = (
    sanitizeFormData: Record<string, CampaignConfigurationValue>,
    flowId: "referrer_journey" | "friend_journey"
  ) => {
    sanitizeFormData[flowId]?.shared.settings.inputs.custom_fields?.map(
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (customField: any) => {
        delete customField.id;
        return customField;
      }
    );
  };

  const sanitizeFormData = (
    formData: Record<string, CampaignConfigurationValue>
  ) => {
    const sanitizedFormData = cloneDeep(formData);

    clearIdFieldFromRewardOptions(sanitizedFormData, "click_to_claim_referrer");
    clearIdFieldFromRewardOptions(sanitizedFormData, "click_to_claim_friend");
    clearIdFieldFromCustomFields(sanitizedFormData, "referrer_journey");
    clearIdFieldFromCustomFields(sanitizedFormData, "friend_journey");

    return sanitizedFormData;
  };

  const handleSave = (formData: Record<string, CampaignConfigurationValue>) => {
    const sanitizedFormData = sanitizeFormData(formData); // TODO: fix root cause of these id field being set when removing items when using useFieldArray with react-hook-form
    const updatingConfig = saveConfig(sanitizedFormData);

    toast.promise(updatingConfig, {
      loading: "Saving...",
      success: "Saved",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  const canEditConfiguration = !selectedCampaignConfiguration.published_at;

  useEffect(() => {
    methods.reset(selectedCampaignConfiguration.value, {
      keepDirtyValues: !isSubmitted,
    });
  }, [selectedCampaignConfiguration]);

  return (
    <FormProvider {...methods}>
      {/* using dirtyFields as it needs to be subscribed for keepDirtyValues to behave as expected */}
      <input type="hidden" value={dirtyFields} />
      <form role="form" onSubmit={methods.handleSubmit(handleSave)}>
        <div className="flex flex-col gap-y-4 ">{children}</div>
        {canEditConfiguration && (
          <div className="w-24 mt-7.5">
            <Button appearance="primary" text="Save" type="submit" />
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default FormWrapper;
