import React, { FC } from "react";

import { FlowId, RewardType } from "interfaces";
import { useHistory, useLocation } from "react-router";

import Button from "components/Button";
import { NoItemsWarning, TableHead } from "components/Table";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

type RewardTypesTableProps = {
  items: RewardType[];
  flowId: FlowId;
};

type RewardOptionSettings = {
  id: string;
};

type RewardTypeSettings = {
  id: string;
  reward_options: RewardOptionSettings[];
};

const RewardTypesTable: FC<RewardTypesTableProps> = ({ items, flowId }) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    throw new Error("Context isn't set correctly");
  }

  const optionsCountForRewardType = (item: RewardType) => {
    const rewardTypeInConfig = selectedCampaignConfiguration.value[
      flowId
    ].shared.settings.reward_types.find(
      (rewardType: RewardTypeSettings) => item.id === rewardType.id
    );

    if (!rewardTypeInConfig) {
      return 0;
    }

    return rewardTypeInConfig.reward_options.length;
  };

  if (!items || items.length === 0) {
    return (
      <NoItemsWarning>
        <p>There is no click to claim reward configured at the moment.</p>
        <p>Create a new a new click to claim reward from co-buying.</p>
      </NoItemsWarning>
    );
  }

  const history = useHistory();
  const location = useLocation();

  const selectRewardType = async (rewardTypeId: string) => {
    history.push(`${location.pathname}/${rewardTypeId}/edit`);
  };

  return (
    <div className="px-5 py-7.5 border border-opacity-20 dark:border-opacity-20 border-light-text dark:border-dark-text rounded-xl">
      <table className="table">
        <TableHead headers={["Name", "Options configured", "Action"]} />
        <tbody>
          {items.map((item) => {
            return (
              <tr key={`reward-type-${item.id}`} className="table-row">
                <td className="table-primary-cell">{item.name}</td>
                <td className="table-secondary-cell">
                  {optionsCountForRewardType(item)}
                </td>
                <td className="table-secondary-cell">
                  <div className="w-36">
                    <Button
                      appearance="primary"
                      text="Configure"
                      handleOnClick={() => selectRewardType(item.id)}
                      description={`Configure options for reward type named: ${item.name}`}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RewardTypesTable;
