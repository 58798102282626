import React, { FC } from "react";

import { CheckboxField } from "components/Form";
import { NurtureConfig } from "components/Nurture/interfaces";
import TooltipWrapper from "components/TooltipWrapper";

type NurtureActiveSettingsRowProps = {
  nurtureConfig: NurtureConfig;
  handleActiveToggle: (id: string, nextChecked: boolean) => void;
};

const NurtureActiveSettingsRow: FC<NurtureActiveSettingsRowProps> = ({
  nurtureConfig,
  handleActiveToggle,
}) => (
  <tr>
    <td className="p-4" colSpan={4}>
      <TooltipWrapper
        show={nurtureConfig.active}
        text="Warning! deactivating a nurture sequence will stop all in flight nurture emails. Save with caution."
        placement="bottomLeft"
      >
        <CheckboxField
          className="flex-row-reverse justify-end"
          label="Active"
          id="active"
          value={nurtureConfig.active}
          handleToggle={handleActiveToggle}
        />
      </TooltipWrapper>
    </td>
  </tr>
);

export default NurtureActiveSettingsRow;
