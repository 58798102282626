import React, { FC, createContext, useState } from "react";

export type PreviewDevice = "mobile" | "desktop";

export type SelectedPreviewDevice = {
  selectedPreviewDevice: PreviewDevice;
  setSelectedPreviewDevice: React.Dispatch<React.SetStateAction<PreviewDevice>>;
};

export const SelectedPreviewDeviceContext =
  createContext<SelectedPreviewDevice>({
    selectedPreviewDevice: "mobile",
    setSelectedPreviewDevice: () => {
      // do nothing
    },
  });

export const SelectedPreviewDeviceProvider: FC = ({ children }) => {
  const [selectedPreviewDevice, setSelectedPreviewDevice] =
    useState<PreviewDevice>("mobile");

  return (
    <SelectedPreviewDeviceContext.Provider
      value={{
        selectedPreviewDevice,
        setSelectedPreviewDevice,
      }}
    >
      {children}
    </SelectedPreviewDeviceContext.Provider>
  );
};
