import React, { FC, useEffect } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";
import { FlowId } from "interfaces";
import toast from "react-hot-toast";

import Flows from "constants/FlowsConstant";

import { useAuth } from "components/AuthProvider";
import BodyWrapper from "components/BodyWrapper";
import ActiveFlow from "components/CampaignConfigurationEditor/FlowsEditor/ActiveFlow/ActiveFlow";
import InactiveFlow from "components/CampaignConfigurationEditor/FlowsEditor/InactiveFlow/InactiveFlow";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const FlowsEditor: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedMarket || !selectedCampaign || !selectedCampaignConfiguration)
    return <ErrorAlert message="Context is invalid" />;

  const { setBreadcrumbItems } = useBreadcrumb();

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;

  const showBreadcrumbs = () => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Configuration",
        value: selectedCampaignConfiguration.name,
      },
    ]);
  };

  useEffect(() => {
    showBreadcrumbs();
  }, []);

  const activateFlow = async (flowId: FlowId) => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      throw new Error("Something went wrong - context not set correctly");
    }
    const {
      data: { campaign_configuration },
    } = await axios.post(
      `/api/campaign_configurations/${selectedCampaignConfiguration.id}/flows`,
      {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        type: flowId,
        version: selectedCampaignConfiguration.version,
      }
    );

    setSelectedCampaignConfiguration(campaign_configuration);
  };

  const handleActivateFlow = (flowId: FlowId) => {
    const activatingFlow = activateFlow(flowId);

    toast.promise(activatingFlow, {
      loading: "Activating...",
      success: "Activated",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  const deactivateFlow = async (flowId: FlowId) => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      throw new Error("Something went wrong - context not set correctly");
    }
    const {
      data: { campaign_configuration },
    } = await axios.delete(
      `/api/campaign_configurations/${selectedCampaignConfiguration.id}/flows/${flowId}`,
      {
        data: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          version: selectedCampaignConfiguration.version,
        },
      }
    );

    setSelectedCampaignConfiguration(campaign_configuration);
  };

  const handleDeactivateFlow = (flowId: FlowId) => {
    const deactivatingFlow = deactivateFlow(flowId);

    toast.promise(deactivatingFlow, {
      loading: "Deactivating...",
      success: "Deactivated",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  return (
    <BodyWrapper>
      <div className="flex flex-col flex-grow space-y-7.5">
        {Flows.map((flow) =>
          selectedCampaignConfiguration.flows[flow.id].active ? (
            <ActiveFlow
              key={flow.id}
              flow={flow}
              deactivateFlow={handleDeactivateFlow}
            />
          ) : (
            <InactiveFlow
              key={flow.id}
              id={flow.id}
              name={flow.name}
              activateFlow={handleActivateFlow}
            />
          )
        )}
      </div>
    </BodyWrapper>
  );
};

export default FlowsEditor;
