import React, { FC } from "react";

import useHeaderDrawer from "hooks/useHeaderDrawer";

const HeaderDrawer: FC = () => {
  const { headerDrawer } = useHeaderDrawer();

  if (!headerDrawer) {
    return null;
  }

  return (
    <div className="h-auto bg-backgroundSecondary relative">
      <div className="absolute right-8 -top-3.5 bg-transparent border-solid border-b-backgroundSecondary border-l-transparent border-r-transparent border-t-transparent w-0 h-0 border-7.5" />
      {headerDrawer.childComponent}
    </div>
  );
};

export default HeaderDrawer;
