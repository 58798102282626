import React, { useEffect, useState } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";
import { GroupComponent } from "interfaces";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  Container,
  Form,
  Nav,
  Preview,
} from "components/CampaignConfigurationEditor/StepEditor";
import ErrorAlert from "components/ErrorAlert";

import { PreviewStatesProvider } from "context/PreviewStatesContext";
import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";
import { SelectedPreviewDeviceProvider } from "context/SelectedPreviewDeviceContext";

import EditorWrapper from "./EditorWrapper/EditorWrapper";

const StepEditor: GroupComponent = ({ flowId, flowName, flowPath, group }) => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedMarket || !selectedCampaign || !selectedCampaignConfiguration) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const { setBreadcrumbItems } = useBreadcrumb();
  const [previousLocale, setPreviousLocale] = useState<string>(
    selectedMarket.default_locale
  );
  const [currentLocale, setCurrentLocale] = useState<string>(
    selectedMarket.default_locale
  );

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;
  const campaignConfigurationEditorPath = `${campaignEditorPath}/configuration/${selectedCampaignConfiguration.id}`;
  const stepEditorPath = `${campaignConfigurationEditorPath}${flowPath}${group.path}`;
  const defaultStepEditorGroup = `${stepEditorPath}${group.stepEditors[0].path}`;

  const goToPreviousLocale = () => setCurrentLocale(previousLocale);
  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Configuration",
        value: selectedCampaignConfiguration.name,
        path: campaignConfigurationEditorPath,
      },
      {
        label: flowName,
        value: group.name,
      },
    ]);
  }, []);

  return (
    <SelectedPreviewDeviceProvider>
      <Container>
        <PreviewStatesProvider>
          <EditorWrapper hasPreview={group.hasPreview}>
            <Nav
              group={group}
              stepEditorPath={stepEditorPath}
              currentLocale={currentLocale}
              setCurrentLocale={setCurrentLocale}
              setPreviousLocale={setPreviousLocale}
            />
            <Switch>
              {group.stepEditors.map((editor) => (
                <Route
                  key={flowPath + group.path + editor.path}
                  exact
                  path={`/:market_subdomain/:campaign_slug/configuration/${selectedCampaignConfiguration.id}${flowPath}${group.path}${editor.path}`}
                >
                  <Form
                    flowId={flowId}
                    editor={editor}
                    currentLocale={currentLocale}
                    goToPreviousLocale={goToPreviousLocale}
                  />
                </Route>
              ))}

              <Route render={() => <Redirect to={defaultStepEditorGroup} />} />
            </Switch>
          </EditorWrapper>
          {group.hasPreview && (
            <Preview
              flowId={flowId}
              step={group}
              currentLocale={currentLocale}
            />
          )}
        </PreviewStatesProvider>
      </Container>
    </SelectedPreviewDeviceProvider>
  );
};

export default StepEditor;
