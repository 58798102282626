import React, { Dispatch, FC, SetStateAction } from "react";

import { ArchivedConfiguration, LiveConfiguration } from "interfaces";

import Button from "components/Button";
import { NoItemsWarning, TableHead } from "components/Table";

import EditingInfo from "../EditingInfo/EditingInfo";

type ArchivedConfigurationsTableProps = {
  configurations: ArchivedConfiguration[] | null;
  hasDraftConfiguration: boolean;
  setBaseConfiguration: Dispatch<
    SetStateAction<LiveConfiguration | ArchivedConfiguration | null>
  >;
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
};

const ArchivedConfigurationsTable: FC<ArchivedConfigurationsTableProps> = ({
  configurations,
  hasDraftConfiguration,
  setBaseConfiguration,
  setShowCreateModal,
}) => {
  if (!configurations || configurations.length === 0) {
    return (
      <NoItemsWarning>
        <p>There is no archived configuration at the moment.</p>
        <p>You will see here the past live configurations.</p>
      </NoItemsWarning>
    );
  }

  const handleOnClick = (configuration: ArchivedConfiguration) => {
    setBaseConfiguration(configuration);
    setShowCreateModal(true);
  };

  return (
    <table className="configurations-table">
      <TableHead headers={["Name", "Archived by", "Created by", ""]} />
      <tbody>
        {configurations.map((configuration) => {
          return (
            <tr
              key={`archived-configuration-${configuration.id}`}
              className="table-row"
            >
              <td className="table-primary-cell">{configuration.name}</td>
              <td className="table-secondary-cell">
                <EditingInfo
                  date={configuration.archived_at}
                  email={configuration.last_updated_by_email}
                />
              </td>
              <td className="table-secondary-cell">
                <EditingInfo
                  date={configuration.created_at}
                  email={configuration.created_by_email}
                />
              </td>
              <td className="table-secondary-cell">
                {!hasDraftConfiguration && (
                  <Button
                    appearance="primary"
                    text="Copy as a new draft"
                    handleOnClick={() => handleOnClick(configuration)}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ArchivedConfigurationsTable;
