import React, { useRef } from "react";

import { useRadio } from "@react-aria/radio";
import { SpectrumRadioProps } from "@react-types/radio";
import cn from "classnames";

import { useRadioProvider } from "components/Radio/context";

export const Ticker: React.FC<SpectrumRadioProps> = (props) => {
  const { children, value, id } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const radioGroupProps = useRadioProvider();
  const { state } = radioGroupProps;
  const { inputProps } = useRadio(
    {
      ...props,
      ...radioGroupProps,
    },
    state,
    inputRef
  );

  return (
    <label className="flex flex-row items-center">
      <input
        type={"radio"}
        {...inputProps}
        ref={inputRef}
        id={id}
        checked={state.selectedValue === value}
        className={cn(
          "focus:outline-none focus:ring-0 focus:ring-offset-0",
          "bg-surfacePrimary",
          "border border-surfaceTertiary",
          "checked:focus:bg-brand-primary",
          "checked:hover:bg-brand-primary",
          "checked:bg-brand-primary"
        )}
      />

      <span
        className={cn("px-2.5 h-6", "cursor-pointer", "text-base font-normal")}
      >
        {children}
      </span>
    </label>
  );
};
