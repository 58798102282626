import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

const Container: FC = ({ children }) => {
  const { selectedPreviewDevice } = useSelectedPreviewDevice();

  return (
    <div
      className={cn("flex  text-text", {
        "flex-col-reverse": selectedPreviewDevice === "desktop",
        "flex-row": selectedPreviewDevice === "mobile",
      })}
    >
      {children}
    </div>
  );
};

export default Container;
