import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  FieldWithColorOptions,
  FieldWithFontSizeOptions,
  FieldWithFontWeightOptions,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

const SwitchEditor: FC = () => (
  <Group>
    <GroupHeader text="Switch" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.switch.background_color"
            label="Background color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Label"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.switch.label.color"
            label="Font color"
            showDefaultOption={true}
          />
          <FieldWithFontSizeOptions
            id="style.switch.label.font_size"
            label="Font size"
          />
          <FieldWithFontWeightOptions
            id="style.switch.label.font_weight"
            label="Font weight"
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Active state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.switch._active.background_color"
            label="Background color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Error state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.switch.error.color"
            label="Color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default SwitchEditor;
