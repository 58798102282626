import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useAuth } from "components/AuthProvider";
import Dropdown from "components/Dropdown/Dropdown";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import { Switch } from "components/Switch/Switch";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import { getDisplaySettings } from "../SchemaRenderer";

type SchemaEligibilityCheckEditorProps = {
  propertyPath: string;
};

interface DropdownOption {
  label: string;
  value: string;
}

const SchemaReferralStatsEditor: FC<SchemaEligibilityCheckEditorProps> = ({
  propertyPath,
}) => {
  const { axios } = useAuth();

  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  const [configurableReferralStats, setConfigurableReferralStats] = useState<
    DropdownOption[]
  >([]);

  const { control, getValues } = useFormContext();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const showReferralStatsId = `${propertyPath}.show_referral_stats`;
  const referralStatsId = `${propertyPath}.referral_stats`;

  if (!axios || !selectedMarket || !selectedCampaign)
    return <ErrorAlert message="Context is invalid" />;

  const displaySettings = getDisplaySettings(propertyPath);

  const fetchStats = async (source: CancelTokenSource) => {
    try {
      const {
        data: { referral_stats },
      } = await axios.get("/api/referral_stats", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      if (!referral_stats) return;
      setConfigurableReferralStats(referral_stats);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
      }
    }
  };

  const loadConfigurables = async (source: CancelTokenSource) => {
    await fetchStats(source);
    setLoading(false);
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurables(source);

    return () => source.cancel();
  }, []);

  useWatch({ name: showReferralStatsId });

  // update to use stats field
  const selectedReferralStats =
    getValues(referralStatsId)?.map((stat: string) => {
      return configurableReferralStats.find(
        (configurableReferralStats) => configurableReferralStats.value === stat
      );
    }) || [];

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Controller
        control={control}
        name={showReferralStatsId}
        key={showReferralStatsId}
        render={({ field: { onChange } }) => (
          <Switch
            id={showReferralStatsId}
            isSelected={getValues(showReferralStatsId)}
            onChange={onChange}
          >
            {displaySettings?.show_referral_stats?._label}
          </Switch>
        )}
      />
      {getValues(showReferralStatsId) && (
        <Controller
          control={control}
          name={referralStatsId}
          key={referralStatsId}
          render={({ field: { onChange } }) => (
            <Dropdown
              isMulti={true}
              label={displaySettings?.referral_stats?._help}
              id={referralStatsId}
              items={configurableReferralStats}
              value={selectedReferralStats}
              onChange={(selectedStats) =>
                onChange(
                  selectedStats.map(
                    (configurableStat: DropdownOption) => configurableStat.value
                  )
                )
              }
            />
          )}
        />
      )}
    </>
  );
};

export default SchemaReferralStatsEditor;
