import React, { FC } from "react";

import { AxiosError } from "axios";
import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { Accordion } from "components/Accordion/Accordion";
import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast/ErrorToast";
import { TextField } from "components/TextField/TextField";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type FormValues = {
  certificateBody: string;
  privateKey: string;
  certificateChain?: string;
};

const CertificateConfiguration: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const { reloadDomain, setReloadDomain } = useWrapperSiteEditor();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormValues>({ mode: "onChange" });

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const importCertificate = async (formData: FormValues) => {
    await axios.post("/api/acm_certificate_import", {
      region_key: selectedMarket.region_key,
      campaign_id: selectedCampaign.id,
      certificate_body: formData.certificateBody,
      private_key: formData.privateKey,
      certificate_chain: formData.certificateChain,
    });

    setReloadDomain(!reloadDomain);
  };

  const onSubmit = (formData: FormValues) => {
    toast.promise(importCertificate(formData), {
      loading: "Importing certificate...",
      success: "Certificate imported",
      error: function MyToast(e: AxiosError) {
        return <ErrorToast error={e} />;
      },
    });
  };

  const certificateRegex =
    /^-----BEGIN CERTIFICATE-----\n(.+\n)+-----END CERTIFICATE-----\n?$/;
  const privateKeyRegex =
    /^-----BEGIN (.+\s)?PRIVATE KEY-----\n(.+\n)+-----END (.+\s)?PRIVATE KEY-----\n?$/;

  return (
    <Accordion label="Input certificate details" isExpandedByDefault={false}>
      <div className="flex flex-col w-full">
        <span>
          Paste the PEM-encoded certificate body, private key, and certificate
          chain below.
        </span>
        <div className="flex flex-col w-full mt-10">
          <form role="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col space-y-4">
              <Controller
                control={control}
                name="certificateBody"
                rules={{
                  required: { value: true, message: "Required" },
                  pattern: {
                    value: certificateRegex,
                    message:
                      "The certificate body provided is not in a valid PEM format.",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="certificateBody"
                    label="Certificate body"
                    aria-label="certificateBody"
                    elementType="textarea"
                    value={value}
                    placeholder="Paste the PEM-encoded certificate body below. Example:&#10;-----BEGIN CERTIFICATE-----&#10;ExampleCertificateBody&#10;-----END CERTIFICATE-----"
                    onChange={onChange}
                    validationState={error ? "invalid" : "valid"}
                    errorMessage={error?.message}
                    className="h-36"
                  />
                )}
              />
              <Controller
                control={control}
                name="privateKey"
                rules={{
                  required: { value: true, message: "Required" },
                  pattern: {
                    value: privateKeyRegex,
                    message:
                      "The certificate private key provided is not in a valid PEM format.",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="privateKey"
                    label="Certificate private key"
                    aria-label="privateKey"
                    elementType="textarea"
                    value={value}
                    placeholder="Paste the PEM-encoded certificate private key below. Example:&#10;-----BEGIN PRIVATE KEY-----&#10;ExamplePrivateKey&#10;-----END PRIVATE KEY-----"
                    onChange={onChange}
                    validationState={error ? "invalid" : "valid"}
                    errorMessage={error?.message}
                    className="h-36"
                  />
                )}
              />
              <Controller
                control={control}
                name="certificateChain"
                rules={{
                  pattern: {
                    value: certificateRegex,
                    message:
                      "The certificate body provided is not in a valid PEM format.",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="certificateChain"
                    label="Certificate chain - optional Info"
                    aria-label="certificateChain"
                    elementType="textarea"
                    value={value}
                    placeholder="Paste the PEM-encoded certificate \n chain below. Example:&#10;-----BEGIN CERTIFICATE-----&#10;ExampleCertificateChain&#10;-----END CERTIFICATE-----"
                    onChange={onChange}
                    validationState={error ? "invalid" : "valid"}
                    errorMessage={error?.message}
                    className="h-36"
                  />
                )}
              />
            </div>
            <div className="flex flex-col items-end mt-10">
              <div className="flex px-4 w-40">
                <Button
                  appearance="primary"
                  text="Submit"
                  type="submit"
                  disabled={!isDirty || !isValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Accordion>
  );
};

export default CertificateConfiguration;
