import React, { FC } from "react";

import { FlowId } from "interfaces";

import Button from "components/Button";
import FlowHeader from "components/CampaignConfigurationEditor/FlowsEditor/FlowHeader/FlowHeader";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

type InactiveFlowProps = {
  name: string;
  id: FlowId;
  activateFlow: (flowId: FlowId) => void;
};

const InactiveFlow: FC<InactiveFlowProps> = ({ id, name, activateFlow }) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const canActivateFlow = !selectedCampaignConfiguration.published_at;

  return (
    <div className="rounded-xl shadow bg-backgroundPrimary py-4 px-7 flex flex-row items-center">
      <FlowHeader name={name} />
      {canActivateFlow && (
        <div className="w-40">
          <Button
            appearance="primary"
            text="Activate"
            handleOnClick={() => activateFlow(id)}
            description={`Activate ${name} flow`}
          />
        </div>
      )}
    </div>
  );
};

export default InactiveFlow;
