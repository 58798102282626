import React, { FC } from "react";

import useHeaderDrawer from "hooks/useHeaderDrawer";

import Breadcrumbs from "components/Breadcrumbs";
import { Container, HeaderDrawer, TopBar } from "components/Header";

const Header: FC = () => {
  const { showDrawer } = useHeaderDrawer();

  return (
    <Container>
      <TopBar />
      <Breadcrumbs />
      {showDrawer && <HeaderDrawer />}
    </Container>
  );
};

export default Header;
