import {
  CSSObjectWithLabel,
  ControlProps,
  GroupBase,
  OptionProps,
  StylesConfig,
  Theme,
  ThemeConfig,
} from "react-select";

declare module "react-select/dist/declarations/src/Select" {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>,
  > {
    fieldId?: string;
  }
}

export const theme: ThemeConfig = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // All possible overrides
    // border color isFocused option is selected background color
    primary: "rgb(var(--surface-tertiary) / 1)",

    // option is focused background color
    primary25: "rgb(var(--surface-tertiary) / 1)",

    // active option background color not selected not disabled
    primary50: "rgb(var(--surface-tertiary) / 1)",
    primary75: "rgb(var(--primary) / 1)",

    danger: "rgb(var(--error:) / 1)",
    dangerLight: "rgb(var(--error:) / 1)",

    // option color selected and menu background color
    neutral0: "rgb(var(--surface-tertiary) / 1)",
    neutral5: "rgb(var(--surface-tertiary) / 1)", // disabled control background

    // border color disabled  and option color isSelected and control background
    neutral10: "rgb(var(--surface-tertiary) / 1)",

    // border color and option color isDisabled and indicator container color
    neutral20: "rgb(var(--surface-tertiary) / 1)",
    neutral30: "rgb(var(--surface-tertiary) / 1)", // control hover border

    // notices color and single value disabled
    neutral40: "rgb(var(--color-text) / 1)",

    neutral50: "rgb(var(--color-text) / 1)", // placeholder
    neutral60: "rgb(var(--color-text) / 1)", // indicator container color focus
    // neutral70: 'hsl(0, 0%, 30%)',

    // input color single value color
    neutral80: "rgb(var(--color-text) / 1)",
    // neutral90: 'hsl(0, 0%, 10%)',
  },
  // Other options you can use
  borderRadius: 8,
  // Used to calculate consistent margin/padding on elements
  baseUnit: 4,
  // The minimum height of the control
  controlHeight: 38,
  // The amount of space between the control and menu */
  menuGutter: 0,
});

export const customStyles: StylesConfig<unknown, false> = {
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginTop: "0px",
    marginBottom: "0px",
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "none",
    borderWidth: "2px",
    fontSize: "14px",
    outline: "2px solid transparent",
    outlineOffset: "2px",
    borderColor: "rgb(var(--primary) / 1)",
  }),
  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: "15px 20px 20px",
  }),
  control: (provided: CSSObjectWithLabel, state: ControlProps) => ({
    ...provided,
    alignContent: "center",
    minHeight: "30px",
    height: "30px",
    minWidth: "127.5px",
    borderRadius: "8px",
    boxShadow: "none",
    color: "rgb(var(--color-text) / 1)",
    fontSize: "14px",
    ":hover": {
      borderColor: "rgb(var(--primary) / 1)",
      borderBottomColor: state.selectProps.menuIsOpen
        ? "transparent"
        : "undefined",
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
      borderBottomRightRadius: state.selectProps.menuIsOpen ? 0 : "undefined",
    },
    borderColor:
      state.isFocused && state.selectProps.menuIsOpen
        ? "rgb(var(--primary) / 1)"
        : "transparent",

    borderWidth: "2px",
    borderBottomColor:
      state.isFocused && state.selectProps.menuIsOpen
        ? "transparent"
        : "undefined",
    borderBottomLeftRadius:
      state.isFocused && state.selectProps.menuIsOpen ? 0 : "undefined",
    borderBottomRightRadius:
      state.isFocused && state.selectProps.menuIsOpen ? 0 : "undefined",
    borderBottom:
      state.isFocused && state.selectProps.menuIsOpen ? "unset" : "undefined",
  }),
  option: (provided: CSSObjectWithLabel, state: OptionProps) => ({
    ...provided,
    borderBottom: "1px solid",
    borderColor: "rgb(var(--color-text) / 0.2)",
    fontWeight: state.isSelected ? "bold" : "normal",
    color: "rgb(var(--color-text) / 1)",
    padding: "10px 0px",
  }),
  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  singleValue: (provided: CSSObjectWithLabel) => ({ ...provided }),
  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    "input:focus": {
      boxShadow: "none",
    },
  }),
  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    color: "rgb(var(--color-text)",
  }),
  indicatorsContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    paddingRight: "5px",
  }),
  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({}),
  clearIndicator: () => ({}),
};
