import React, { useRef } from "react";

import { useRadio } from "@react-aria/radio";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { SpectrumRadioProps } from "@react-types/radio";
import cn from "classnames";

import { useRadioProvider } from "components/Radio/context";

export const Radio: React.FC<SpectrumRadioProps> = (props) => {
  const {
    /**
     * React children.
     */
    children,
    /**
     * Whether or not the radio is disabled.
     */
    isDisabled,
    /**
     * The value to associate with when in a group.
     */
    value,
    id,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const radioGroupProps = useRadioProvider();
  const { state } = radioGroupProps;
  const { inputProps } = useRadio(
    {
      ...props,
      ...radioGroupProps,
      isDisabled,
    },
    state,
    inputRef
  );

  const checked = state.selectedValue === value;

  return (
    <label className="flex items-start flex-wrap pr-4 space-y-2">
      <VisuallyHidden>
        <input
          type={"radio"}
          {...inputProps}
          ref={inputRef}
          id={id}
          checked={state.selectedValue === value}
          disabled={isDisabled}
        />
      </VisuallyHidden>

      <span
        className={cn(
          "flex justify-center items-center",
          "p-2 h-10 min-w-32 px-4",
          "cursor-pointer",
          "box-border rounded-full",
          "disabled:pointer-events-none disabled:opacity-50",
          "text-md font-medium",
          "focus:outline-none focus:ring-0",
          "hover:bg-brand-secondary hover:text-button-text",
          {
            "bg-brand-secondary text-button-text": checked,
            "bg-transparent text-text border-brand-secondary border-2":
              isDisabled || !checked,
          }
        )}
      >
        {children}
      </span>
    </label>
  );
};
