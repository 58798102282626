import React, { FC, useState } from "react";

import { FlowId } from "interfaces";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";

import Button from "components/Button";

type ActiveFlowMenuProps = {
  name: string;
  id: FlowId;
  deactivateFlow: (flowId: FlowId) => void;
};

const ActiveFlowMenu: FC<ActiveFlowMenuProps> = ({
  id,
  name,
  deactivateFlow,
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleMouseOver = () => {
    setShowMenu(true);
  };

  const handleMouseOut = () => {
    setShowMenu(false);
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className="relative"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        aria-label="Show flow menu"
      >
        <IconContext.Provider
          value={{
            className: "float-right cursor-pointer",
            size: "1.25em",
          }}
        >
          <BsThreeDots />
        </IconContext.Provider>
        <div
          data-testid="flow-menu"
          className={`hover:block ${
            showMenu ? "block" : "hidden"
          } text-sm text-text z-10 absolute w-32 -left-14 top-5 rounded-md shadow p-3 bg-backgroundSecondary`}
        >
          <Button
            appearance="link"
            text="Deactivate flow"
            handleOnClick={() => deactivateFlow(id)}
            description={`Deactivate ${name} flow`}
          />
        </div>
      </div>
    </div>
  );
};

export default ActiveFlowMenu;
