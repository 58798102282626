import React, { FC } from "react";

import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";

import ErrorAlert from "components/ErrorAlert";

import ResourceStatus from "./ResourceStatus/ResourceStatus";

const DomainInfoTable: FC = () => {
  const { domainConfig } = useWrapperSiteEditor();

  if (!domainConfig.info) {
    return <ErrorAlert message="Unable to get domain configuration" />;
  }

  return (
    <>
      <table className="bg-backgroundPrimary text-text table">
        <thead className="bg-surfacePrimary text-sm text-left">
          <tr>
            <th className="w-2/12 p-4 font-normal">Resource</th>
            <th className="w-3/12 p-4 font-normal">Name</th>
            <th className="w-1/12 p-4 font-normal">Type</th>
            <th className="w-4/12 p-4 font-normal">Value</th>
            <th className="w-3/12 p-4 font-normal">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-row-wrap">
            <td className="table-primary-cell">Certificate</td>
            <td className="table-secondary-cell">
              {domainConfig.info.acm.name}
            </td>
            <td className="table-secondary-cell">
              {domainConfig.info.acm.expiry_days ? "N/A" : "CNAME"}
            </td>
            <td className="table-secondary-cell">
              {domainConfig.info.acm.value}
            </td>
            <td className="table-secondary-cell">
              <div className="flex space-x-4">
                <ResourceStatus status={domainConfig.info.acm.status} />
                {domainConfig.info.acm.expiry_days && (
                  <div className="status-grey">
                    <span>
                      Expires: {domainConfig.info.acm.expiry_days} days
                    </span>
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr className="table-row-wrap">
            <td className="table-primary-cell">Cloudfront</td>
            <td className="table-secondary-cell">
              {domainConfig.info.cf.name}
            </td>
            <td className="table-secondary-cell">CNAME</td>
            <td className="table-secondary-cell">
              {domainConfig.info.cf.value}
            </td>
            <td className="table-secondary-cell">
              <ResourceStatus status={domainConfig.info.cf.status} />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DomainInfoTable;
