import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { CustomField } from "interfaces";
import { useFormContext } from "react-hook-form";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import { requiredConfigFor } from "components/CampaignConfigurationEditor/StepEditor/Editors/utils";
import Loader from "components/Loader";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

import CustomFieldsSettingsEditor from "../../CustomFieldsEditor/CustomFieldsSettingsEditor";

type CustomFieldsSettingsEditorProps = {
  pathRoot: string;
};

const SchemaCustomsFieldSettingsEditor: FC<CustomFieldsSettingsEditorProps> = ({
  pathRoot,
}) => {
  const { axios } = useAuth();

  const customFieldsPath = pathRoot + ".custom_fields";

  const [selectedMarket] = useSelectedMarketContext();

  const { getValues } = useFormContext();

  const [, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [configurableCustomFieldTypes, setConfigurableCustomFieldTypes] =
    useState<CustomField[]>([]);

  const requiredCustomFieldConfigFor = (type: string) =>
    requiredConfigFor(type, configurableCustomFieldTypes);

  const [configureCustomFields, setConfigureCustomFields] = useState<boolean>(
    getValues(customFieldsPath) && getValues(customFieldsPath).length > 0
  );

  const configurableCustomFieldTypesRequest = (source: CancelTokenSource) => {
    return axios.get("/api/custom_fields", {
      params: {
        region_key: selectedMarket?.region_key,
        market_id: selectedMarket?.id,
      },
      cancelToken: source.token,
    });
  };

  const loadConfigurableCustomFieldTypes = async (
    source: CancelTokenSource
  ) => {
    try {
      const [
        {
          data: { custom_fields },
        },
      ] = await Promise.all([configurableCustomFieldTypesRequest(source)]);

      setConfigurableCustomFieldTypes(custom_fields);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  const handleAddCustomField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setConfigureCustomFields(true);
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurableCustomFieldTypes(source);

    return () => source.cancel();
  }, []);

  const configurableToDropdowItems = (configurables: CustomField[]) =>
    configurables.map((configurable: CustomField) => ({
      label: configurable.name,
      value: configurable.id,
    }));

  const customFieldTypeDropdownItems = configurableToDropdowItems(
    configurableCustomFieldTypes
  );

  if (loading) {
    return <Loader />;
  }

  return configureCustomFields ? (
    <CustomFieldsSettingsEditor
      requiredCustomFieldConfigFor={requiredCustomFieldConfigFor}
      customFieldTypeDropdownItems={customFieldTypeDropdownItems}
      customFieldsPath={customFieldsPath}
      setConfigureCustomFields={setConfigureCustomFields}
    />
  ) : (
    <Button
      appearance="secondary"
      text="Add a custom field"
      handleOnClick={handleAddCustomField}
    />
  );
};

export default SchemaCustomsFieldSettingsEditor;
