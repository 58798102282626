import React, { FC } from "react";

export type FieldsetProps = {
  legend: string;
};

export const Fieldset: FC<FieldsetProps> = ({ legend, children }) => (
  <fieldset className="px-5 pb-7.5 rounded-xl border border-backgroundSecondary">
    <legend className="px-2.5 text-text font-bold text-xl">{legend}</legend>

    <div className="flex flex-col space-y-5 pt-10.25">{children}</div>
  </fieldset>
);
