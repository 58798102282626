import React, { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { TextField } from "components/TextField/TextField";

type DefaultValidationErrorEditorProps = {
  fieldPath: string;
  label: string;
  isDisabled?: boolean;
};
const DefaultValidationErrorEditor: FC<DefaultValidationErrorEditorProps> = ({
  fieldPath,
  label,
  isDisabled,
}) => {
  const { control, getValues } = useFormContext();

  const resolvedPath = `${fieldPath}.0.value`;

  return (
    <Controller
      control={control}
      key={resolvedPath}
      name={resolvedPath}
      render={({ field: { onChange } }) => (
        <>
          <input type="hidden" name={`${fieldPath}.0.key`} value="default" />

          <TextField
            id={resolvedPath}
            key={resolvedPath}
            label={label}
            aria-label={label}
            value={getValues(resolvedPath) || ""}
            isRequired={false}
            onChange={(e) => onChange(e)}
            elementType={"input"}
            isDisabled={isDisabled}
          />
        </>
      )}
    />
  );
};

export default DefaultValidationErrorEditor;
