import React, { FC } from "react";

import {
  hoursInSeconds,
  secondsInHours,
} from "helpers/TimeConversionHelper/TimeConversionHelper";
import { OptionTypeBase } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import CallToActionBuilder from "components/CallToActionBuilder/CallToActionBuilder";
import Dropdown from "components/Dropdown/Dropdown";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";
import VoucherDistribution from "components/VoucherDistribution/VoucherDistribution";

import CustomFieldsContentEditor from "../../CustomFieldsEditor/CustomFieldsContentEditor";
import TrackReferralOptionsEditor from "../../FriendJourney/TrackReferralOptionsEditor/TrackReferralOptionsEditor";
import SchemaArrayEditor from "../SchemaArrayEditor";
import { dropdownTypes, getDropdownItems } from "../SchemaDropDownItems";
import DefaultValidationErrorEditor from "./DefaultValidationErrorEditor";
import SchemaCustomsFieldSettingsEditor from "./SchemaCustomsFieldSettingsEditor";
import SchemaEligibilityCheckEditor from "./SchemaEligibilityCheckEditor";
import SchemaEmailTemplateEditor from "./SchemaEmailTemplateEditor";
import SchemaReferralStatsEditor from "./SchemaReferralStatsEditor";
import SchemaShareNetworksEditor from "./SchemaShareNetworksEditor";

const ControlRenderer: FC<{
  flowId: string; // e.g. "friend_journey"
  propType: string; // e.g. "voucher_distribution"
  propPath: string; // e.g. "friend_journey.shared.settings.voucher_distribution"
  isGroup: boolean | undefined;
  toggle: JSX.Element | null;
  label: string | undefined; // e.g. "Voucher distribution"
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  property: any | undefined; // e.g. {type: 'voucher_distribution'}
  isDisabled: boolean | undefined;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  displaySettings: any; // e.g. {_label: 'Voucher distribution'}
  fieldPath: string; // e.g. "friend_journey.shared.settings"
  prop: string; // e.g. "voucher_distribution"
}> = ({
  flowId,
  propType,
  propPath,
  isGroup,
  toggle,
  label,
  property,
  isDisabled,
  displaySettings,
  fieldPath,
  prop,
}) => {
  const { control, getValues } = useFormContext();

  switch (propType) {
    case "string":
      return (
        <Controller
          control={control}
          key={propPath}
          name={propPath}
          render={({ field: { onChange } }) => (
            <TextField
              id={propPath}
              key={propPath}
              label={isGroup || toggle ? label : null}
              aria-label={label}
              value={getValues(propPath) || ""}
              isRequired={property.required}
              onChange={(e) => onChange(e)}
              elementType={property.long ? "textarea" : "input"}
              isDisabled={isDisabled}
              placeholder={displaySettings?._placeholder}
              tooltip={displaySettings?._tooltip}
            />
          )}
        />
      );
    case "number":
      return (
        <Controller
          control={control}
          key={propPath}
          name={propPath}
          render={({ field: { onChange } }) => (
            <TextField
              id={propPath}
              key={propPath}
              label={isGroup || toggle ? label : null}
              aria-label={label}
              value={getValues(propPath)}
              isRequired={property.required}
              onChange={(e) => onChange(e)}
              elementType="input"
              isDisabled={isDisabled}
              type="number"
              min="0"
              placeholder={displaySettings?._placeholder}
            />
          )}
        />
      );
    case "seconds":
      return (
        <Controller
          control={control}
          key={propPath}
          name={propPath}
          // commented out for now because of inconsistencies in data model validation
          // TODO: sort out referr journey shared email section
          // shouldUnregister={hasToggle}
          render={({ field: { onChange } }) => (
            <TextField
              id={propPath}
              key={propPath}
              label={isGroup || toggle ? label : null}
              aria-label={label}
              isRequired={property.required}
              value={secondsInHours(getValues(propPath))?.toFixed() || ""}
              onChange={(e) => onChange(hoursInSeconds(parseFloat(e)) || null)}
              elementType="input"
              isDisabled={isDisabled}
              type="number"
              min="0"
              placeholder={displaySettings?._placeholder}
            />
          )}
        />
      );
    case "boolean":
      return (
        <Controller
          control={control}
          key={propPath}
          name={propPath}
          render={({ field: { onChange, value } }) => {
            const isSelected = displaySettings?._reverse ? !value : value;

            return (
              <Switch
                id={propPath}
                isSelected={isSelected}
                onChange={(isSelected) => {
                  if (displaySettings?._reverse) return onChange(!isSelected);

                  onChange(isSelected);
                }}
                isDisabled={isDisabled}
              >
                {label}
              </Switch>
            );
          }}
        />
      );
    case "custom_fields":
      const customFields = getValues(property.path || propPath);

      return (
        <CustomFieldsContentEditor
          key={fieldPath}
          allowTooltipConfiguration={property.tooltips}
          customFieldsPath={propPath}
          configuredCustomFields={customFields}
          setShowConfiguredCustomFields={() => false}
        />
      );
    case "custom_fields_settings":
      return (
        <SchemaCustomsFieldSettingsEditor key={propPath} pathRoot={fieldPath} />
      );
    case "email_template":
      return (
        <SchemaEmailTemplateEditor
          key={propPath}
          fieldPath={propPath}
          flowId={flowId}
          emailTemplateType={property.email_template_type}
        />
      );
    case "eligibility_check":
      return (
        <SchemaEligibilityCheckEditor
          key={propPath}
          pathRoot={propPath}
          flowId={flowId}
        />
      );
    case "array":
      return (
        <SchemaArrayEditor
          key={propPath}
          pathRoot={propPath}
          property={property}
        />
      );
    case "dropdown":
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      const dropdownType = (dropdownTypes as any)[property.dropdownType];
      const items = getDropdownItems(dropdownType);
      const selectedItem =
        items.find(
          (identifierType) => identifierType.value === getValues(propPath)
        ) || null;

      return (
        <Controller
          control={control}
          name={propPath}
          key={propPath}
          render={({ field: { onChange } }) => (
            <Dropdown
              id={propPath}
              value={selectedItem}
              items={items}
              onChange={(e) => onChange((e as OptionTypeBase).value)}
              label={label || ""}
            />
          )}
        />
      );
    case "track_referrals":
      return (
        <TrackReferralOptionsEditor
          key={propPath}
          propertyPath={propPath}
          customFieldPath={property.path}
          displaySettings={displaySettings}
        />
      );
    case "call_to_actions":
      return (
        <CallToActionBuilder
          contentPath={propPath}
          bpToggleDisplayed={displaySettings?._display_options.bp_toggle}
        />
      );
    case "share_networks":
      return (
        <SchemaShareNetworksEditor
          key={propPath}
          fieldPath={propPath}
          label={displaySettings?._help || prop}
        />
      );
    case "referral_stats":
      return (
        <SchemaReferralStatsEditor
          key={propPath}
          propertyPath={propPath.replace(`.${prop}`, "")}
        />
      );
    case "validation_error":
      return (
        <DefaultValidationErrorEditor
          key={propPath}
          fieldPath={propPath}
          label={displaySettings?._label || prop}
          isDisabled={isDisabled}
        />
      );
    case "voucher_distribution":
      return (
        <VoucherDistribution
          flowId={flowId}
          fieldPath={fieldPath}
          displayEmailSettings={
            displaySettings?._display_options.email_settings
          }
        />
      );
    default:
      return <></>;
  }
};

export default ControlRenderer;
