{
  "partner_journey": {
    "shared": {
      "settings": {
        "emails": {
          "signed_up_email_template_id": {
            "type": "email_template",
            "email_template_type": "partner_signed_up"
          }
        }
      }
    },
    "partner_dashboard": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true
          },
          "referral_stats": {
            "title": {
              "type": "string",
              "required": true
            },
            "friend_visit_count": {
              "type": "string",
              "required": true,
              "long": false
            },
            "friend_registration_count": {
              "type": "string",
              "required": true,
              "long": false
            },
            "pending_referral_count": {
              "type": "string",
              "required": true,
              "long": false
            },
            "complete_referral_count": {
              "type": "string",
              "required": true,
              "long": false
            },
            "cancelled_referral_count": {
              "type": "string",
              "required": true,
              "long": false
            }
          },
          "partner_offer": {
            "title": {
              "type": "string",
              "required": true
            },
            "heading": {
              "type": "string",
              "required": true
            },
            "body": {
              "type": "string",
              "required": true
            },
            "image": {
              "url": {
                "type": "string",
                "required": true
              },
              "alt_text": {
                "type": "string",
                "required": true
              }
            }
          },
          "audience_offer": {
            "title": {
              "type": "string",
              "required": true
            },
            "heading": {
              "type": "string",
              "required": true
            },
            "body": {
              "type": "string",
              "required": true
            },
            "image": {
              "url": {
                "type": "string",
                "required": true
              },
              "alt_text": {
                "type": "string",
                "required": true
              }
            }
          },
          "sharing_tools": {
            "title": {
              "type": "string",
              "required": true
            },
            "direct_link": {
              "label": {
                "type": "string",
                "required": true
              },
              "copy_button_text": {
                "type": "string",
                "required": true
              },
              "copy_success_notification": {
                "type": "string",
                "required": true
              },
              "share_message": {
                "type": "string",
                "required": true
              },
              "native_sharing": {
                "title": {
                  "type": "string",
                  "required": true
                },
                "button_text": {
                  "type": "string",
                  "required": true
                }
              }
            },
            "share_code": {
              "label": {
                "type": "string",
                "required": true
              },
              "copy_button_text": {
                "type": "string",
                "required": true
              },
              "copy_success_notification": {
                "type": "string",
                "required": true
              },
              "share_message": {
                "type": "string",
                "required": true
              },
              "native_sharing": {
                "title": {
                  "type": "string",
                  "required": true
                },
                "button_text": {
                  "type": "string",
                  "required": true
                }
              }
            }
          },
          "useful_links": {
            "title": {
              "type": "string",
              "required": true
            },
            "buttons": [
              {
                "text": {
                  "type": "string",
                  "required": true
                }
              },
              {
                "url": {
                  "type": "string",
                  "required": true
                }
              }
            ]
          },
          "performance": {
            "title": {
              "type": "string",
              "required": true
            },
            "table": {
              "download_button_text": {
                "type": "string",
                "required": true
              },
              "headers": {
                "month": {
                  "type": "string",
                  "required": true
                },
                "visits": {
                  "type": "string",
                  "required": true
                },
                "created": {
                  "type": "string",
                  "required": true
                },
                "confirmed": {
                  "type": "string",
                  "required": true
                },
                "cancelled": {
                  "type": "string",
                  "required": true
                },
                "rewards_confirmed": {
                  "type": "string",
                  "required": true
                }
              },
              "tooltips": {
                "visits": {
                  "type": "string",
                  "required": true
                },
                "created": {
                  "type": "string",
                  "required": true
                },
                "confirmed": {
                  "type": "string",
                  "required": true
                },
                "cancelled": {
                  "type": "string",
                  "required": true
                }
              },
              "total": {
                "type": "string",
                "required": true
              }
            }
          },
          "rules": {
            "title": {
              "type": "string",
              "required": true
            },
            "body": {
              "type": "string",
              "long": true,
              "required": true
            }
          }
        }
      },
      "settings": {
        "audience_offer": {
          "display_rewards": {
            "type": "boolean",
            "required": true
          }
        },
        "partner_offer": {
          "display_rewards": {
            "type": "boolean",
            "required": true
          }
        },
        "referral_stats": {
          "type": "referral_stats"
        },
        "sharing_tools": {
          "display_direct_link": {
            "type": "boolean",
            "required": true
          },
          "display_share_code": {
            "type": "boolean",
            "required": true
          }
        }
      }
    }
  }
}
