import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

const Nav: FC = ({ children }) => {
  const { selectedPreviewDevice } = useSelectedPreviewDevice();

  return (
    <div
      className={cn("flex flex-row mb-5 items-center justify-end space-x-2.5", {
        "w-1/3": selectedPreviewDevice === "desktop",
      })}
    >
      <div
        className={cn(
          "flex flex-row w-93.75 mb-3 items-center justify-end space-x-2.5",
          {
            "mx-auto": selectedPreviewDevice === "desktop",
          }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Nav;
