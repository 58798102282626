import React, { FC } from "react";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

const UnavailablePreviewWarning: FC = () => {
  const [selectedMarket] = useSelectedMarketContext();

  if (!selectedMarket) {
    throw new Error("Something went wrong - context not set correctly");
  }

  return (
    <p className="p-12 text-center text-text">
      This step isn&apos;t currently previewable, please make sure you have
      saved content in the default locale ({selectedMarket.default_locale}) for
      each step of this flow in order to enable the preview
    </p>
  );
};

export default UnavailablePreviewWarning;
