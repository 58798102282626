import React from "react";

import { EditorComponent } from "interfaces";

import OptionSelectorEditorWrapper from "components/CampaignConfigurationEditor/StepEditor/Editors/ClickToClaim/OptionSelectorEditor/OptionSelectorEditorWrapper/OptionSelectorEditorWrapper";
import schema from "components/CampaignConfigurationEditor/StepEditor/Editors/ClickToClaimReferrerJourney/schema.json";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";
import ErrorAlert from "components/ErrorAlert";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const OptionSelectorSettingsEditor: EditorComponent = ({ flowId }) => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign)
    return <ErrorAlert message="Context is invalid" />;

  const journeyId = "options_selector";
  const section = "settings";

  return (
    <OptionSelectorEditorWrapper
      flowId={flowId}
      setConfigurableRewardTypes={() => undefined}
    >
      <SchemaRenderer
        schema={schema}
        flowId={flowId}
        journeyId={journeyId}
        section={section}
        locale={""}
        includeShared={false}
      />
    </OptionSelectorEditorWrapper>
  );
};

export default OptionSelectorSettingsEditor;
