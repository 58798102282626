import React, { FC } from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import Button from "components/Button";
import { Fieldset } from "components/Fieldset/Fieldset";
import { TextField } from "components/TextField/TextField";

import labels from "./display-schema.json";

const SchemaArrayEditor: FC<{
  pathRoot: string;
  property: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}> = ({ pathRoot, property }) => {
  const { control, getValues, setValue } = useFormContext();

  const { append, remove } = useFieldArray({
    name: pathRoot,
  });

  const entries = getValues(pathRoot) || [];

  const removeArrayItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    remove(index);
  };

  const addArrayItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!getValues(pathRoot)) {
      setValue(pathRoot, []);
    }

    append({});
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const getDisplaySettings = (path: string): any => {
    const paths = [...path.split(".")];

    return paths.reduce((response, current) => {
      if (!response) return undefined;

      return response[current] || response["locale"];
    }, labels as any); // eslint-disable-line @typescript-eslint/no-explicit-any
  };

  const displaySettings = getDisplaySettings(pathRoot);

  return (
    <>
      {entries.map((entry: string, index: number) => (
        <Fieldset
          key={`${pathRoot}-${index}`}
          legend={`${displaySettings._array ? displaySettings._array : "Item"} #${index + 1}`}
        >
          {/*TODO: rationalise render so any field type can be used*/}
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {property.map((child: any) => {
            const childPropName = Object.keys(child)[0];

            return (
              <Controller
                control={control}
                key={`${pathRoot}.${index}.${childPropName}`}
                name={`${pathRoot}.${index}.${childPropName}`}
                render={({ field: { onChange } }) => (
                  <TextField
                    elementType="input"
                    id={`${pathRoot}.${index}.${childPropName}`}
                    label={displaySettings[childPropName]?._label}
                    aria-label={displaySettings[childPropName]?._label}
                    value={getValues(`${pathRoot}.${index}.${childPropName}`)}
                    onChange={(e) => onChange(e)}
                  />
                )}
              />
            );
          })}
          <Button
            testId={`remove-cf-${index}`}
            appearance="danger"
            text="Remove"
            handleOnClick={(e) => removeArrayItem(e, index)}
          />
        </Fieldset>
      ))}
      <Button appearance="secondary" text="Add" handleOnClick={addArrayItem} />
    </>
  );
};

export default SchemaArrayEditor;
