import { FC, ReactElement, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";

type CaptureAdditionalFriendDetailsEditorWrapperProps = {
  children: ReactElement;
};

const CaptureAdditionalFriendDetailsEditorWrapper: FC<
  CaptureAdditionalFriendDetailsEditorWrapperProps
> = ({ children }) => {
  const { setPreviewStates } = usePreviewStates();

  useEffect(() => {
    setPreviewStates([
      {
        label: "Default",
        value: {},
      },
      {
        label: "Friend coming back to provide the additional details",
        value: { is_first_visiting_capture_friend_additional_details: false },
      },
    ]);

    return () => setPreviewStates([]);
  }, []);

  return children;
};

export default CaptureAdditionalFriendDetailsEditorWrapper;
