import React, { useEffect } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";
import { GroupComponent } from "interfaces";
import { Redirect, Route, Switch } from "react-router-dom";

import BodyWrapper from "components/BodyWrapper";
import Container from "components/CampaignConfigurationEditor/RewardManagementEditor/Container/Container";
import RewardOptionsEditor from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsEditor/RewardOptionsEditor";
import RewardTypesList from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardTypesList/RewardTypesList";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const RewardManagementEditor: GroupComponent = ({
  flowId,
  flowName,
  flowPath,
  group,
}) => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedMarket || !selectedCampaign || !selectedCampaignConfiguration) {
    throw new Error("Context isn't set correctly");
  }

  const { setBreadcrumbItems } = useBreadcrumb();

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;
  const campaignConfigurationEditorPath = `${campaignEditorPath}/configuration/${selectedCampaignConfiguration.id}`;
  const rewardManagementPath = `${campaignConfigurationEditorPath}${flowPath}/reward-management`;

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Configuration",
        value: selectedCampaignConfiguration.name,
        path: campaignConfigurationEditorPath,
      },
      {
        label: flowName,
        value: group.name,
      },
    ]);
  }, []);

  return (
    <BodyWrapper>
      <Container>
        <Switch>
          <Route exact path={`${rewardManagementPath}/:rewardTypeId/edit`}>
            <RewardOptionsEditor
              flowId={flowId}
              flowName={flowName}
              flowPath={flowPath}
              groupName={group.name}
            />
          </Route>
          <Route exact path={rewardManagementPath}>
            <RewardTypesList flowId={flowId} />
          </Route>
          <Route render={() => <Redirect to={rewardManagementPath} />} />
        </Switch>
      </Container>
    </BodyWrapper>
  );
};

export default RewardManagementEditor;
