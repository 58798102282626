import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

const Container: FC = ({ children }) => {
  const { selectedPreviewDevice } = useSelectedPreviewDevice();

  return (
    <div
      className={cn("bg-surfaceSecondary h-fit min-h-full", {
        "w-1/3": selectedPreviewDevice === "mobile",
        "w-full": selectedPreviewDevice === "desktop",
      })}
    >
      {children}
    </div>
  );
};

export default Container;
