import React, { FC } from "react";

import {
  findDropdownItemByValue,
  itemsToDropdowItems,
} from "helpers/DropdownHelper";
import { OptionTypeBase, OptionsBasedReward } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import Dropdown from "components/Dropdown/Dropdown";

type OptionBasedSubtypeFieldsProps = {
  rewards: OptionsBasedReward[];
  emailTemplateDropdownItems: OptionTypeBase[];
  manualEmailTemplateDropdownItems: OptionTypeBase[];
};

const OptionBasedSubtypeFields: FC<OptionBasedSubtypeFieldsProps> = ({
  emailTemplateDropdownItems,
  rewards,
  manualEmailTemplateDropdownItems,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const selectedEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(emailTemplateDropdownItems, value);

  const rewardOptionDropdownItems = itemsToDropdowItems(rewards);
  const selectedRewardOptionItem = (value: string) =>
    findDropdownItemByValue(rewardOptionDropdownItems, value);

  const selectedManualEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(manualEmailTemplateDropdownItems, value);

  return (
    <>
      <Controller
        control={control}
        name={"settings.email_template_id"}
        rules={{ required: { value: true, message: "Required" } }}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"email_template_id"}
            value={selectedEmailTemplateItem(value)}
            items={emailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item.value)}
            label="Reward email template"
            validationState={
              errors?.settings?.email_template_id ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.email_template_id?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.reward_option_id"}
        rules={{ required: { value: true, message: "Required" } }}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"reward_option_id"}
            value={selectedRewardOptionItem(value)}
            items={rewardOptionDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item.value)}
            label="Reward option"
            validationState={
              errors?.settings?.reward_option_id ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.reward_option_id?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.manual_email_template_id"}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"manual_email_template_id"}
            value={selectedManualEmailTemplateItem(value)}
            items={manualEmailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
            label="Manual Reward email template"
            isClearable={true}
          />
        )}
      />
    </>
  );
};

export default OptionBasedSubtypeFields;
