import React, { FC } from "react";

import { findItemById } from "helpers/ArrayHelper";
import {
  EmailTemplate,
  RewardOption,
  VoucherPool,
  VoucherRewardSettings,
} from "interfaces";

export type VoucherRewardOptionProps = {
  rewardOption: RewardOption<VoucherRewardSettings>;
  voucherPools: VoucherPool[];
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
};

const VoucherRewardOption: FC<VoucherRewardOptionProps> = ({
  rewardOption,
  voucherPools,
  emailTemplates,
  manualRewardEmailTemplates,
}) => {
  const voucherPool = findItemById(
    voucherPools,
    rewardOption.settings.voucher_pool_id
  );

  const emailTemplate = findItemById(
    emailTemplates,
    rewardOption.settings.email_template_id
  );

  const manualEmailTemplate = findItemById(
    manualRewardEmailTemplates,
    rewardOption.settings.manual_email_template_id
  );

  return (
    <div className="flex-grow text-text">
      <div>
        <span>Option identifier: </span>
        {rewardOption.key}
      </div>
      <div>
        <span>Subtype: </span>
        {rewardOption.reward_class}
      </div>
      <div>
        <span>Voucher pool: </span>
        {voucherPool ? voucherPool.name : "Not set"}
      </div>
      <div>
        <span>Reward email template: </span>
        {emailTemplate ? emailTemplate.name : "Not set"}
      </div>
      <div>
        <span>Manual Reward email template: </span>
        {manualEmailTemplate ? manualEmailTemplate.name : "Not set"}
      </div>
    </div>
  );
};

export default VoucherRewardOption;
