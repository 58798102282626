import React, { FC } from "react";

import { findItemById } from "helpers/ArrayHelper";
import {
  EmailTemplate,
  OptionBaseRewardSettings,
  OptionsBasedReward,
  RewardOption,
} from "interfaces";

export type OptionBasedRewardOptionProps = {
  rewardOption: RewardOption<OptionBaseRewardSettings>;
  rewards: OptionsBasedReward[];
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
};

const OptionBasedRewardOption: FC<OptionBasedRewardOptionProps> = ({
  rewardOption,
  rewards,
  emailTemplates,
  manualRewardEmailTemplates,
}) => {
  const reward = findItemById(rewards, rewardOption.settings.reward_option_id);

  const emailTemplate = findItemById(
    emailTemplates,
    rewardOption.settings.email_template_id
  );

  const manualEmailTemplate = findItemById(
    manualRewardEmailTemplates,
    rewardOption.settings.manual_email_template_id
  );

  return (
    <div className="flex-grow text-text">
      <div>
        <span>Option identifier: </span>
        {rewardOption.key}
      </div>
      <div>
        <span>Subtype: </span>
        {rewardOption.reward_class}
      </div>
      <div>
        <span>Reward option: </span>
        {reward ? reward.name : "Not set"}
      </div>
      <div>
        <span>Reward email template: </span>
        {emailTemplate ? emailTemplate.name : "Not set"}
      </div>
      <div>
        <span>Manual Reward email template: </span>
        {manualEmailTemplate ? manualEmailTemplate.name : "Not set"}
      </div>
    </div>
  );
};

export default OptionBasedRewardOption;
