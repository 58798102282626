import React, { FC } from "react";

import { CheckboxField, InputField } from "components/Form";
import { NurtureConfig } from "components/Nurture/interfaces";

type NurtureMaxDaysSinceSignupSettingsRowProps = {
  nurtureConfig: NurtureConfig;
  handleInputToggle: (id: string, nextChecked: boolean) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NurtureMaxDaysSinceSignupSettingsRow: FC<
  NurtureMaxDaysSinceSignupSettingsRowProps
> = ({ nurtureConfig, handleInputToggle, handleInputChange }) => (
  <tr>
    <td className="p-4" colSpan={2}>
      <CheckboxField
        className="flex-row-reverse justify-end"
        label="Only include inactive Referrers that have signed up in the last"
        id="existing_referrer_max_days_since_signup"
        value={nurtureConfig.existing_referrer_max_days_since_signup !== null}
        handleToggle={handleInputToggle}
      />
    </td>
    <td className="flex flex-row p-4">
      <InputField
        id="existing_referrer_max_days_since_signup"
        testId="existing_referrer_max_days_since_signup"
        className="w-1/2"
        placeholder=""
        min="1"
        type="number"
        value={nurtureConfig.existing_referrer_max_days_since_signup || ""}
        handleInputChange={handleInputChange}
        disabled={
          nurtureConfig.existing_referrer_max_days_since_signup === null
        }
      />
      <div className="self-center w-1/2 px-4">days</div>
    </td>
  </tr>
);

export default NurtureMaxDaysSinceSignupSettingsRow;
