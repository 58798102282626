import { FC, ReactElement, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";

type SuccessfulRegistrationEditorWrapperProps = {
  children: ReactElement;
};

const SuccessfulRegistrationEditorWrapper: FC<
  SuccessfulRegistrationEditorWrapperProps
> = ({ children }) => {
  const { setPreviewStates } = usePreviewStates();

  useEffect(() => {
    setPreviewStates([
      {
        label: "Default",
        value: {},
      },
      {
        label: "Multiple referrals enabled",
        value: { allow_friend_additional_referral: true },
      },
    ]);

    return () => setPreviewStates([]);
  }, []);

  return children;
};

export default SuccessfulRegistrationEditorWrapper;
