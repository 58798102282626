import React, { FC, useRef } from "react";

import { useSwitch } from "@react-aria/switch";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useToggleState } from "@react-stately/toggle";
import { SpectrumSwitchProps } from "@react-types/switch";
import cn from "classnames";

export const Switch: FC<SpectrumSwitchProps> = (props) => {
  const state = useToggleState(props);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useSwitch(props, state, ref);

  return (
    <label
      className={cn(
        "flex items-center justify-between",
        "text-text",
        "cursor-pointer",
        {
          "cursor-not-allowed": inputProps.disabled,
        }
      )}
    >
      <span>{props.children}</span>
      <VisuallyHidden>
        <input {...inputProps} ref={ref} />
      </VisuallyHidden>
      <div
        className={cn(
          "flex items-center",
          "box-border",
          "shrink-0",
          "w-10.25",
          "h-6.25",
          "p-1",
          "ml-4",
          "rounded-full",
          "light:bg-surfacePrimary",
          {
            "justify-end shadow-[0_0_3px] bg-actions-positive":
              state.isSelected,
            border: !state.isSelected,
            "opacity-20": inputProps.disabled,
          }
        )}
      >
        <div
          className={cn(
            "w-4.25 h-4.25",
            "rounded-full",
            "shadow-[0_0_3px]",
            "border-1",
            "bg-surfacePrimary",
            {
              "dark:bg-button-text bg-button-text": state.isSelected,
            }
          )}
        ></div>
      </div>
    </label>
  );
};
