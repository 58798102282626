import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import {
  ArchivedConfiguration,
  DraftConfiguration,
  LiveConfiguration,
} from "interfaces";

import { useAuth } from "components/AuthProvider";
import ArchivedConfigurationsTable from "components/CampaignEditor/CampaignConfigurationsList/ArchivedConfigurationsTable/ArchivedConfigurationsTable";
import ConfigurationsGroup from "components/CampaignEditor/CampaignConfigurationsList/ConfigurationsGroup/ConfigurationsGroup";
import CreateCampaignConfigurationModal from "components/CampaignEditor/CampaignConfigurationsList/CreateCampaignConfigurationModal/CreateCampaignConfigurationModal";
import DraftConfigurationsTable from "components/CampaignEditor/CampaignConfigurationsList/DraftConfigurationsTable/DraftConfigurationsTable";
import LiveConfigurationTable from "components/CampaignEditor/CampaignConfigurationsList/LiveConfigurationTable/LiveConfigurationTable";
import ErrorAlert from "components/ErrorAlert";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const CampaignConfigurationsList: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [liveConfiguration, setLiveConfiguration] =
    useState<LiveConfiguration | null>(null);
  const [draftConfigurations, setDraftConfigurations] = useState<
    DraftConfiguration[]
  >([]);
  const [draftConfigurationsTotal, setDraftConfigurationsTotal] =
    useState<number>(0);
  const [archivedConfigurations, setArchivedConfigurations] = useState<
    ArchivedConfiguration[]
  >([]);
  const [archivedConfigurationsTotal, setArchivedConfigurationsTotal] =
    useState<number>(0);

  const [baseConfiguration, setBaseConfiguration] = useState<
    LiveConfiguration | ArchivedConfiguration | null
  >(null);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [sourceToken, setSourceToken] = useState<CancelTokenSource | null>(
    null
  );

  const loadConfigurations = async (source: CancelTokenSource | null) => {
    if (!selectedMarket || !selectedCampaign || !axios || !source) {
      throw new Error("Something went wrong - context not set correctly");
    }

    try {
      const {
        data: { published_configuration, draft, archived },
      } = await axios.get("/api/campaign_configurations", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setLiveConfiguration(published_configuration);
      setDraftConfigurations(draft.configurations);
      setDraftConfigurationsTotal(draft.total);
      setArchivedConfigurations(archived.configurations);
      setArchivedConfigurationsTotal(archived.total);
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurations(source);
    setSourceToken(source);

    return () => source.cancel();
  }, []);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  const hasDraftConfiguration = draftConfigurationsTotal > 0;

  return (
    <div className="flex flex-col gap-y-7.5">
      <CreateCampaignConfigurationModal
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        baseConfiguration={baseConfiguration}
        setBaseConfiguration={setBaseConfiguration}
        loadConfigurations={loadConfigurations}
        sourceToken={sourceToken}
      />

      <ConfigurationsGroup
        showTotal={false}
        total={null}
        circleColor="positive"
        title="Live configuration"
        loading={loading}
        defaultShowState={true}
      >
        <LiveConfigurationTable
          configuration={liveConfiguration}
          hasDraftConfiguration={hasDraftConfiguration}
          setBaseConfiguration={setBaseConfiguration}
          setShowCreateModal={setShowCreateModal}
        />
      </ConfigurationsGroup>

      <ConfigurationsGroup
        showTotal={true}
        total={draftConfigurationsTotal}
        circleColor="medium"
        title="Draft configurations"
        loading={loading}
        defaultShowState={true}
      >
        <DraftConfigurationsTable
          configurations={draftConfigurations}
          hasLiveConfiguration={!!liveConfiguration}
          setShowCreateModal={setShowCreateModal}
          loadConfigurations={loadConfigurations}
          sourceToken={sourceToken}
        />
      </ConfigurationsGroup>

      <ConfigurationsGroup
        showTotal={true}
        total={archivedConfigurationsTotal}
        circleColor="error"
        title="Archived configurations"
        loading={loading}
        defaultShowState={false}
      >
        <ArchivedConfigurationsTable
          configurations={archivedConfigurations}
          hasDraftConfiguration={hasDraftConfiguration}
          setBaseConfiguration={setBaseConfiguration}
          setShowCreateModal={setShowCreateModal}
        />
      </ConfigurationsGroup>
    </div>
  );
};

export default CampaignConfigurationsList;
