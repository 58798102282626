import React, { FC } from "react";

import { StepEditor } from "interfaces";

import FormWrapper from "components/CampaignConfigurationEditor/FormWrapper/FormWrapper";

type FormProps = {
  flowId: string;
  editor: StepEditor;
  currentLocale: string;
  goToPreviousLocale: () => void;
};

const Form: FC<FormProps> = ({
  flowId,
  editor,
  currentLocale,
  goToPreviousLocale,
}) => (
  <FormWrapper>
    {editor.component && (
      <editor.component
        flowId={flowId}
        locale={currentLocale}
        goToPreviousLocale={goToPreviousLocale}
      />
    )}
  </FormWrapper>
);

export default Form;
