import React, { FC } from "react";

type FieldLabelProps = {
  text: string;
};

const FieldLabel: FC<FieldLabelProps> = ({ text }) => (
  <label className="opacity-50 flex-1 text-text">{text}</label>
);

export default FieldLabel;
