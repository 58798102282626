import React from "react";

import {
  UseSelectedCampaignConfiguration,
  useSelectedCampaignConfiguration,
} from "hooks/useSelectedCampaignConfiguration";
import { SelectedCampaignConfiguration } from "interfaces";

import { createGenericContext } from "./createGenericContext";

interface Props {
  children: React.ReactNode;
  value?: SelectedCampaignConfiguration;
}

// Generate context
const [
  useSelectedCampaignConfigurationContext,
  SelectedCampaignConfigurationContextProvider,
] = createGenericContext<UseSelectedCampaignConfiguration>();

// Generate provider
const SelectedCampaignConfigurationProvider = ({
  children,
  value,
}: Props): React.ReactElement => {
  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useSelectedCampaignConfiguration(value || null);

  return (
    <SelectedCampaignConfigurationContextProvider
      value={[selectedCampaignConfiguration, setSelectedCampaignConfiguration]}
    >
      {children}
    </SelectedCampaignConfigurationContextProvider>
  );
};

export {
  useSelectedCampaignConfigurationContext,
  SelectedCampaignConfigurationProvider,
};
