import React, { FC } from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";

type Props = {
  showChildren: boolean;
  children: React.ReactElement;
};

const Transition: FC<Props> = ({ showChildren, children }) => (
  <TransitionGroup>
    {showChildren && (
      <CSSTransition timeout={100} classNames="easeBox">
        {children}
      </CSSTransition>
    )}
  </TransitionGroup>
);

export default Transition;
