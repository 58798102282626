import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import useHeaderDrawer from "hooks/useHeaderDrawer";
import { Campaign, Market, SelectedCampaignConfiguration } from "interfaces";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import { Container } from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

import GlobalStyleForm from "./GlobalStyleForm/GlobalStyleForm";

type GlobalStyleEditorProps = {
  selectedMarket: Market;
  selectedCampaign: Campaign;
  afterSave: (campaignConfiguration: SelectedCampaignConfiguration) => void;
};

const GlobalStyleEditor: FC<GlobalStyleEditorProps> = ({
  selectedMarket,
  selectedCampaign,
  afterSave,
}) => {
  const { axios } = useAuth();
  const { setShowDrawer } = useHeaderDrawer();
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!axios || !selectedCampaignConfiguration) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [customFonts, setCustomFonts] = useState<
    { value: string; label: string }[]
  >([]);

  const mapFontsList = (fonts: string[], styleFont: string) => {
    return fonts
      .concat([styleFont])
      .reduce((acc: string[], item: string) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, [])
      .sort()
      .map((font: string) => ({ value: font, label: font }));
  };

  const loadCustomFonts = async (source: CancelTokenSource) => {
    try {
      const {
        data: { fonts },
      } = await axios.get("/api/fonts", {
        params: {
          market_id: selectedMarket.id,
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setCustomFonts(
        mapFontsList(
          fonts,
          selectedCampaignConfiguration.value.style.font.family
        )
      );
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadCustomFonts(source);

    return () => source.cancel();
  }, []);

  const saveStyle = async (formData: Record<string, unknown>) => {
    try {
      const {
        data: { campaign_configuration },
      } = await axios.put(
        `/api/campaign_configurations/${selectedCampaignConfiguration.id}`,
        {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          version: selectedCampaignConfiguration.version,
          value: formData,
        }
      );

      afterSave(campaign_configuration);
    } finally {
      setShowDrawer(false);
    }
  };

  const handleSave = (formData: Record<string, unknown>) => {
    const updatingStyle = saveStyle(formData);

    toast.promise(updatingStyle, {
      loading: "Saving...",
      success: "Saved",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  if (loading) return null;

  return (
    <Container>
      {error ? (
        <ErrorAlert message={error} />
      ) : (
        <GlobalStyleForm customFonts={customFonts} handleSave={handleSave} />
      )}
    </Container>
  );
};

export default GlobalStyleEditor;
