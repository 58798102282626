import React, { FC } from "react";

import Tooltip from "rc-tooltip";

type TooltipWrapperProps = {
  show: boolean;
  text: string;
  placement: string;
  children: React.ReactElement;
};

const TooltipWrapper: FC<TooltipWrapperProps> = ({
  show,
  text,
  placement,
  children,
}) => {
  if (!show) {
    return children;
  }

  return (
    <Tooltip placement={placement} overlay={text}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default TooltipWrapper;
