import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

const EditorWrapper: FC<{ hasPreview: boolean }> = ({
  children,
  hasPreview,
}) => {
  const { selectedPreviewDevice } = useSelectedPreviewDevice();

  return (
    <div
      className={cn("p-8 flex flex-col space-y-7.5 text-color", {
        "w-2/3": hasPreview && selectedPreviewDevice === "mobile",
        "w-full": !hasPreview || selectedPreviewDevice === "desktop",
      })}
    >
      {children}
    </div>
  );
};

export default EditorWrapper;
