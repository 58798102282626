import React, { Dispatch, FC, SetStateAction } from "react";

import Button from "components/Button";
import ModalContainer from "components/ModalContainer/ModalContainer";

type ClearDomainConfirmationModalProps = {
  setShow: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
};

const ClearDomainConfirmationModal: FC<ClearDomainConfirmationModalProps> = ({
  setShow,
  handleConfirm,
}) => {
  const onConfirm = () => {
    setShow(false);
    handleConfirm();
  };

  return (
    <ModalContainer
      showModal={true}
      setShowModal={setShow}
      title="Confirmation"
    >
      <span className="flex text-text mb-5">
        Are you sure you would like to clear the domain for this wrapper site
        and delete the ACM Certificate and CloudFront Distribution? This means
        the client will have to redo any DNS configuration they may have done
        thus far.
      </span>
      <div className="flex justify-end">
        <div className="w-20">
          <Button
            testId="modal-cancel"
            appearance="danger"
            handleOnClick={() => setShow(false)}
            text="Cancel"
            role="button"
          />
        </div>
        <div className="w-40 ml-4">
          <Button
            testId="modal-confirm"
            appearance="primary"
            handleOnClick={onConfirm}
            text="Clear domain"
            role="button"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default ClearDomainConfirmationModal;
