import React, { FC } from "react";

import cn from "classnames";
import {
  EmailTemplate,
  OptionsBasedReward,
  RewardOption,
  RewardOptionSettings,
  VoucherPool,
} from "interfaces";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useWatch } from "react-hook-form";

import DraggableRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/DraggableRewardOption/DraggableRewardOption";

export type RewardOptionsListProps = {
  pathToRewardOptionsArray: string;
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
  voucherPools: VoucherPool[];
  rewards: OptionsBasedReward[];
  rewardOptions: Record<"id", string>[];
  moveRewardOption: (indexA: number, indexB: number) => void;
  removeRewardOption: (index: number) => void;
};

const RewardOptionsList: FC<RewardOptionsListProps> = ({
  pathToRewardOptionsArray,
  emailTemplates,
  manualRewardEmailTemplates,
  voucherPools,
  rewards,
  rewardOptions,
  moveRewardOption,
  removeRewardOption,
}) => {
  const watchFieldArray = useWatch({ name: pathToRewardOptionsArray });
  const controlledFields = rewardOptions.map(
    (rewardOption: Record<"id", string>, index: number) => {
      return {
        ...rewardOption,
        ...watchFieldArray[index],
      };
    }
  );

  const handleDrag = ({ source, destination }: DropResult) => {
    if (destination) {
      moveRewardOption(source.index, destination.index);
    }
  };

  return (
    <div
      className={cn(
        "p-5",
        "border border-backgroundSecondary rounded-xl flex-1"
      )}
    >
      <h2 className={cn("px-0 py-2.5", "text-text text-xl font-bold")}>
        List of reward options
      </h2>
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="test-items">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="divide-y divide-light-text divide-opacity-20 dark:divide-dark-text dark:divide-opacity-20"
            >
              {controlledFields.map(
                (
                  rewardOption: RewardOption<RewardOptionSettings>,
                  index: number
                ) => (
                  <DraggableRewardOption
                    key={`test[${index}]`}
                    rewardOptionsPath={pathToRewardOptionsArray}
                    rewardOption={rewardOption}
                    index={index}
                    emailTemplates={emailTemplates}
                    manualRewardEmailTemplates={manualRewardEmailTemplates}
                    voucherPools={voucherPools}
                    rewards={rewards}
                    showDragHandle={controlledFields.length > 1}
                    removeRewardOption={removeRewardOption}
                  />
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default RewardOptionsList;
