{
  "user_lookup_journey": {
    "lookup_user": {
      "content": {
        "locale": {
          "title": {
            "type": "string",
            "required": true
          },
          "body": {
            "type": "string",
            "long": true,
            "required": true
          },
          "image": {
            "url": {
              "type": "string",
              "required": true
            },
            "alt_text": {
              "type": "string",
              "required": true,
              "canBeEmpty": true
            }
          },
          "form": {
            "submit_error": {
              "type": "string",
              "required": true
            },
            "inputs": {
              "access_code": {
                "label": {
                  "type": "string",
                  "required": true
                },
                "placeholder": {
                  "type": "string",
                  "required": true
                },
                "validation_errors": {
                  "type": "validation_error",
                  "required": true
                },
                "tooltip": {
                  "type": "string",
                  "required": false
                }
              }
            },
            "button": {
              "text": {
                "type": "string",
                "required": true
              }
            }
          }
        }
      }
    }
  }
}
