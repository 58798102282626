import React, { FC } from "react";

import { Campaign } from "interfaces";
import { useHistory } from "react-router-dom";

import Button from "components/Button";
import ListContainer from "components/CampaignSelector/ListContainer/ListContainer";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

const CampaignsList: FC = () => {
  const [selectedMarket] = useSelectedMarketContext();
  const history = useHistory();

  if (!selectedMarket) {
    return <div className="w-full md:w-1/2" />;
  }

  const handleOnClick = (campaign: Campaign) =>
    history.push(`/${selectedMarket.subdomain}/${campaign.slug}`);

  return (
    <ListContainer header="Campaigns">
      {selectedMarket.campaigns.map((campaign) => (
        <li key={campaign.id} className="generic-list-item bg-surfacePrimary">
          <div className={"flex-grow flex flex-col justify-center"}>
            <div className={"text-md font-medium"}>{campaign.name}</div>
            <div className={"text-sm"}>{campaign.slug}</div>
          </div>
          <div className="w-40">
            <Button
              text="Edit"
              appearance="primary"
              handleOnClick={() => handleOnClick(campaign)}
            />
          </div>
        </li>
      ))}
    </ListContainer>
  );
};

export default CampaignsList;
