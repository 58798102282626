import React, { FC } from "react";

import classnames from "classnames";

type CheckboxFieldProps = {
  label: string;
  id: string;
  value: boolean;
  className?: string;
  handleToggle: (id: string, nextChecked: boolean) => void;
};

const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  id,
  value,
  className,
  handleToggle,
}) => (
  <div className={classnames("flex items-center", className)}>
    <label id={`label-${id}`} className="pr-5 text-sm">
      {label}
    </label>
    <input
      name={id}
      type="checkbox"
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-3"
      onChange={(e) => handleToggle(id, e.target.checked)}
      checked={value}
      aria-labelledby={`label-${id}`}
    />
  </div>
);

export default CheckboxField;
