import { useState } from "react";

import { Campaign } from "interfaces";

export type UseSelectedCampaign = [
  Campaign | null,
  React.Dispatch<React.SetStateAction<Campaign | null>>,
];

export const useSelectedCampaign = (
  newValue: Campaign | null
): UseSelectedCampaign => {
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
    newValue || null
  );

  return [selectedCampaign, setSelectedCampaign];
};
