import { useState } from "react";

import { SelectedCampaignConfiguration } from "interfaces";

export type UseSelectedCampaignConfiguration = [
  SelectedCampaignConfiguration | null,
  React.Dispatch<React.SetStateAction<SelectedCampaignConfiguration | null>>,
];

export const useSelectedCampaignConfiguration = (
  newValue: SelectedCampaignConfiguration | null
): UseSelectedCampaignConfiguration => {
  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useState<SelectedCampaignConfiguration | null>(newValue || null);

  return [selectedCampaignConfiguration, setSelectedCampaignConfiguration];
};
