import React, { Dispatch, FC, SetStateAction, useState } from "react";

import { AxiosError, CancelTokenSource } from "axios";
import { DraftConfiguration } from "interfaces";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ErrorToast from "components/ErrorToast";
import { NoItemsWarning, TableHead } from "components/Table";
import TooltipWrapper from "components/TooltipWrapper";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import DeleteCampaignConfigurationModal from "../DeleteCampaignConfigurationModal/DeleteCampaignConfigurationModal";
import EditingInfo from "../EditingInfo/EditingInfo";

type DraftConfigurationsTableProps = {
  configurations: DraftConfiguration[] | null;
  hasLiveConfiguration: boolean;
  setShowCreateModal: Dispatch<SetStateAction<boolean>>;
  loadConfigurations: (source: CancelTokenSource | null) => Promise<void>;
  sourceToken: CancelTokenSource | null;
};

const DraftConfigurationsTable: FC<DraftConfigurationsTableProps> = ({
  configurations,
  hasLiveConfiguration,
  setShowCreateModal,
  loadConfigurations,
  sourceToken,
}) => {
  const history = useHistory();
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [
    showPublicationConfirmationModal,
    setShowPublicationConfirmationModal,
  ] = useState<boolean>(false);
  const [selectedConfiguration, setSelectedConfiguration] =
    useState<DraftConfiguration | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteConfiguration, setDeleteConfiguration] =
    useState<DraftConfiguration | null>(null);

  if (!selectedMarket || !selectedCampaign || !axios) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const [isPublishing, setIsPublishing] = useState<boolean>(false);

  const handleEdit = (configurationId: string) => {
    history.push(
      `/${selectedMarket?.subdomain}/${selectedCampaign?.slug}/configuration/${configurationId}`
    );
  };

  const publish = async (campaignConfiguration: DraftConfiguration) => {
    try {
      await axios.post(
        `/api/campaign_configurations/${campaignConfiguration.id}/publish`,
        {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          version: campaignConfiguration.version,
        }
      );
    } finally {
      setIsPublishing(false);
      loadConfigurations(sourceToken);
    }
  };

  const handlePublish = () => {
    setIsPublishing(true);
    setShowPublicationConfirmationModal(false);
    if (selectedConfiguration) {
      toast.promise(publish(selectedConfiguration), {
        loading: "Publishing...",
        success: "Published",
        error: function MyToast(e: AxiosError) {
          return <ErrorToast error={e} />;
        },
      });
    }
  };

  const handleCreate = () => setShowCreateModal(true);
  const handleDelete = (configuration: DraftConfiguration) => {
    setShowDeleteModal(true);
    setDeleteConfiguration(configuration);
  };

  if (!configurations || configurations.length === 0) {
    return (
      <NoItemsWarning>
        <p>There is no draft configuration at the moment.</p>
        {hasLiveConfiguration ? (
          <p>Create a new draft from your live configuration.</p>
        ) : (
          <div className="w-1/4 mt-5 mb-2 p-4">
            <Button
              appearance="primary"
              text="Create your first draft"
              handleOnClick={handleCreate}
            />
          </div>
        )}
      </NoItemsWarning>
    );
  }

  const confirmPublish = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    configuration: DraftConfiguration
  ) => {
    e.preventDefault();
    setSelectedConfiguration(configuration);
    setShowPublicationConfirmationModal(true);
  };

  const headers = () => ["Name", "Created by", "Last updated by", "", "", ""];
  return (
    <>
      <table className="configurations-table auto">
        <TableHead headers={headers()} />
        <tbody>
          {configurations.map((configuration) => {
            return (
              <tr
                key={`draft-configuration-${configuration.id}`}
                className="table-row"
              >
                <td className="table-primary-cell">{configuration.name}</td>
                <td className="table-secondary-cell">
                  <EditingInfo
                    date={configuration.created_at}
                    email={configuration.created_by_email}
                  />
                </td>
                <td className="table-secondary-cell">
                  <EditingInfo
                    date={configuration.updated_at}
                    email={configuration.last_updated_by_email}
                  />
                </td>
                <td className="table-secondary-cell">
                  <Button
                    appearance="secondary"
                    text="Delete"
                    handleOnClick={() => handleDelete(configuration)}
                  />
                </td>
                <td className="table-secondary-cell">
                  <Button
                    appearance="secondary"
                    text="Edit"
                    handleOnClick={() => handleEdit(configuration.id)}
                  />
                </td>
                <td className="table-secondary-cell">
                  <TooltipWrapper
                    show={!configuration.publishable}
                    text="This draft is not publishable in its current state please edit the draft to review the errors"
                    placement="bottomLeft"
                  >
                    <Button
                      appearance="primary"
                      text="Publish"
                      handleOnClick={(e) => confirmPublish(e, configuration)}
                      disabled={isPublishing || !configuration.publishable}
                    />
                  </TooltipWrapper>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <DeleteCampaignConfigurationModal
        loadConfigurations={loadConfigurations}
        configuration={deleteConfiguration}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        sourceToken={sourceToken}
      />
      <ConfirmationModal
        showModal={showPublicationConfirmationModal}
        title="Are you sure you want to publish this new draft of your campaign?"
        positiveButtonText="Yes, publish"
        negativeButtonText="No"
        setToggle={setShowPublicationConfirmationModal}
        handlePositiveAction={handlePublish}
        handleNegativeAction={() => setShowPublicationConfirmationModal(false)}
      >
        <p className="text-text">
          Doing so will change the existing campaign to feature your most recent
          edits
        </p>
      </ConfirmationModal>
    </>
  );
};

export default DraftConfigurationsTable;
