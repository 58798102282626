import React, { FC } from "react";

import { InputField } from "components/Form";
import { NurtureConfig } from "components/Nurture/interfaces";

type NurtureInactiveReferrerSettingsRowProps = {
  nurtureConfig: NurtureConfig;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NurtureInactiveReferrerSettingsRow: FC<
  NurtureInactiveReferrerSettingsRowProps
> = ({ nurtureConfig, handleInputChange }) => (
  <tr>
    <td className="p-4 text-sm" colSpan={2}>
      Number of days until the referrer is considered inactive.
    </td>
    <td className="flex flex-row p-4">
      <InputField
        id="referrer_inactive_days"
        testId="referrer_inactive_days"
        className="w-1/2"
        placeholder=""
        min="1"
        type="number"
        value={nurtureConfig.referrer_inactive_days}
        handleInputChange={handleInputChange}
      />
      <div className="self-center w-1/2 px-4">days</div>
    </td>
  </tr>
);

export default NurtureInactiveReferrerSettingsRow;
