import React, { FC } from "react";

import { Controller, useFormContext, useWatch } from "react-hook-form";

import {
  FieldLabel,
  FieldWrapper,
  HexPreview,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type InputFieldProps = {
  id: string;
  label: string;
};

const InputField: FC<InputFieldProps> = ({ id, label }) => {
  const { getValues, control } = useFormContext();

  const value = useWatch({ name: id });
  const valueIsHexColor = value.match(/^#[A-Fa-f0-9]{6}$/);

  return (
    <FieldWrapper>
      <FieldLabel text={label} />
      <div className="h-7.5 w-fit text-sm text-text bg-surfaceTertiary rounded-lg px-2.5 py-1 gap-x-1.25 flex flex-row items-center justify-items-center focus-within:ring-2 ring-brand-primary shadow-sm">
        {valueIsHexColor && <HexPreview id={id} />}
        <Controller
          control={control}
          name={id}
          render={({ field: { onChange } }) => (
            <input
              id={id}
              aria-label={label}
              data-lpignore="true"
              autoComplete="off"
              spellCheck="false"
              value={getValues(id)}
              className="bg-surfaceTertiary w-20 focus:outline-none focus:ring-0 border-none text-center text-text"
              onChange={(e) => onChange(e.target.value)}
            />
          )}
        />
      </div>
    </FieldWrapper>
  );
};

export default InputField;
