import React, { FC } from "react";

const ArrowUpIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 14.5L12.3536 9.85355C12.1583 9.65829 11.8417 9.65829 11.6464 9.85355L7 14.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export default ArrowUpIcon;
