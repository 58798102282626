import React, { Dispatch, FC, SetStateAction } from "react";

import { IoClose } from "react-icons/io5";

type ModalContainerProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onCloseModal?: () => void;
  title?: string;
};

const ModalContainer: FC<ModalContainerProps> = ({
  showModal,
  setShowModal,
  onCloseModal,
  title,
  children,
}) => {
  if (!showModal) {
    return null;
  }

  const handleCloseModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (onCloseModal) {
      onCloseModal();
    }
    setShowModal(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto max-w-md">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-backgroundPrimary bg-opacity-100 dark:bg-opacity-100 outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 pb-2 rounded-t">
              {title && (
                <h2 className="font-medium text-text text-2xl">{title}</h2>
              )}
              <button
                className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleCloseModal}
              >
                <IoClose
                  className="m-auto h-full text-button-light-text dark:text-dark-text w-5 "
                  aria-label="Close modal"
                />
              </button>
            </div>
            <div className="relative p-6 pt-2 flex-auto">{children}</div>
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-light-surfacePrimary bg-opacity-80 dark:bg-dark-surfacePrimary dark:bg-opacity-80" />
    </>
  );
};

export default ModalContainer;
