import React, { FC, ReactElement, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import usePreviewStates from "hooks/usePreviewStates";
import { Item, RewardType } from "interfaces";

import { useAuth } from "components/AuthProvider";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type OptionSelectorEditorWrapperProps = {
  flowId: string;
  children: ReactElement;
  setConfigurableRewardTypes: React.Dispatch<
    React.SetStateAction<RewardType[]>
  >;
};

const OptionSelectorEditorWrapper: FC<OptionSelectorEditorWrapperProps> = ({
  flowId,
  setConfigurableRewardTypes,
  children,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const { setPreviewStates } = usePreviewStates();

  if (!axios || !selectedMarket || !selectedCampaign) {
    throw new Error("Context isn't set correctly");
  }

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const rewardTypesToPreviewStateItems = (rewardTypes: Item[]) => {
    return rewardTypes.map((rewardType: Item) => ({
      label: `Reward type: ${rewardType.name}`,
      value: { reward_type_id: rewardType.id },
    }));
  };

  const loadConfigurableRewardTypes = async (source: CancelTokenSource) => {
    try {
      const {
        data: { reward_types },
      } = await axios.get("/api/reward_types", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          flow: flowId,
        },
        cancelToken: source.token,
      });

      setConfigurableRewardTypes(reward_types);
      setPreviewStates(rewardTypesToPreviewStateItems(reward_types));
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurableRewardTypes(source);

    return () => {
      setPreviewStates([]);
      source.cancel();
    };
  }, []);

  if (error) return <ErrorAlert message={error} />;
  if (loading) return <Loader />;

  return children;
};

export default OptionSelectorEditorWrapper;
