import React, { FC, useEffect, useState } from "react";

import {
  EmailTemplate,
  FlowId,
  OptionsBasedReward,
  RewardOptionsListItem,
  RewardType,
  VoucherPool,
} from "interfaces";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Redirect } from "react-router";
import { Route } from "react-router-dom";

import AddRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/AddRewardOption/AddRewardOption";
import RewardOptionsList from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/RewardOptionsList";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

export type ManageRewardOptionsProps = {
  rewardTypeId: string;
  voucherPools: VoucherPool[];
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
  rewardOptions: RewardOptionsListItem[];
  flowId: FlowId;
};
const ManageRewardOptions: FC<ManageRewardOptionsProps> = ({
  flowId,
  rewardTypeId,
  voucherPools,
  emailTemplates,
  rewardOptions,
  manualRewardEmailTemplates,
}) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    throw new Error("Something went wrong - context not set correctly");
  }
  const { getValues } = useFormContext();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const pathToRewardTypesArray = `${flowId}.shared.settings.reward_types`;
  const rewardTypeIndex = getValues(pathToRewardTypesArray).findIndex(
    (rewardType: RewardType) => rewardType.id === rewardTypeId
  );

  if (rewardTypeIndex < 0) return <Route render={() => <Redirect to="/" />} />;

  const pathToRewardOptionsArray = `${pathToRewardTypesArray}.${rewardTypeIndex}.reward_options`;

  const { fields, append, move, remove, update } = useFieldArray({
    name: pathToRewardOptionsArray,
  });

  const updateRewardOptionsPositions = () => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fields.forEach((field: any, index: number) => {
      if (field.position != index + 1) {
        update(index, { ...field, position: index + 1 });
      }
    });
  };

  const sortRewardOptionsByPosition = () => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fields.forEach((field: any, index: number) => {
      if (field.position != index + 1) {
        move(index, field.position - 1);
      }
    });
  };

  const removeRewardOption = (index: number): void => {
    remove(index);
  };

  const canEditRewardOptions = !selectedCampaignConfiguration.published_at;

  const rewardItems: OptionsBasedReward[] = rewardOptions.map(
    (rewardOption) => {
      return {
        id: rewardOption.id,
        name: `${rewardOption.name} - ${rewardOption.country} - ${rewardOption.formatted_amount}`,
      };
    }
  );

  useEffect(() => {
    if (isFirstRender) {
      sortRewardOptionsByPosition();
      setIsFirstRender(false);
    } else {
      updateRewardOptionsPositions();
    }
  }, [fields]);

  return (
    <div className="flex flex-col p-7.5 space-y-7.5 bg-backgroundPrimary text-text rounded-xl">
      <p className="text-2xl font-semibold">Manage reward options</p>
      <div className="flex flex-row space-x-10">
        {canEditRewardOptions && (
          <AddRewardOption
            pathToRewardTypesArray={pathToRewardTypesArray}
            emailTemplates={emailTemplates}
            voucherPools={voucherPools}
            manualRewardEmailTemplates={manualRewardEmailTemplates}
            rewards={rewardItems}
            rewardOptions={fields}
            appendRewardOption={append}
          />
        )}
        <RewardOptionsList
          pathToRewardOptionsArray={pathToRewardOptionsArray}
          emailTemplates={emailTemplates}
          voucherPools={voucherPools}
          manualRewardEmailTemplates={manualRewardEmailTemplates}
          rewards={rewardItems}
          rewardOptions={fields}
          moveRewardOption={move}
          removeRewardOption={removeRewardOption}
        />
      </div>
    </div>
  );
};

export default ManageRewardOptions;
