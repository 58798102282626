import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import useHeaderDrawer from "hooks/useHeaderDrawer";
import { SelectedCampaignConfiguration } from "interfaces";
import { Redirect, Route, Switch, matchPath } from "react-router-dom";

import Flows from "constants/FlowsConstant";

import { useAuth } from "components/AuthProvider";
import FlowsEditor from "components/CampaignConfigurationEditor/FlowsEditor/FlowsEditor";
import GlobalStyleEditor from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

interface Match {
  params: {
    marketSubdomain: string;
    campaignSlug: string;
    configurationId: string;
  };
}

const CampaignConfigurationEditor: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const { setShowDrawer, setHeaderDrawer } = useHeaderDrawer();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  const match: Match | null = matchPath(location.pathname, {
    path: "/:marketSubdomain/:campaignSlug/configuration/:configurationId",
  });

  const selectedConfigurationId: string | undefined =
    match?.params.configurationId;

  if (!selectedConfigurationId)
    return (
      <Route
        render={() => (
          <Redirect
            to={`/${selectedMarket.subdomain}/${selectedCampaign.slug}`}
          />
        )}
      />
    );

  const loadConfiguration = async (source: CancelTokenSource) => {
    try {
      const {
        data: { campaign_configuration },
      } = await axios.get(
        `/api/campaign_configurations/${selectedConfigurationId}`,
        {
          params: {
            region_key: selectedMarket.region_key,
            campaign_id: selectedCampaign.id,
          },
          cancelToken: source.token,
        }
      );

      setSelectedCampaignConfiguration(campaign_configuration);
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfiguration(source);

    return () => {
      source.cancel();
      setShowDrawer(false);
      setHeaderDrawer(null);
      setSelectedCampaignConfiguration(null);
    };
  }, []);

  const afterSave = (campaignConfiguration: SelectedCampaignConfiguration) =>
    setSelectedCampaignConfiguration(campaignConfiguration);

  const addGlobalStyleEditorToHeaderDrawer = () => {
    if (!selectedCampaignConfiguration) return;

    setHeaderDrawer({
      buttonText: "Global style configuration",
      childComponent: (
        <GlobalStyleEditor
          selectedMarket={selectedMarket}
          selectedCampaign={selectedCampaign}
          afterSave={afterSave}
        />
      ),
    });
  };

  useEffect(() => {
    addGlobalStyleEditorToHeaderDrawer();
  }, [selectedCampaignConfiguration]);

  if (error) return <ErrorAlert message={error} />;
  if (loading) return <Loader />;

  return (
    <Switch>
      <Route
        exact
        path="/:market_subdomain/:campaign_slug/configuration/:configuration_id"
      >
        <FlowsEditor />
      </Route>

      {Flows.map((flow) =>
        flow.groups.map((group) => (
          <Route
            key={flow.path + group.path}
            path={`/:market_subdomain/:campaign_slug/configuration/:configuration_id${flow.path}${group.path}`}
          >
            <group.component
              flowId={flow.id}
              flowName={flow.name}
              flowPath={flow.path}
              group={group}
            />
          </Route>
        ))
      )}

      <Route
        render={() => (
          <Redirect
            to={`/${selectedMarket.subdomain}/${selectedCampaign.slug}/configuration/${selectedCampaignConfiguration?.id}`}
          />
        )}
      />
    </Switch>
  );
};

export default CampaignConfigurationEditor;
