import React, { Dispatch, FC, SetStateAction } from "react";

import Button from "components/Button";
import ModalContainer from "components/ModalContainer/ModalContainer";

type ConfirmationModalProps = {
  setShowSavePrompt: Dispatch<SetStateAction<boolean>>;
  handleSave: () => void;
  includedUserCount: number | string;
  isDeactivatingSequence: boolean;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  setShowSavePrompt,
  handleSave,
  includedUserCount,
  isDeactivatingSequence,
}) => (
  <ModalContainer
    showModal={true}
    setShowModal={setShowSavePrompt}
    title="Confirmation"
  >
    {isDeactivatingSequence ? (
      <span className="flex text-text mb-5">
        Warning! deactivating a nurture sequence will stop all in flight nurture
        emails. Save with caution.
      </span>
    ) : (
      <span className="flex flex-col text-text mb-5">
        Saving this nurture sequence will trigger nurture for
        <strong>{includedUserCount} users</strong>
      </span>
    )}
    <div className="flex justify-end">
      <div className="w-20">
        <Button
          testId="modal-cancel"
          appearance="danger"
          handleOnClick={() => setShowSavePrompt(false)}
          text="Cancel"
          role="button"
        />
      </div>
      <div className="w-20 ml-4">
        <Button
          testId="modal-save"
          appearance="primary"
          handleOnClick={handleSave}
          text="Save"
          role="button"
        />
      </div>
    </div>
  </ModalContainer>
);

export default ConfirmationModal;
