import React, { FC } from "react";

import useHeaderDrawer from "hooks/useHeaderDrawer";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const HeaderDrawerNav: FC = () => {
  const { showDrawer, setShowDrawer, headerDrawer } = useHeaderDrawer();

  if (!headerDrawer) {
    return null;
  }

  return (
    <button
      onClick={() => setShowDrawer(!showDrawer)}
      className={`flex flex-row h-full items-center disabled:pointer-events-none disabled:opacity-50 focus:outline-none focus:ring-0 px-5 text-base font-medium hover:bg-brand-secondary hover:text-button-text ${
        showDrawer ? "bg-brand-secondary text-button-text" : ""
      }`}
      aria-label="Global style configuration"
    >
      <span className="pr-2.5">{headerDrawer.buttonText}</span>
      {showDrawer ? <IoIosArrowUp /> : <IoIosArrowDown />}
    </button>
  );
};

export default HeaderDrawerNav;
