import React, { FC } from "react";

import { Market, RunaRewardOption } from "interfaces";

import RewardOptionListItem from "./RewardOptionListItem/RewardOptionListItem";

type RewardOptionListProps = {
  market: Market;
  rewardOptions: RunaRewardOption[] | undefined;
  onDelete: () => void;
};

const RewardOptionList: FC<RewardOptionListProps> = ({
  market,
  rewardOptions,
  onDelete,
}) => (
  <ul>
    {rewardOptions?.map((rewardOption) => (
      <RewardOptionListItem
        market={market}
        key={rewardOption.id}
        rewardOption={rewardOption}
        onDelete={onDelete}
      />
    ))}
  </ul>
);

export default RewardOptionList;
