import React, { FC } from "react";

import Axios from "axios";
import { itemsToDropdowItems } from "helpers/DropdownHelper";
import { Item, Market, OptionTypeBase, Product } from "interfaces";
import { Controller, FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import Dropdown from "components/Dropdown/Dropdown";
import ErrorToast from "components/ErrorToast";

import RunaDenominationSelector from "../RunaDenominationSelector/RunaDenominationSelector";

type RewardOptionCreatorProps = {
  products: Product[];
  market: Market;
  id: string;
  rewardType: string;
  onSave: () => void;
};

type RunaRewardOptionFormValues = {
  selectedProductCode: string | null;
  denomination: number | null;
};

const RewardOptionCreator: FC<RewardOptionCreatorProps> = ({
  products,
  market,
  id,
  rewardType,
  onSave,
}) => {
  const { axios } = useAuth();

  const methods = useForm<RunaRewardOptionFormValues>({
    mode: "onChange",
    defaultValues: {
      selectedProductCode: null,
      denomination: null,
    },
  });

  if (!axios) {
    throw new Error("Context isn't set correctly");
  }

  const rewardOptions: Item[] = products.map((product) => {
    return { name: product.name, id: product.code };
  });

  const createRewardOption = async (formData: RunaRewardOptionFormValues) => {
    await axios.post(`/api/markets/${market.id}/reward_options`, {
      region_key: market.region_key,
      code: formData.selectedProductCode,
      denomination: formData.denomination,
      cancelToken: Axios.CancelToken.source().token,
    });
  };

  const handleSave = (formData: RunaRewardOptionFormValues) => {
    toast.promise(createRewardOption(formData), {
      loading: "Saving...",
      success: () => {
        onSave();
        return "Saved";
      },
      error: (e) => <ErrorToast error={e} />,
    });
  };

  const rewardOptionDropdownItems = itemsToDropdowItems(rewardOptions);
  const selectedProductCode: string | null = methods.watch(
    "selectedProductCode"
  );
  const selectedProduct = selectedProductCode
    ? products.find((prod) => prod.code === selectedProductCode)
    : null;

  return (
    <div className="p-5 space-y-7.5 border border-backgroundSecondary rounded-xl w-87.5">
      <h2 className="px-0 pt-2.5 text-text text-xl font-bold">{rewardType}</h2>
      <FormProvider {...methods}>
        <Controller
          name="selectedProductCode"
          rules={{ required: { value: true, message: "Required" } }}
          render={({ field: { onChange, value } }) => (
            <Dropdown
              id={id}
              value={value}
              items={rewardOptionDropdownItems}
              onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
              label="Select a product"
              validationState={
                methods.formState.errors?.selectedProductCode
                  ? "invalid"
                  : "valid"
              }
              errorMessage={
                methods.formState.errors?.selectedProductCode?.message
              }
              isSearchable={true}
            />
          )}
        />

        {selectedProduct ? (
          <RunaDenominationSelector selectedProduct={selectedProduct} />
        ) : null}
      </FormProvider>

      <div className="w-32 ml-2.5">
        <Button
          appearance="secondary"
          text="Save"
          description="Save reward option"
          type="submit"
          disabled={!methods.formState.isValid}
          handleOnClick={methods.handleSubmit(handleSave)}
        />
      </div>
    </div>
  );
};

export default RewardOptionCreator;
