import React, { FC, useContext } from "react";

import {
  FormHelpersContext,
  FormHelpersContextType,
} from "context/FormHelpersContext";

import EligibilityCheckSettingsEditor from "../../EligibilityCheckSettingsEditor/EligibilityCheckSettingsEditor";

type SchemaEligibilityCheckEditorProps = {
  pathRoot: string;
  flowId: string;
};

const SchemaEligibilityCheckEditor: FC<SchemaEligibilityCheckEditorProps> = ({
  pathRoot,
  flowId,
}) => {
  const formHelpers = useContext<FormHelpersContextType>(FormHelpersContext);

  const configurableEligibilityChecks =
    formHelpers.campaign_configurations[flowId]?.eligibility_check || [];

  return (
    <EligibilityCheckSettingsEditor
      eligibilityChecksPath={pathRoot}
      configurableEligibilityChecks={configurableEligibilityChecks}
    />
  );
};

export default SchemaEligibilityCheckEditor;
