import React, { FC } from "react";

export type ResourceStatusProps = {
  status: string;
};

const ResourceStatus: FC<ResourceStatusProps> = ({ status }) => {
  const statusColor = (status: string) => {
    switch (status) {
      case "Issued":
        return "green";
      case "Pending Validation":
        return "blue";
      default:
        return "orange";
    }
  };

  return <div className={`status-${statusColor(status)}`}>{status}</div>;
};

export default ResourceStatus;
