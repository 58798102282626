import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import { InputField } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

const ColorsEditor: FC = () => (
  <Group>
    <GroupHeader text="Colours" />
    <GroupBody>
      <div className="flex flex-col gap-y-3">
        <InputField id="style.colors.highlight" label="Highlight" />
        <InputField id="style.layout.background_color" label="Background" />
        <InputField id="style.layout.color" label="Base text" />
        <InputField id="style.colors.error" label="Error state" />
      </div>
    </GroupBody>
  </Group>
);

export default ColorsEditor;
