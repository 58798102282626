import React, { FC, MouseEvent } from "react";

import useHeaderDrawer from "hooks/useHeaderDrawer";
import { useFormState } from "react-hook-form";

import Button from "components/Button";

const FormActions: FC = () => {
  const { setShowDrawer } = useHeaderDrawer();

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowDrawer(false);
  };
  const { dirtyFields } = useFormState();

  return (
    <div className="flex flex-row w-full justify-end my-7.5">
      {/* using dirtyFields as it needs to be subscribed for keepDirtyValues to behave as expected */}
      <input type="hidden" value={dirtyFields} />
      <div className="w-32 ml-2.5">
        <Button
          appearance="secondary"
          handleOnClick={handleCancel}
          text="Cancel"
        />
      </div>
      <div className="w-32 ml-2.5">
        <Button appearance="primary" text="Save" type="submit" />
      </div>
    </div>
  );
};

export default FormActions;
