import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { EditorsGroup } from "interfaces";
import { Helmet } from "react-helmet";

import { useAuth } from "components/AuthProvider";
import {
  Container,
  PreviewEmbed,
  UnavailablePreviewWarning,
} from "components/CampaignConfigurationEditor/StepEditor/Preview";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type PreviewProps = {
  flowId: string;
  currentLocale: string;
  step: EditorsGroup;
};

const Preview: FC<PreviewProps> = ({ flowId, step, currentLocale }) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (
    !selectedMarket ||
    !axios ||
    !selectedCampaign ||
    !selectedCampaignConfiguration
  ) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const [error, setError] = useState<string>("");

  const [platformScriptUrl, setPlatformScriptUrl] = useState<string>("");
  const [platformScriptLoaded, setPlatformScriptLoaded] =
    useState<boolean>(false);
  const [isPreviewable, setIsPreviewable] = useState<boolean>(true);
  const [previewStatusReceived, setPreviewStatusReceived] =
    useState<boolean>(false);
  const getPlatformScriptUrl = async () => {
    const element = document.getElementsByName("platformurl")[0];
    if (element) {
      setPlatformScriptUrl(element.getAttribute("content") || "");
    } else {
      setError("Platform script URL not found");
    }
  };

  const checkPlatformScriptLoaded = (
    timer: NodeJS.Timeout | null
  ): NodeJS.Timeout | null => {
    if (!window.BuyapowaPlatform) {
      timer = setTimeout(() => checkPlatformScriptLoaded(timer), 150);
    } else {
      timer = null;
      setPlatformScriptLoaded(true);
    }

    return timer;
  };

  const getPreviewStatus = async (source: CancelTokenSource) => {
    try {
      const {
        data: { previewable },
      } = await axios.get("/api/campaign_configurations/preview_status", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          configuration_id: selectedCampaignConfiguration.id,
          flow: flowId,
        },
        cancelToken: source.token,
      });

      setIsPreviewable(previewable);
      setPreviewStatusReceived(true);
    } catch (e: unknown | Error) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setPreviewStatusReceived(true);
      }
    }
  };

  useEffect(() => {
    getPlatformScriptUrl();
    let timer: NodeJS.Timeout | null = null;
    timer = checkPlatformScriptLoaded(timer);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getPreviewStatus(source);

    return () => source.cancel();
  }, [selectedCampaignConfiguration]);

  if (error) return <ErrorAlert message={error} />;
  if (!platformScriptUrl || !previewStatusReceived)
    return (
      <div className="w-1/3 bg-surfaceSecondary h-fit min-h-full absolute inset-y-0 right-0 bottom-0">
        <Loader />
      </div>
    );

  return (
    <Container>
      <div data-testid="helmetTestId">
        <Helmet>
          <script src={platformScriptUrl} type="text/javascript" />
        </Helmet>
      </div>

      {!isPreviewable && <UnavailablePreviewWarning />}
      {isPreviewable && !platformScriptLoaded && <Loader />}
      {isPreviewable && platformScriptLoaded && (
        <PreviewEmbed
          flowId={flowId}
          step={step}
          currentLocale={currentLocale}
        />
      )}
    </Container>
  );
};

export default Preview;
