import React, { FC } from "react";

interface Icons {
  [index: string]: string;
}

export const ICONS: Icons = {
  SETTINGS:
    "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4",
  CONTENT:
    "M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z",
  REWARD:
    "M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7",
  DELETE:
    "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16",
  ARROW_LEFT: "M15 19l-7-7 7-7",
  ARROW_RIGHT: "M9 5l7 7-7 7",
  CROSS:
    "M12.8925 0.3025C12.5025 -0.0874998 11.8725 -0.0874998 11.4825 0.3025L6.5925 5.1825L1.7025 0.2925C1.3125 -0.0975 0.6825 -0.0975 0.2925 0.2925C-0.0975 0.6825 -0.0975 1.3125 0.2925 1.7025L5.1825 6.5925L0.2925 11.4825C-0.0975 11.8725 -0.0975 12.5025 0.2925 12.8925C0.6825 13.2825 1.3125 13.2825 1.7025 12.8925L6.5925 8.0025L11.4825 12.8925C11.8725 13.2825 12.5025 13.2825 12.8925 12.8925C13.2825 12.5025 13.2825 11.8725 12.8925 11.4825L8.0025 6.5925L12.8925 1.7025C13.2725 1.3225 13.2725 0.6825 12.8925 0.3025Z",
  DRAG_HANDLE: "M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4",
  BIN: "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16",
  ERROR:
    "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
};

export type IconProps = {
  icon: string;
  className?: string;
  sizes?: {
    w: string;
    h: string;
  };
  fill?: string;
  stroke?: {
    color: string;
    width: string;
    linecap?: "round" | "inherit" | "butt" | "square";
    linejoin?: "round" | "inherit" | "miter" | "bevel";
  };
  handleOnClick?: () => void;
  testId?: string;
  ariaLabel?: string;
};

const Icon: FC<IconProps> = ({
  icon,
  className = "inline-block",
  sizes,
  fill,
  stroke,
  handleOnClick,
  testId,
  ariaLabel,
}) => (
  <svg
    data-testid={testId}
    width={sizes?.w || "40"}
    height={sizes?.h || "40"}
    viewBox={`0 0 ${sizes?.w || "24"} ${sizes?.h || "24"}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    stroke={stroke?.color}
    onClick={handleOnClick}
    aria-label={ariaLabel}
  >
    <path
      d={icon}
      fill={fill}
      strokeWidth={stroke?.width}
      strokeLinecap={stroke?.linecap}
      strokeLinejoin={stroke?.linejoin}
    ></path>
  </svg>
);

export default Icon;
