export const sharedSettingsFields = (rootPath: string) => {
  return {
    voucherDistributionEnabled: {
      id: `${rootPath}.voucher_distribution.enabled`,
      label: "Distribute an incentive to the friend",
    },
    voucherDistributionEmailEnabled: {
      id: `${rootPath}.voucher_distribution.email_enabled`,
      label: "Send a friend incentive email",
    },
    voucherDistributionKey: {
      id: `${rootPath}.voucher_distribution.key`,
      label: "Offer identifier",
    },
    primaryVoucherPool: {
      id: `${rootPath}.voucher_distribution.primary_voucher_pool_id`,
      label: "Select a voucher pool",
    },
    secondaryVoucherPool: {
      id: `${rootPath}.voucher_distribution.secondary_voucher_pool_id`,
      label: "Select a secondary voucher pool",
    },
    emailTemplate: {
      id: `${rootPath}.voucher_distribution.email_template_id`,
      label: "Select an email template",
    },
  };
};
