import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import SearchBar from "components/SearchBar";

import { MarketsContext, MarketsContextType } from "context/MarketsContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const MarketsFilter: FC = () => {
  const [, setSelectedMarket] = useSelectedMarketContext();
  const { state: markets, setMarkets } =
    useContext<MarketsContextType>(MarketsContext);
  const [allMarkets] = useState(markets);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedMarket(null);

    const needle = e.target.value.toLowerCase();
    const result = allMarkets.filter(
      (market) =>
        market.name.toLowerCase().search(needle) !== -1 ||
        market.subdomain.toLowerCase().search(needle) !== -1
    );

    setMarkets(result);
  };

  useEffect(() => {
    return () => setMarkets(allMarkets);
  }, []);

  return (
    <div className="md:w-1/2 mt-2.5 mb-7 px-3.5 md:pl-0">
      <SearchBar handleChange={handleChange} placeholder="Filter markets" />
    </div>
  );
};

export default MarketsFilter;
