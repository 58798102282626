import React, { FC } from "react";

type CancelIconProps = {
  size?: number;
};

const CancelIcon: FC<CancelIconProps> = ({ size = 18 }) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none">
    <path
      d="M10.1714 10.1714L15.8282 15.8282M10.1714 10.1714L15.8282 15.8282"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1714 15.8286L15.8282 10.1718M10.1714 15.8286L15.8282 10.1718"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="13"
      cy="13"
      r="10"
      transform="rotate(-45 12.9999 13)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default CancelIcon;
