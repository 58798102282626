import React, { FC, createContext, useState } from "react";

import { OptionTypeBase } from "interfaces";

export type PreviewStates = {
  previewStates: OptionTypeBase[];
  setPreviewStates: React.Dispatch<React.SetStateAction<OptionTypeBase[]>>;
};

export const PreviewStatesContext = createContext<PreviewStates>({
  previewStates: [],
  setPreviewStates: () => {
    // do nothing
  },
});

export const PreviewStatesProvider: FC = ({ children }) => {
  const [previewStates, setPreviewStates] = useState<OptionTypeBase[]>([]);

  return (
    <PreviewStatesContext.Provider
      value={{
        previewStates,
        setPreviewStates,
      }}
    >
      {children}
    </PreviewStatesContext.Provider>
  );
};
