import React, { FC } from "react";

import Icon, { ICONS } from "components/Icon";

type ErrorAlertProps = {
  message: string;
};

const ErrorAlert: FC<ErrorAlertProps> = ({ message }) => (
  <div className="w-full mt-4 mb-4 alert flex flex-row items-center bg-red-200 p-5 rounded border-b-2 border-red-300">
    <div className="alert-icon flex items-center bg-red-100 border-2 border-red-500 justify-center h-10 w-10 shrink-0 rounded-full">
      <span className="text-red-500">
        <Icon
          icon={ICONS.ERROR}
          fill="currentColor"
          sizes={{ w: "20", h: "20" }}
        />
      </span>
    </div>
    <div className="alert-content ml-4">
      <div className="alert-title font-semibold text-lg text-red-800">
        Error
      </div>
      <div className="alert-description text-sm text-red-600">
        Something went wrong: {message}, please reload the page.
      </div>
    </div>
  </div>
);

export default ErrorAlert;
