{
  "wrapper_site": {
    "site": {
      "settings": {
        "font_family": {
          "type": "string",
          "required": true
        },
        "head_tags": {
          "type": "string",
          "required": false,
          "key_optional": true,
          "long": true
        },
        "favicon_img_url": {
          "type": "string",
          "required": false,
          "key_optional": true
        },
        "header": {
          "show": {
            "type": "boolean",
            "required": true
          },
          "font_color": {
            "type": "string",
            "required": true
          },
          "background_color": {
            "type": "string",
            "required": true
          },
          "width": {
            "type": "string",
            "required": true
          },
          "language_switcher": {
            "show": {
              "type": "boolean",
              "required": true
            }
          },
          "logo": {
            "img_url": {
              "type": "string",
              "required": false,
              "key_optional": true
            },
            "width": {
              "type": "string",
              "required": true
            },
            "text_align": {
              "type": "dropdown",
              "dropdownType": "TextAlignTypes",
              "required": true
            }
          }
        },
        "footer": {
          "show": {
            "type": "boolean",
            "required": true
          },
          "font_color": {
            "type": "string",
            "required": true
          },
          "background_color": {
            "type": "string",
            "required": true
          },
          "text_align": {
            "type": "dropdown",
            "dropdownType": "TextAlignTypes",
            "required": true
          },
          "terms_link": {
            "show": {
              "type": "boolean",
              "required": true
            }
          },
          "privacy_link": {
            "show": {
              "type": "boolean",
              "required": true
            }
          }
        }
      },
      "content": {
        "locale": {
          "site": {
            "title": {
              "type": "string",
              "required": true
            },
            "embed_error": {
              "type": "string",
              "required": true
            }
          },
          "header": {
            "logo": {
              "url": {
                "type": "string",
                "required": false,
                "key_optional": true
              },
              "alt_text": {
                "type": "string",
                "required": false,
                "key_optional": true
              }
            },
            "language_switcher": {
              "language_name": {
                "type": "string",
                "required": true
              }
            }
          },
          "footer": {
            "terms_link": {
              "url": {
                "type": "string",
                "required": false,
                "key_optional": true
              },
              "text": {
                "type": "string",
                "required": false,
                "key_optional": true
              }
            },
            "privacy_link": {
              "url": {
                "type": "string",
                "required": false,
                "key_optional": true
              },
              "text": {
                "type": "string",
                "required": false,
                "key_optional": true
              }
            },
            "contact_link": {
              "url": {
                "type": "string",
                "required": false,
                "key_optional": true
              },
              "text": {
                "type": "string",
                "required": false,
                "key_optional": true
              }
            },
            "message": {
              "type": "string",
              "required": false,
              "key_optional": true,
              "long": true
            },
            "powered_by_message": {
              "type": "string",
              "required": false,
              "key_optional": true
            }
          }
        }
      }
    }
  }
}
