import React, { FC } from "react";

import {
  EmailTemplate,
  HyperwalletRewardSettings,
  OptionBaseRewardSettings,
  OptionsBasedReward,
  PaypalRewardSettings,
  RewardOption,
  RewardOptionSettings,
  VoucherPool,
  VoucherRewardSettings,
} from "interfaces";
import { Draggable } from "react-beautiful-dnd";
import { Controller, useFormContext } from "react-hook-form";

import HyperwalletRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/HyperwalletRewardOption/HyperwalletRewardOption";
import OptionBasedRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/OptionBasedRewardOption/OptionBasedRewardOption";
import PaypalRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/PaypalRewardOption/PaypalRewardOption";
import VoucherRewardOption from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardOptionsList/VoucherRewardOption/VoucherRewardOption";
import { CancelIcon, SortableIcon } from "components/Icon";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

type DraggableRewardOptionProps = {
  rewardOptionsPath: string;
  rewardOption: RewardOption<RewardOptionSettings>;
  index: number;
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
  voucherPools: VoucherPool[];
  rewards: OptionsBasedReward[];
  showDragHandle: boolean;
  removeRewardOption: (index: number) => void;
};

const DraggableRewardOption: FC<DraggableRewardOptionProps> = ({
  rewardOptionsPath,
  rewardOption,
  index,
  emailTemplates,
  manualRewardEmailTemplates,
  voucherPools,
  rewards,
  showDragHandle,
  removeRewardOption,
}) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    throw new Error("Something went wrong - context not set correctly");
  }
  const canEditRewardOptions = !selectedCampaignConfiguration.published_at;

  const { control } = useFormContext();

  return (
    <Draggable
      draggableId={`item-${index}`}
      index={index}
      isDragDisabled={!canEditRewardOptions}
    >
      {(provided) => (
        <li
          className={"py-5 text-text list-none flex items-center"}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Controller
            control={control}
            name={`${rewardOptionsPath}.${index}`}
            render={() => (
              <>
                {canEditRewardOptions && showDragHandle && (
                  <div className="mr-4 cursor-move opacity-40 hover:opacity-100">
                    <SortableIcon />
                  </div>
                )}
                {rewardOption.reward_class === "voucher" && (
                  <VoucherRewardOption
                    rewardOption={
                      rewardOption as RewardOption<VoucherRewardSettings>
                    }
                    voucherPools={voucherPools}
                    emailTemplates={emailTemplates}
                    manualRewardEmailTemplates={manualRewardEmailTemplates}
                  />
                )}
                {rewardOption.reward_class === "paypal" && (
                  <PaypalRewardOption
                    rewardOption={
                      rewardOption as RewardOption<PaypalRewardSettings>
                    }
                    emailTemplates={emailTemplates}
                    manualRewardEmailTemplates={manualRewardEmailTemplates}
                  />
                )}
                {rewardOption.reward_class === "hyperwallet" && (
                  <HyperwalletRewardOption
                    rewardOption={
                      rewardOption as RewardOption<HyperwalletRewardSettings>
                    }
                    emailTemplates={emailTemplates}
                    manualRewardEmailTemplates={manualRewardEmailTemplates}
                  />
                )}
                {["runa", "ifeelgoods"].includes(rewardOption.reward_class) && (
                  <OptionBasedRewardOption
                    rewardOption={
                      rewardOption as RewardOption<OptionBaseRewardSettings>
                    }
                    rewards={rewards}
                    emailTemplates={emailTemplates}
                    manualRewardEmailTemplates={manualRewardEmailTemplates}
                  />
                )}
                {canEditRewardOptions && (
                  <div
                    data-testid={`remove-${rewardOption.key}`}
                    onClick={() => removeRewardOption(index)}
                    className="cursor-pointer opacity-40 hover:opacity-100"
                  >
                    <CancelIcon size={24} />
                  </div>
                )}
              </>
            )}
          />
        </li>
      )}
    </Draggable>
  );
};

export default DraggableRewardOption;
