import { useState } from "react";

import { Market } from "interfaces";

export type UseSelectedMarket = [
  Market | null,
  React.Dispatch<React.SetStateAction<Market | null>>,
];

export const useSelectedMarket = (
  newValue: Market | null
): UseSelectedMarket => {
  const [selectedMarket, setSelectedMarket] = useState<Market | null>(
    newValue || null
  );

  return [selectedMarket, setSelectedMarket];
};
