import React, { FC } from "react";

import { EmailTemplate } from "interfaces";
import { IconContext } from "react-icons";
import { FiTrash2 } from "react-icons/fi";

import { DropdownField, InputField } from "components/Form";
import { NurtureSequenceStep } from "components/Nurture/interfaces";

type NurtureSequenceRowProps = {
  nurtureSequenceStep: NurtureSequenceStep;
  nurtureSequenceStepIndex: number;
  emailTemplates: EmailTemplate[];
  handleEmailTemplateChange: (
    e: React.ChangeEvent<HTMLSelectElement> | Event
  ) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveSequenceStep: (index: number) => void;
};

const NurtureSequenceRow: FC<NurtureSequenceRowProps> = ({
  nurtureSequenceStep,
  nurtureSequenceStepIndex,
  emailTemplates,
  handleEmailTemplateChange,
  handleInputChange,
  handleRemoveSequenceStep,
}) => (
  <tr>
    <td className="p-4">{nurtureSequenceStepIndex + 1}</td>
    <td className="p-4">
      <DropdownField
        id={`sequence[${nurtureSequenceStepIndex}].email_template_id`}
        label={`sequence[${nurtureSequenceStepIndex}].email_template_id`}
        showLabel={false}
        items={emailTemplates}
        value={nurtureSequenceStep.email_template_id}
        displayEmptyOption={false}
        preSelected={false}
        handleInputChange={handleEmailTemplateChange}
      />
    </td>
    <td className="flex flex-row p-4">
      <InputField
        id={`sequence[${nurtureSequenceStepIndex}].delay_days`}
        testId={`sequence[${nurtureSequenceStepIndex}].delay_days`}
        className="w-1/2"
        placeholder="1"
        min="0"
        type="number"
        value={nurtureSequenceStep.delay_days.toString()}
        handleInputChange={handleInputChange}
      />
      <div className="self-center w-1/2 px-4">days</div>
    </td>
    <td className="p-4">
      <button
        onClick={() => {
          handleRemoveSequenceStep(nurtureSequenceStepIndex);
        }}
        className="flex items-center mr-2 focus:outline-none focus:ring-0"
        aria-label="Delete"
      >
        <IconContext.Provider
          value={{
            color: "currentColor",
            size: "1.25em",
          }}
        >
          <div>
            <FiTrash2 />
          </div>
        </IconContext.Provider>
      </button>
    </td>
  </tr>
);

export default NurtureSequenceRow;
