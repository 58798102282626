import React from "react";

import { EditorComponent } from "interfaces";

import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import schema from "../../schema.json";
import SuccessfulRegistrationEditorWrapper from "../SuccessfulRegistrationEditorWrapper/SuccessfulRegistrationEditorWrapper";

const SuccessfulRegistrationContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "successful_friend_registration";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <SuccessfulRegistrationEditorWrapper>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <SchemaRenderer
          schema={schema}
          flowId={flowId}
          journeyId={journeyId}
          section={"content"}
          locale={locale}
        />
      </ContentEditorWrapper>
    </SuccessfulRegistrationEditorWrapper>
  );
};

export default SuccessfulRegistrationContentEditor;
