{
  "click_to_claim_referrer": {
    "fulfilment_expired": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "options_selector": {
      "settings": {
        "intro_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "option_confirmation": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    },
    "reward_emailed": {
      "settings": {
        "footer_link": {
          "show": {
            "type": "boolean"
          }
        }
      }
    }
  }
}
