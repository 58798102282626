import React, { FC, useEffect, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Fieldset } from "components/Fieldset/Fieldset";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";

type RewardOptionContentEditorProps = {
  flowId: string;
  locale: string;
  rewardOptionKey: string;
};
const RewardOptionContentEditor: FC<RewardOptionContentEditorProps> = ({
  flowId,
  locale,
  rewardOptionKey,
}) => {
  const { getValues, control, setValue } = useFormContext();

  const fieldRootPath = `${flowId}.options_selector.content.${locale}.reward_options.${rewardOptionKey}`;
  const stepFields = {
    title: {
      id: `${fieldRootPath}.title`,
      label: "Title",
    },
    body: {
      id: `${fieldRootPath}.body`,
      label: "Description",
    },
    selectButtonText: {
      id: `${fieldRootPath}.select_button_text`,
      label: "Button",
    },
    image: {
      id: `${fieldRootPath}.image`,
      label: "Show reward option image?",
    },
    imageUrl: {
      id: `${fieldRootPath}.image.url`,
      label: "Image URL",
    },
    imageAltText: {
      id: `${fieldRootPath}.image.alt_text`,
      label: "Image alt text",
    },
    imageBackgroundColor: {
      id: `${fieldRootPath}.image.background_color`,
      label: "Image background color",
    },
  };

  const [showImage, setShowImage] = useState(
    getValues(stepFields.image.id) != null
  );

  useEffect(() => {
    if (!showImage) {
      setValue(stepFields.image.id, null);
    }
  }, [showImage]);

  return (
    <Fieldset legend={rewardOptionKey}>
      <Controller
        control={control}
        name={stepFields.title.id}
        defaultValue={""}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={stepFields.title.id}
            label={stepFields.title.label}
            aria-label={stepFields.title.label}
            value={getValues(stepFields.title.id)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Controller
        control={control}
        name={stepFields.body.id}
        defaultValue={""}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={stepFields.body.id}
            label={stepFields.body.label}
            aria-label={stepFields.body.label}
            value={getValues(stepFields.body.id)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Controller
        control={control}
        name={stepFields.selectButtonText.id}
        defaultValue={""}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={stepFields.selectButtonText.id}
            label={stepFields.selectButtonText.label}
            aria-label={stepFields.selectButtonText.label}
            value={getValues(stepFields.selectButtonText.id)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Switch
        id={`show_image_for_${rewardOptionKey}`}
        isSelected={showImage}
        onChange={() => setShowImage(!showImage)}
      >
        {stepFields.image.label}
      </Switch>
      {showImage && (
        <>
          <Controller
            control={control}
            name={stepFields.imageUrl.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.imageUrl.id}
                label={stepFields.imageUrl.label}
                aria-label={stepFields.imageUrl.label}
                value={getValues(stepFields.imageUrl.id) || ""}
                onChange={(e) => onChange(e)}
              />
            )}
          />
          <Controller
            control={control}
            name={stepFields.imageAltText.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.imageAltText.id}
                label={stepFields.imageAltText.label}
                aria-label={stepFields.imageAltText.label}
                value={getValues(stepFields.imageAltText.id) || ""}
                onChange={(e) => onChange(e)}
              />
            )}
          />
          <Controller
            control={control}
            name={stepFields.imageBackgroundColor.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.imageBackgroundColor.id}
                label={stepFields.imageBackgroundColor.label}
                aria-label={stepFields.imageBackgroundColor.label}
                value={getValues(stepFields.imageBackgroundColor.id) || ""}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </>
      )}
    </Fieldset>
  );
};

export default RewardOptionContentEditor;
