import React, { FC } from "react";

import { toFormattedDate } from "../../../../helpers/TimeConversionHelper/TimeConversionHelper";
import TooltipWrapper from "../../../TooltipWrapper";

type EditingProps = {
  date: string;
  email: string;
};

const EditingInfo: FC<EditingProps> = ({ date, email }) => {
  return (
    <div className="flex flex-col">
      <TooltipWrapper show={!!email} text={email} placement={"top"}>
        <div className="truncate text-text">{email}</div>
      </TooltipWrapper>
      {toFormattedDate(date)}
    </div>
  );
};
export default EditingInfo;
