import React, { FC, ReactElement } from "react";

import PropTypes from "prop-types";
import { Route, RouteProps } from "react-router-dom";

import GlobalContextWrapper from "components/GlobalContextWrapper";

import { SelectedMarketProvider } from "context/SelectedMarketContext";

import { useAuth } from "../AuthProvider";

type PrivateRouteProps = {
  component: FC;
} & RouteProps;

const PrivateRoute = ({
  component: ChildComponent,
  ...rest
}: PrivateRouteProps): ReactElement | null => {
  const { isAuthenticated, loginWithRedirect, loading } = useAuth();
  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return (
    <Route {...rest}>
      <GlobalContextWrapper>
        <SelectedMarketProvider>
          <ChildComponent />
        </SelectedMarketProvider>
      </GlobalContextWrapper>
    </Route>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
