import React, { FC } from "react";

type SubGroupHeaderProps = {
  text: string;
};

const SubGroupHeader: FC<SubGroupHeaderProps> = ({ text }) => (
  <div className="text-text font-medium">{text}</div>
);

export default SubGroupHeader;
