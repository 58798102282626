import React, { FC } from "react";

import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import Button from "components/Button";

import CallToActionContentEditor from "./CallToActionContentEditor/CallToActionContentEditor";

type CallToActionBuilderProps = {
  contentPath: string;
  bpToggleDisplayed: boolean;
};

const CallToActionBuilder: FC<CallToActionBuilderProps> = ({
  contentPath,
  bpToggleDisplayed,
}) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: contentPath,
  });

  const buttonsFieldArray = useWatch({
    name: contentPath,
  });

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...(buttonsFieldArray || [])[index],
    };
  });

  const addCallToAction = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    append({ text: "", url: "", button_class: "basic" });
  };

  const removeCallToAction = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    remove(index);
  };

  return (
    <>
      {controlledFields.map((field, index) => (
        <CallToActionContentEditor
          key={field.id}
          contentPath={contentPath}
          field={field}
          index={index}
          bpToggleDisplayed={bpToggleDisplayed}
          removeCallToAction={removeCallToAction}
        />
      ))}
      <Button
        appearance="secondary"
        text="Add a call to action"
        handleOnClick={addCallToAction}
      />
    </>
  );
};

export default CallToActionBuilder;
