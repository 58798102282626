import React, { FC } from "react";

import { EditorsGroup, Flow, FlowId } from "interfaces";
import { Link } from "react-router-dom";

import ActiveFlowMenu from "components/CampaignConfigurationEditor/FlowsEditor/ActiveFlowMenu/ActiveFlowMenu";
import FlowHeader from "components/CampaignConfigurationEditor/FlowsEditor/FlowHeader/FlowHeader";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

type ActiveFlowProps = {
  flow: Flow;
  deactivateFlow: (flowId: FlowId) => void;
};

const ActiveFlow: FC<ActiveFlowProps> = ({ flow, deactivateFlow }) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const isDeactivatedStep = (group: EditorsGroup) =>
    !!group.stepId &&
    !selectedCampaignConfiguration.flows[flow.id].steps[group.stepId].active;

  const canDeactivateFlow = !selectedCampaignConfiguration.published_at;

  return (
    <div className="rounded-xl shadow text-text bg-backgroundPrimary p-7 flex flex-col">
      <div className="flex flex-row items-center">
        <FlowHeader name={flow.name} />
        {canDeactivateFlow && (
          <ActiveFlowMenu
            id={flow.id}
            name={flow.name}
            deactivateFlow={deactivateFlow}
          />
        )}
      </div>

      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 pt-7">
        {flow.groups.map((group) => {
          if (isDeactivatedStep(group)) {
            return null;
          }

          return (
            <Link
              key={group.name}
              className="rounded-xl text-lg flex flex-col flex-1 justify-center items-center text-center p-4 shadow h-24 bg-surfacePrimary hover:bg-brand-secondary hover:text-button-text dark:hover:bg-brand-secondary dark:hover:text-button-text"
              to={`${location.pathname}${flow.path}${group.path}`}
            >
              <div>{group.name}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ActiveFlow;
