export const captureAdditionalFriendDetailsSettingsFields = () => {
  const rootPath = "friend_journey.capture_additional_friend_details.settings";

  return {
    email: {
      id: `${rootPath}.partial_registration.email_template_id`,
      label: "Select an email template",
    },
    reminderEmail: {
      id: `${rootPath}.partial_registration.reminder_email_template_id`,
      label: "Select an email template",
    },
    reminderIntervals: {
      id: `${rootPath}.partial_registration.reminder_intervals`,
    },
    firstReminderIntervals: {
      id: `${rootPath}.partial_registration.reminder_intervals[0]`,
      label:
        "How many days after the registration should the first reminder email be sent?",
    },
    secondReminderIntervals: {
      id: `${rootPath}.partial_registration.reminder_intervals[1]`,
      label:
        "How many days after the previous reminder email should this second reminder email be sent?",
    },
    thirdReminderIntervals: {
      id: `${rootPath}.partial_registration.reminder_intervals[2]`,
      label:
        "How many days after the previous reminder email should this third reminder email be sent?",
    },
  };
};
