import React, { FC, ReactNode, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";

type ReferrerDashboardEditorWrapperProps = {
  children: ReactNode;
};

const ReferrerDashboardEditorWrapper: FC<
  ReferrerDashboardEditorWrapperProps
> = ({ children }) => {
  const { setPreviewStates } = usePreviewStates();

  useEffect(() => {
    setPreviewStates([
      {
        label: "Default",
        value: {},
      },
      {
        label: "Referrer was seamlessly logged in",
        value: { can_logout: false },
      },
      {
        label: "With pending referrals only",
        value: { with_pending_referrals: true, with_complete_referrals: false },
      },
      {
        label: "With complete referrals only",
        value: { with_pending_referrals: false, with_complete_referrals: true },
      },
      {
        label: "With pending and complete referrals",
        value: { with_pending_referrals: true, with_complete_referrals: true },
      },
      {
        label: "With reward capping warning message",
        value: { show_reward_limit_warning_message: true },
      },
    ]);

    return () => setPreviewStates([]);
  }, []);

  return <>{children}</>;
};

export default ReferrerDashboardEditorWrapper;
