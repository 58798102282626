import React, { FC } from "react";

import { CreatableSelectField } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type FieldWithColorOptionsProps = {
  id: string;
  label: string;
  showDefaultOption: boolean;
};

const optionsWithoutDefault = ["error", "highlight"].map((value) => ({
  label: value,
  value: value,
}));

const optionsWithDefault = ["default", "error", "highlight"].map((value) => ({
  label: value,
  value: value,
}));

const FieldWithColorOptions: FC<FieldWithColorOptionsProps> = ({
  id,
  label,
  showDefaultOption,
}) => {
  const options = showDefaultOption
    ? optionsWithDefault
    : optionsWithoutDefault;

  return <CreatableSelectField fieldId={id} label={label} options={options} />;
};

export default FieldWithColorOptions;
