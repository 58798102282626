{
  "shared": {
    "settings": {
      "fulfilment_hook": {
        "enabled": {
          "type": "boolean",
          "required": true
        },
        "url": {
          "type": "string",
          "required": true
        }
      }
    }
  }
}
