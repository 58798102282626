import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

import { DesktopIcon, MobileIcon } from "components/Icon";

const DeviceSelector: FC = () => {
  const { selectedPreviewDevice, setSelectedPreviewDevice } =
    useSelectedPreviewDevice();

  return (
    <div className="flex flex-row items-center rounded-lg bg-backgroundPrimary h-11 mt-1 w-25">
      <div
        aria-label="Mobile preview"
        className={cn("flex flex-1 justify-center cursor-pointer", {
          "opacity-40": selectedPreviewDevice != "mobile",
        })}
        onClick={() => setSelectedPreviewDevice("mobile")}
      >
        <MobileIcon />
      </div>
      <div
        aria-label="Desktop preview"
        className={cn("flex flex-1 justify-center cursor-pointer", {
          "opacity-40": selectedPreviewDevice != "desktop",
        })}
        onClick={() => setSelectedPreviewDevice("desktop")}
      >
        <DesktopIcon />
      </div>
    </div>
  );
};

export default DeviceSelector;
