import React, { FC } from "react";

import { AxiosError } from "axios";
import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast/ErrorToast";
import { TextField } from "components/TextField/TextField";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type FormValues = {
  wrapperSiteDomain: string;
};

const DomainForm: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const { reloadDomain, setReloadDomain } = useWrapperSiteEditor();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormValues>({ mode: "onChange" });

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const saveDomain = async (domain: string) => {
    await axios.post("/api/wrapper_site", {
      region_key: selectedMarket.region_key,
      campaign_id: selectedCampaign.id,
      domain: domain,
    });

    setReloadDomain(!reloadDomain);
  };

  const onSubmit = (formData: FormValues) => {
    toast.promise(saveDomain(formData.wrapperSiteDomain), {
      loading: "Setting domain...",
      success: "Domain set",
      error: function MyToast(e: AxiosError) {
        return <ErrorToast error={e} />;
      },
    });
  };

  return (
    <div className="flex flex-col">
      <span>Wrapper site domain</span>
      <form role="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row items-start mt-4">
          <div className="flex flex-grow">
            <Controller
              control={control}
              name="wrapperSiteDomain"
              defaultValue=""
              rules={{
                required: { value: true, message: "Required" },
                pattern: {
                  value: /^((?!-)[a-z0-9-]{1,63}(?<!-)\.)+[a-z]{2,9}$/,
                  message: "Please enter a valid domain name",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id="wrapperSiteDomain"
                  aria-label="wrapperSiteDomain"
                  elementType="input"
                  value={value}
                  onChange={onChange}
                  validationState={error ? "invalid" : "valid"}
                  errorMessage={error?.message}
                />
              )}
            />
          </div>
          <div className="flex w-52 px-4">
            <Button
              appearance="primary"
              text="Set domain"
              type="submit"
              disabled={!isDirty || !isValid}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DomainForm;
