import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import useBreadcrumb from "hooks/useBreadcrumb";
import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";

import { useAuth } from "components/AuthProvider";
import BodyWrapper from "components/BodyWrapper";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import CertificateConfiguration from "components/WrapperSite/WrapperSiteEditor/CertificateConfiguration/CertificateConfiguration";
import ConfigurationSteps from "components/WrapperSite/WrapperSiteEditor/ConfigurationSteps/ConfigurationSteps";
import DomainConfiguration from "components/WrapperSite/WrapperSiteEditor/DomainConfiguration/DomainConfiguration";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const WrapperSiteEditor: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const { reloadDomain, domainConfig, setDomainConfig } =
    useWrapperSiteEditor();

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const { setBreadcrumbItems } = useBreadcrumb();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;

  const getAndCompleteWrapperSiteDomain = async (source: CancelTokenSource) => {
    try {
      setLoading(true);
      const { data } = await axios.get("/api/wrapper_site/get_and_complete", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setDomainConfig(data.wrapper_site);
    } catch (e) {
      if (Axios.isCancel(e)) {
        // do nothing
      } else {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Wrapper site",
        value: "Domain configuration",
      },
    ]);
  }, [domainConfig]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getAndCompleteWrapperSiteDomain(source);

    return () => source && source.cancel();
  }, [reloadDomain]);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <BodyWrapper>
      <div className="text-text">
        <ConfigurationSteps isExpandedByDefault={!domainConfig.domain} />
        <DomainConfiguration />
        {domainConfig.domain && <CertificateConfiguration />}
      </div>
    </BodyWrapper>
  );
};

export default WrapperSiteEditor;
