import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  CreatableSelectField,
  FieldWithFontWeightOptions,
  InputField,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

export type FontEditorProps = {
  customFonts: { value: string; label: string }[];
};

const FontEditor: FC<FontEditorProps> = ({ customFonts }) => (
  <Group>
    <GroupHeader text="Font" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <CreatableSelectField
            fieldId="style.font.family"
            label="Family"
            options={customFonts}
          />
          <FieldWithFontWeightOptions id="style.font.weight" label="Weight" />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text="Font sizes" />
        <SubGroupBody>
          <InputField id="style.font.sizes.extra_small" label="Extra small" />
          <InputField id="style.font.sizes.small" label="Small" />
          <InputField id="style.font.sizes.medium" label="Medium" />
          <InputField id="style.font.sizes.large" label="Large" />
          <InputField id="style.font.sizes.extra_large" label="Extra large" />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default FontEditor;
