import React, { FC, useEffect } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";

import BodyWrapper from "components/BodyWrapper";
import CampaignConfigurationsList from "components/CampaignEditor/CampaignConfigurationsList/CampaignConfigurationsList";
import ErrorAlert from "components/ErrorAlert";
import NurtureList from "components/Nurture/NurtureList";
import WrapperSiteList from "components/WrapperSite/WrapperSiteList";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const CampaignEditor: FC = () => {
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.name,
      },
      {
        label: "Campaign slug",
        value: selectedCampaign.slug,
      },
    ]);
  }, []);

  return (
    <BodyWrapper>
      <CampaignConfigurationsList />
      <NurtureList />
      <WrapperSiteList />
    </BodyWrapper>
  );
};

export default CampaignEditor;
