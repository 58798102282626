import React, { FC, useContext } from "react";

import { findDropdownItemByValue } from "helpers/DropdownHelper";
import { objectToDropdowItems } from "helpers/DropdownHelper";
import { amountFromCents, amountToCents } from "helpers/MonetaryHelper";
import { OptionTypeBase } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import Dropdown from "components/Dropdown/Dropdown";
import { TextField } from "components/TextField/TextField";

import {
  CurrenciesContext,
  CurrenciesContextType,
} from "context/CurrenciesContext";

type HyperwalletSubtypeFieldsProps = {
  emailTemplateDropdownItems: OptionTypeBase[];
  manualEmailTemplateDropdownItems: OptionTypeBase[];
};

const HyperwalletSubtypeFields: FC<HyperwalletSubtypeFieldsProps> = ({
  emailTemplateDropdownItems,
  manualEmailTemplateDropdownItems,
}) => {
  const currencies = useContext<CurrenciesContextType>(CurrenciesContext);

  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const currencyDropdownItems = objectToDropdowItems(currencies);
  const selectedCurrencyItem = (value: string) =>
    findDropdownItemByValue(currencyDropdownItems, value);

  const selectedEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(emailTemplateDropdownItems, value);

  const selectedManualEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(manualEmailTemplateDropdownItems, value);

  return (
    <>
      <Controller
        control={control}
        name={"settings.reward_cents"}
        rules={{
          required: { value: true, message: "Required" },
          min: { value: 1, message: "Please enter a valid value" },
        }}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            type="number"
            min="0"
            label="Reward value"
            aria-label="Reward value"
            id={"settings.reward_cents"}
            value={
              Number.isInteger(getValues("settings.reward_cents")) &&
              getValues("settings.reward_cents") > 0
                ? amountFromCents(getValues("settings.reward_cents")).toString()
                : ""
            }
            onChange={(e) =>
              onChange(
                Number.isInteger(parseFloat(e))
                  ? amountToCents(parseFloat(e))
                  : null
              )
            }
            validationState={
              errors?.settings?.reward_cents ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.reward_cents?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.reward_currency"}
        rules={{ required: { value: true, message: "Required" } }}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"currency_code"}
            value={selectedCurrencyItem(value)}
            items={currencyDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item.value)}
            label="Currency"
            showValueInLabel={true}
            validationState={
              errors?.settings?.currency_code ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.currency_code?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.email_template_id"}
        rules={{
          validate: (value) =>
            getValues("reward_class") === "hyperwallet" || value || "Required",
        }}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"email_template_id"}
            value={selectedEmailTemplateItem(value)}
            items={emailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
            label="Reward email template"
            isClearable={true}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.manual_email_template_id"}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"manual_email_template_id"}
            value={selectedManualEmailTemplateItem(value)}
            items={manualEmailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
            label="Manual Reward email template"
            isClearable={true}
          />
        )}
      />
    </>
  );
};

export default HyperwalletSubtypeFields;
