import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  CreatableSelectField,
  FieldWithColorOptions,
  FieldWithFontSizeOptions,
  FieldWithFontWeightOptions,
  FieldWithTextDecorationOptions,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type LinkEditorProps = {
  customFonts: { value: string; label: string }[];
};

const LinkEditor: FC<LinkEditorProps> = ({ customFonts }) => (
  <Group>
    <GroupHeader text="Link" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <CreatableSelectField
            fieldId="style.link.font_family"
            label="Font family"
            options={customFonts}
          />
          <FieldWithFontSizeOptions
            id="style.link.font_size"
            label="Font size"
          />
          <FieldWithFontWeightOptions
            id="style.link.font_weight"
            label="Font weight"
          />
          <FieldWithColorOptions
            id="style.link.color"
            label="Font color"
            showDefaultOption={true}
          />
          <FieldWithTextDecorationOptions
            id="style.link.text_decoration"
            label="Text decoration"
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Hover state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.link._hover.color"
            label="Font color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Active state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.link._active.color"
            label="Font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Visited state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.link._visited.color"
            label="Font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default LinkEditor;
