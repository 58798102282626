import React, { FC } from "react";

const SortableIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17 8.5L12.3536 3.85355C12.1583 3.65829 11.8417 3.65829 11.6464 3.85355L7 8.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7 15.5L11.6464 20.1464C11.8417 20.3417 12.1583 20.3417 12.3536 20.1464L17 15.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export default SortableIcon;
