import { Item, OptionTypeBase } from "interfaces";

export const findDropdownItemByValue = (
  items: OptionTypeBase[],
  value: string | null
): OptionTypeBase | null =>
  items.find((item: OptionTypeBase) => item.value === value) || null;

export const itemsToDropdowItems = (items: Item[]) =>
  items.map((item: Item) => ({
    label: item.name,
    value: item.id,
  }));

export const objectToDropdowItems = (object: {
  [key: string]: string | number;
}) =>
  Object.entries(object).map(([key, value]) => ({
    label: value,
    value: key,
  }));
