import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import {
  CustomField,
  EmailTemplate,
  OptionTypeBase,
  VoucherPool,
} from "interfaces";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { useAuth } from "components/AuthProvider";
import Dropdown from "components/Dropdown/Dropdown";
import ErrorAlert from "components/ErrorAlert";
import { Fieldset } from "components/Fieldset/Fieldset";
import Loader from "components/Loader";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";
import Transition from "components/Transition";
import { sharedSettingsFields } from "components/VoucherDistribution/utils";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type VoucherDistributionProps = {
  flowId: string;
  fieldPath: string;
  displayEmailSettings?: boolean;
};

const VoucherDistribution: FC<VoucherDistributionProps> = ({
  flowId,
  fieldPath,
  displayEmailSettings,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!axios || !selectedMarket || !selectedCampaign)
    return <ErrorAlert message="Context is invalid" />;

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [configurableVoucherPools, setConfigurableVoucherPools] = useState<
    VoucherPool[]
  >([]);
  const [configurableEmailTemplates, setConfigurableEmailTemplates] = useState<
    EmailTemplate[]
  >([]);

  const { getValues, control } = useFormContext();

  const settingsFields = sharedSettingsFields(fieldPath);

  useWatch({
    name: [
      settingsFields.voucherDistributionEnabled.id,
      settingsFields.voucherDistributionEmailEnabled.id,
      settingsFields.emailTemplate.id,
      settingsFields.primaryVoucherPool.id,
      settingsFields.secondaryVoucherPool.id,
    ],
  });

  const configurableVoucherPoolsRequest = (source: CancelTokenSource) =>
    axios.get("/api/voucher_pools", {
      params: {
        region_key: selectedMarket?.region_key,
        campaign_id: selectedCampaign?.id,
        flow: flowId,
      },
      cancelToken: source.token,
    });

  const configurableEmailTemplatesRequest = (source: CancelTokenSource) => {
    return axios.get("/api/email_templates", {
      params: {
        region_key: selectedMarket?.region_key,
        campaign_id: selectedCampaign?.id,
        flow: flowId,
        type: "friend_incentive",
      },
      cancelToken: source.token,
    });
  };

  const loadConfigurables = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { voucher_pools },
        },
        {
          data: { email_templates },
        },
      ] = await Promise.all([
        configurableVoucherPoolsRequest(source),
        displayEmailSettings
          ? configurableEmailTemplatesRequest(source)
          : Promise.resolve({ data: {} }),
      ]);

      setConfigurableVoucherPools(voucher_pools);
      displayEmailSettings && setConfigurableEmailTemplates(email_templates);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadConfigurables(source);

    return () => source.cancel();
  }, []);

  const configurableToDropdowItems = (
    configurables: VoucherPool[] | EmailTemplate[] | CustomField[]
  ) =>
    configurables.map(
      (configurable: VoucherPool | EmailTemplate | CustomField) => ({
        label: configurable.name,
        value: configurable.id,
      })
    );

  const voucherPoolDropdownItems = configurableToDropdowItems(
    configurableVoucherPools
  );

  const selectedPrimaryVoucherPoolDropdownItem =
    voucherPoolDropdownItems.find(
      (voucherPoolDropdownItem) =>
        voucherPoolDropdownItem.value ===
        getValues(settingsFields.primaryVoucherPool.id)
    ) || null;

  const selectedSecondaryVoucherPoolDropdownItem =
    voucherPoolDropdownItems.find(
      (voucherPoolDropdownItem) =>
        voucherPoolDropdownItem.value ===
        getValues(settingsFields.secondaryVoucherPool.id)
    ) || null;

  const emailTemplateDropdownItems = configurableToDropdowItems(
    configurableEmailTemplates
  );

  const selectedEmailTemplateDropdownItem =
    emailTemplateDropdownItems.find(
      (configurableEmailTemplate) =>
        configurableEmailTemplate.value ===
        getValues(settingsFields.emailTemplate.id)
    ) || null;

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Controller
        control={control}
        name={settingsFields.voucherDistributionEnabled.id}
        key={settingsFields.voucherDistributionEnabled.id}
        render={({ field: { onChange } }) => (
          <Switch
            id={settingsFields.voucherDistributionEnabled.id}
            isSelected={
              getValues(settingsFields.voucherDistributionEnabled.id) || false
            }
            onChange={onChange}
          >
            {settingsFields.voucherDistributionEnabled.label}
          </Switch>
        )}
      />

      <Transition
        showChildren={getValues(settingsFields.voucherDistributionEnabled.id)}
      >
        <>
          <Fieldset legend={"Incentive settings"}>
            <Controller
              control={control}
              name={settingsFields.voucherDistributionKey.id}
              key={settingsFields.voucherDistributionKey.id}
              render={({ field: { onChange } }) => (
                <TextField
                  elementType="input"
                  id={settingsFields.voucherDistributionKey.id}
                  label={settingsFields.voucherDistributionKey.label}
                  aria-label={settingsFields.voucherDistributionKey.label}
                  value={getValues(settingsFields.voucherDistributionKey.id)}
                  onChange={(e) => onChange(e)}
                />
              )}
            />

            <Controller
              control={control}
              name={settingsFields.primaryVoucherPool.id}
              key={settingsFields.primaryVoucherPool.id}
              render={({ field: { onChange } }) => (
                <Dropdown
                  id={settingsFields.primaryVoucherPool.id}
                  value={selectedPrimaryVoucherPoolDropdownItem}
                  items={voucherPoolDropdownItems}
                  isClearable={true}
                  onChange={(e) => onChange((e as OptionTypeBase).value)}
                  label={settingsFields.primaryVoucherPool.label}
                />
              )}
            />

            <Controller
              control={control}
              name={settingsFields.secondaryVoucherPool.id}
              key={settingsFields.secondaryVoucherPool.id}
              render={({ field: { onChange } }) => (
                <Dropdown
                  id={settingsFields.secondaryVoucherPool.id}
                  value={selectedSecondaryVoucherPoolDropdownItem}
                  items={voucherPoolDropdownItems}
                  isClearable={true}
                  onChange={(e) => onChange((e as OptionTypeBase).value)}
                  label={settingsFields.secondaryVoucherPool.label}
                />
              )}
            />
          </Fieldset>

          {displayEmailSettings && (
            <Fieldset legend={"Email settings"}>
              <Controller
                control={control}
                name={settingsFields.voucherDistributionEmailEnabled.id}
                key={settingsFields.voucherDistributionEmailEnabled.id}
                render={({ field: { onChange } }) => (
                  <Switch
                    id={settingsFields.voucherDistributionEmailEnabled.id}
                    isSelected={
                      getValues(
                        settingsFields.voucherDistributionEmailEnabled.id
                      ) || false
                    }
                    onChange={onChange}
                  >
                    {settingsFields.voucherDistributionEmailEnabled.label}
                  </Switch>
                )}
              />
              <Transition
                showChildren={getValues(
                  settingsFields.voucherDistributionEmailEnabled.id
                )}
              >
                <Controller
                  control={control}
                  name={settingsFields.emailTemplate.id}
                  key={settingsFields.emailTemplate.id}
                  render={({ field: { onChange } }) => (
                    <Dropdown
                      id={settingsFields.emailTemplate.id}
                      value={selectedEmailTemplateDropdownItem}
                      items={emailTemplateDropdownItems}
                      onChange={(e) => onChange((e as OptionTypeBase).value)}
                      label={settingsFields.emailTemplate.label}
                    />
                  )}
                />
              </Transition>
            </Fieldset>
          )}
        </>
      </Transition>
    </>
  );
};

export default VoucherDistribution;
