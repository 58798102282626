import React, { FC, useState } from "react";

import { AxiosError } from "axios";
import copy from "copy-to-clipboard";
import useWrapperSiteEditor from "hooks/useWrapperSiteEditor";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast";
import { AwsResourceInfo } from "components/WrapperSite/interfaces";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import ClearDomainConfirmationModal from "./ClearDomainConfirmationModal/ClearDomainConfirmationModal";
import DomainInfoTable from "./DomainInfoTable/DomainInfoTable";
import MigrateConfirmationModal from "./MigrateConfirmationModal/MigrateConfirmationModal";

const DomainInfo: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const { reloadDomain, setReloadDomain, domainConfig } =
    useWrapperSiteEditor();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showMigrateConfirmationModal, setShowMigrateConfirmationModal] =
    useState(false);

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  if (!domainConfig.domain || !domainConfig.info) {
    return <ErrorAlert message="Unable to get domain configuration" />;
  }

  const clearDomain = async () => {
    await axios.delete("/api/wrapper_site", {
      data: {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
      },
    });

    setReloadDomain(!reloadDomain);
  };

  const handleClearDomain = () => {
    toast.promise(clearDomain(), {
      loading: "Clearing domain...",
      success: "Domain cleared",
      error: function MyToast(e: AxiosError) {
        return <ErrorToast error={e} />;
      },
    });
  };

  const formatResourceInfo = (resourceInfo: AwsResourceInfo) => {
    return `${resourceInfo.name} CNAME ${resourceInfo.value}`;
  };

  const copyRecords = () => {
    return new Promise((resolve, reject) => {
      if (!domainConfig.info) {
        return reject("Missing domain configuration");
      }

      copy(
        `${formatResourceInfo(domainConfig.info.acm)}\n${formatResourceInfo(domainConfig.info.cf)}`
      );
      resolve(true);
    });
  };

  const handleCopyRecords = () => {
    toast.promise(copyRecords(), {
      loading: "",
      success: "Copied to clipboard",
      error: function MyToast(e) {
        return <ErrorToast error={e.message} />;
      },
    });
  };

  const migrate = async () => {
    await axios.post("/api/wrapper_site/migrate", {
      region_key: selectedMarket.region_key,
      campaign_id: selectedCampaign.id,
    });

    setReloadDomain(!reloadDomain);
  };

  const handleMigrate = () => {
    toast.promise(migrate(), {
      loading: "Migrating...",
      success: "Migrated",
      error: function MyToast(e) {
        return <ErrorToast error={e.message} />;
      },
    });
  };

  return (
    <>
      {showConfirmationModal && (
        <ClearDomainConfirmationModal
          setShow={setShowConfirmationModal}
          handleConfirm={handleClearDomain}
        />
      )}
      {showMigrateConfirmationModal && (
        <MigrateConfirmationModal
          setShow={setShowMigrateConfirmationModal}
          handleConfirm={handleMigrate}
        />
      )}
      <div className="flex flex-row items-center mb-10">
        <div className="flex flex-col flex-grow">
          <span>Wrapper site domain</span>
          <span className="text-text text-opacity-80">
            {domainConfig.domain}
          </span>
        </div>
        <div className="flex space-x-2.5">
          <div className="w-40">
            <Button
              appearance="danger"
              text="Clear domain"
              role="button"
              handleOnClick={() => setShowConfirmationModal(true)}
            />
          </div>
          <div className="w-40">
            <Button
              appearance="primary"
              text="Copy records"
              role="button"
              handleOnClick={handleCopyRecords}
            />
          </div>
          {domainConfig.requires_migration && (
            <div className="w-40">
              <Button
                appearance="primary"
                text="Migrate"
                role="button"
                handleOnClick={() => setShowMigrateConfirmationModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      <DomainInfoTable />
    </>
  );
};

export default DomainInfo;
