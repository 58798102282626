import React from "react";

import { EditorComponent } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { TextField } from "components/TextField/TextField";

import ContentEditorWrapper from "../../ContentEditorWrapper/ContentEditorWrapper";

const RewardClaimedContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const { getValues, control } = useFormContext();

  const stepId =
    flowId === "click_to_claim_referrer" ? "reward_emailed" : "reward_claimed";

  const contentPath = `${flowId}.${stepId}.content`;
  const fieldRootPath = `${contentPath}.${locale}`;

  const titleFieldId = `${fieldRootPath}.title`;
  const titleFieldLabel = "Title";

  const bodyFieldId = `${fieldRootPath}.body`;
  const bodyFieldLabel = "Body";

  const linkTextFieldId = `${fieldRootPath}.footer_link.text`;
  const linkTextFieldLabel = "Text";

  const linkUrlFieldId = `${fieldRootPath}.footer_link.url`;
  const linkUrlFieldLabel = "URL";

  return (
    <ContentEditorWrapper
      nextLocale={locale}
      contentPath={contentPath}
      goToPreviousLocale={goToPreviousLocale}
    >
      <Accordion label={titleFieldLabel} isExpandedByDefault={false}>
        <Controller
          control={control}
          name={titleFieldId}
          key={titleFieldId}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="textarea"
              id={titleFieldId}
              aria-label={titleFieldLabel}
              value={getValues(titleFieldId)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      </Accordion>
      <Accordion label={bodyFieldLabel} isExpandedByDefault={false}>
        <Controller
          control={control}
          name={bodyFieldId}
          key={bodyFieldId}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="textarea"
              id={bodyFieldId}
              aria-label={bodyFieldLabel}
              value={getValues(bodyFieldId)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      </Accordion>
      <Accordion label="Footer link" isExpandedByDefault={false}>
        <Controller
          control={control}
          name={linkTextFieldId}
          key={linkTextFieldId}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              id={linkTextFieldId}
              label={linkTextFieldLabel}
              aria-label={linkTextFieldLabel}
              value={getValues(linkTextFieldId)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
        <Controller
          control={control}
          name={linkUrlFieldId}
          key={linkUrlFieldId}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              id={linkUrlFieldId}
              label={linkUrlFieldLabel}
              aria-label={linkUrlFieldLabel}
              value={getValues(linkUrlFieldId)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      </Accordion>
    </ContentEditorWrapper>
  );
};

export default RewardClaimedContentEditor;
