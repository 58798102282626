/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { HelpTextProps, ValidationState } from "@react-types/shared";
import cn from "classnames";
import { OptionTypeBase } from "interfaces";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import Select, {
  CSSObjectWithLabel,
  ControlProps,
  DropdownIndicatorProps,
  OptionProps,
  Options,
  SingleValueProps,
  StylesConfig,
  Theme,
  ThemeConfig,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { MultiValueRemoveProps } from "react-select/dist/declarations/src/components/MultiValue";

import { ArrowDownIcon, CancelIcon } from "components/Icon";
import TooltipWrapper from "components/TooltipWrapper";

export interface DropdownProps extends HelpTextProps {
  label: string;
  id: string;
  items: OptionTypeBase[];
  value: OptionTypeBase | null;
  validationState?: ValidationState;
  onChange?: (
    selectedOptions: OptionTypeBase | Options<OptionTypeBase>
  ) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  createLabel?: string;
  noOptionsMessage?: string;
  isCreatable?: boolean;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
  isMulti?: true | undefined;
  showValueInLabel?: boolean;
  tooltip?: string;
}

const theme: ThemeConfig = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // All possible overrides
    // border color isFocused option is selected background color
    primary: "rgb(var(--surface-primary) / 1)",

    // option is focused background color
    primary25: "rgb(var(--surface-primary) / 1)",

    // active option background color not selected not disabled
    primary50: "rgb(var(--surface-primary) / 1)",
    primary75: "rgb(var(--primary) / 1)",

    danger: "rgb(var(--error) / 1)",
    dangerLight: "rgb(var(--error) / 1)",

    // option color selected and menu background color
    neutral0: "rgb(var(--surface-primary) / 1)",
    neutral5: "rgb(var(--surface-primary) / 1)", // disabled control background

    // border color disabled  and option color isSelected and control background
    neutral10: "rgb(var(--surface-primary) / 1)",

    // border color and option color isDisabled and indicator container color
    neutral20: "rgb(var(--surface-primary) / 1)",
    neutral30: "rgb(var(--surface-primary) / 1)", // control hover border

    // notices color and single value disabled
    neutral40: "rgb(var(--color-text) / 1)",

    neutral50: "rgb(var(--color-text) / 0.2)", // placeholder
    neutral60: "rgb(var(--color-text) / 1)", // indicator container color focus

    // input color single value color
    neutral80: "rgb(var(--color-text) / 1)",
  },
  // Other options you can use
  borderRadius: 0,
  // Used to calculate consistent margin/padding on elements
  baseUnit: 4,
  // The minimum height of the control
  controlHeight: 38,
  // The amount of space between the control and menu */
  menuGutter: 5,
});

const Dropdown: FC<DropdownProps> = ({
  label,
  id,
  items,
  value,
  onChange,
  isDisabled,
  isClearable = false,
  isCreatable = false,
  isSearchable = false,
  placeholder = "Select...",
  createLabel = "Set value to:",
  noOptionsMessage = "No options",
  validationState = "valid",
  isMulti = undefined,
  showValueInLabel = false,
  errorMessage,
  tooltip,
}) => {
  const isInvalid = validationState === "invalid";
  const labelId = `${id} ${label}`;

  const customStyles: StylesConfig = {
    menu: (provided: CSSObjectWithLabel) => ({
      ...provided,
      marginBottom: "0px",
      boxShadow: "none",
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "10px 10px 20px",
    }),
    option: (provided: CSSObjectWithLabel, state: OptionProps) => ({
      ...provided,
      borderBottom: "1px solid",
      borderColor: "rgb(var(--color-text) / 0.2)",
      fontWeight: state.isSelected || state.isFocused ? "bold" : "normal",
      color: "rgb(var(--color-text) / 1)",
      padding: "10px 0px",
    }),
    control: (provided: CSSObjectWithLabel, state: ControlProps) => ({
      ...provided,
      borderWidth: "2px",
      borderColor: state.isFocused
        ? "rgb(var(--surface-primary) / 1)"
        : isInvalid
          ? "rgb(var(--error) / 1)"
          : "rgb(var(--surface-primary) / 1)",
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
    }),
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "10px",
      gap: "10px",
    }),
    singleValue: (provided: CSSObjectWithLabel, state: SingleValueProps) => {
      const opacity =
        state.isDisabled || isInvalid || state.selectProps.menuIsOpen ? 0.5 : 1;
      const marginLeft = 0;
      return { ...provided, opacity, marginLeft };
    },
    indicatorsContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({}),
    clearIndicator: (provided: CSSObjectWithLabel) => ({
      ...provided,
    }),
    multiValue: (provided: CSSObjectWithLabel) => ({
      ...provided,
      backgroundColor: "rgb(var(--surface-tertiary) / 1)",
      borderRadius: "8px",
      padding: "0 10px",
      margin: 0,
      fontSize: "12px",
      lineHeight: "14px",
      display: "flex",
      alignItems: "center",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    }),
    multiValueLabel: (provided: CSSObjectWithLabel) => ({
      ...provided,
      padding: "8px 5px 8px 10px",
      fontWeight: "bold",
    }),
    multiValueRemove: (provided: CSSObjectWithLabel) => ({
      ...provided,
      height: "100%",
      boxSizing: "border-box",
      opacity: 0.4,
      ":hover": {
        opacity: 1,
        cursor: "pointer",
      },
    }),
    dropdownIndicator: (
      provided: CSSObjectWithLabel,
      state: DropdownIndicatorProps
    ) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return {
        ...provided,
        color: `rgb(var(--color-text) / ${opacity})`,
        transition: "all .2s ease",
        transform:
          state.selectProps.menuIsOpen && !isInvalid
            ? "rotate(180deg)"
            : undefined,
      };
    },
    input: (provided: CSSObjectWithLabel) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
    }),
  };

  const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => (
    <components.DropdownIndicator {...props}>
      {isInvalid ? (
        <MdCancel className="w-6 h-6 text-actions-error" />
      ) : (
        <ArrowDownIcon />
      )}
    </components.DropdownIndicator>
  );

  const MultiValueRemove = (props: MultiValueRemoveProps<any, true>) => (
    <components.MultiValueRemove {...props}>
      <CancelIcon />
    </components.MultiValueRemove>
  );

  const itemsWithValueInLabel = items.map((item) => ({
    ...item,
    label: `${item.label} (${item.value})`,
  }));

  const itemWithValueInLabel = value && {
    ...value,
    label: `${value.label} (${value.value})`,
  };

  const SelectComponent = isCreatable ? CreatableSelect : Select;

  return (
    <div className="col-span-6 sm:col-span-3">
      <div className="flex flex-row w-full justify-end">
        <label
          htmlFor={id}
          id={labelId}
          className={cn("pb-1 text-text font-bold flex-1", {
            "text-opacity-50": isDisabled,
          })}
        >
          {label}
        </label>
        {tooltip && (
          <div className="flex-0 text-xl m-1">
            <TooltipWrapper
              show={!!tooltip}
              text={tooltip || ""}
              placement={"top"}
            >
              <AiOutlineInfoCircle title="More information" />
            </TooltipWrapper>
          </div>
        )}
      </div>
      <SelectComponent
        styles={customStyles}
        theme={theme}
        defaultValue={showValueInLabel ? itemWithValueInLabel : value}
        isDisabled={isDisabled}
        closeMenuOnSelect={true}
        isClearable={isClearable}
        isSearchable={isSearchable || isCreatable}
        isMulti={isMulti}
        name={id}
        placeholder={placeholder}
        options={showValueInLabel ? itemsWithValueInLabel : items}
        formatCreateLabel={(inputValue) => {
          return `${createLabel} ${inputValue}`;
        }}
        noOptionsMessage={() => noOptionsMessage}
        inputId={id}
        onChange={(selectedOption: OptionTypeBase | null) => {
          if (selectedOption === null) {
            onChange && isClearable && onChange({ name: null, value: null });
          } else {
            onChange && onChange(selectedOption);
          }
        }}
        aria-label={label}
        aria-labelledby={labelId}
        classNamePrefix="react-select-dropdown"
        className="react-select-dropdown shadow"
        components={{ DropdownIndicator, MultiValueRemove }}
      />
      {errorMessage && isInvalid && (
        <>
          <div className="text-actions-error text-xs pt-2">{errorMessage}</div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
