import { createContext } from "react";

import { Market } from "interfaces";

export interface MarketsContextType {
  state: Market[];
  setMarkets: (markets: Market[]) => void;
}

export const MarketsContext = createContext<MarketsContextType>({
  state: [],
  setMarkets: () => {
    // do nothing
  },
});
