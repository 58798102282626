import React, { FC, useContext, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { Campaign, Market } from "interfaces";
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
} from "react-router-dom";

import { NurtureConfigAvailableTypes } from "constants/NurtureConfigsConstant";

import { useAuth } from "components/AuthProvider";
import CampaignConfigurationEditor from "components/CampaignConfigurationEditor/CampaignConfigurationEditor";
import CampaignEditor from "components/CampaignEditor/CampaignEditor";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import NurtureEditor from "components/Nurture/NurtureEditor";
import WrapperSiteEditor from "components/WrapperSite/WrapperSiteEditor/WrapperSiteEditor";

import { FormHelpersContext } from "context/FormHelpersContext";
import { MarketsContext, MarketsContextType } from "context/MarketsContext";
import { SelectedCampaignProvider } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";
import { WrapperSiteEditorProvider } from "context/WrapperSiteEditorContext";

interface Match {
  params: {
    marketSubdomain: string;
    campaignSlug: string;
  };
}

const CampaignRouter: FC = () => {
  const markets = useContext<MarketsContextType>(MarketsContext);
  const [, setSelectedMarket] = useSelectedMarketContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const location = useLocation();
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const [formHelpers, setFormHelpers] = useState<any>(null);
  const { axios } = useAuth();

  if (!axios) {
    throw new Error("Something went wrong - context not set correctly");
  }

  const match: Match | null = matchPath(location.pathname, {
    path: "/:marketSubdomain/:campaignSlug",
  });

  const selectedMarket: Market | null | undefined =
    match &&
    match.params &&
    markets.state.find((market: Market) => {
      return market.subdomain === match.params.marketSubdomain;
    });

  const loadFormHelpers = async (
    source: CancelTokenSource,
    selectedMarket: Market
  ) => {
    try {
      const {
        data: { campaign_configurations },
      } = await axios.get(`/api/markets/${selectedMarket.id}/form_helpers`, {
        params: {
          region_key: selectedMarket.region_key,
        },
        cancelToken: source.token,
      });

      setFormHelpers({ campaign_configurations });
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();

    if (selectedMarket) {
      setSelectedMarket(selectedMarket);

      loadFormHelpers(source, selectedMarket);

      setLoading(false);
    }
    return () => source.cancel();
  }, []);

  if (!selectedMarket) return <Route render={() => <Redirect to="/" />} />;

  if (error) {
    return <ErrorAlert message={error} />;
  }

  const selectedCampaign: Campaign | undefined | null =
    match &&
    match.params &&
    selectedMarket.campaigns.find(
      (campaign) => campaign.slug === match.params.campaignSlug
    );

  if (!selectedCampaign) return <Route render={() => <Redirect to="/" />} />;

  const availableNurtureTypes: string = NurtureConfigAvailableTypes.join("|");

  if (loading) {
    return <Loader />;
  }

  return (
    <FormHelpersContext.Provider value={formHelpers}>
      <SelectedCampaignProvider value={selectedCampaign}>
        <Switch>
          <Route exact path="/:market_subdomain/:campaign_slug">
            <CampaignEditor />
          </Route>
          <Route path="/:market_subdomain/:campaign_slug/configuration/:configuration_id">
            <CampaignConfigurationEditor />
          </Route>
          <Route
            path={`/:market_subdomain/:campaign_slug/:nurture_type(${availableNurtureTypes})`}
          >
            <NurtureEditor />
          </Route>
          <Route path={"/:market_subdomain/:campaign_slug/wrapper_site"}>
            <WrapperSiteEditorProvider>
              <WrapperSiteEditor />
            </WrapperSiteEditorProvider>
          </Route>
          <Route
            render={() => (
              <Redirect
                to={`/${selectedMarket.subdomain}/${selectedCampaign.slug}`}
              />
            )}
          />
        </Switch>
      </SelectedCampaignProvider>
    </FormHelpersContext.Provider>
  );
};

export default CampaignRouter;
