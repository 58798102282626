import React, { ButtonHTMLAttributes, FC, MouseEvent } from "react";

import { IconType } from "react-icons";

export type ButtonProps = {
  className?: string;
  justifyContent?: "start" | "center" | "end";
  // TODO sc-28047 - remove text prop in favor of children
  text?: string;
  role?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  handleOnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  appearance?: "primary" | "secondary" | "danger" | "link";
  description?: string;
  testId?: string;
  children?: React.ReactNode;
  PrefixIcon?: IconType;
};

const Button: FC<ButtonProps> = ({
  className,
  justifyContent,
  text,
  type,
  role,
  handleOnClick,
  disabled,
  appearance,
  description,
  testId,
  children,
  PrefixIcon,
}) => {
  const where = justifyContent || "center";

  const content = (
    <>
      {PrefixIcon && (
        <span className="mr-3">
          <PrefixIcon />
        </span>
      )}
      {children || text}
    </>
  );

  if (!appearance) {
    return (
      <div className={className}>
        <button
          data-testid={testId}
          onClick={handleOnClick}
          className={`disabled:pointer-events-none disabled:opacity-50 justify-${where} py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          disabled={disabled}
          role={role}
          type={type}
        >
          {content}
        </button>
      </div>
    );
  }

  return (
    <button
      data-testid={testId}
      onClick={handleOnClick}
      className={`flex justify-${where} items-center box-border disabled:pointer-events-none disabled:opacity-50 w-full text-md font-medium rounded-lg focus:outline-none focus:ring-0 ${appearance}-btn`}
      disabled={disabled}
      aria-label={description}
    >
      {content}
    </button>
  );
};

export default Button;
