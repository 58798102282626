import React, { FC } from "react";

import { CheckboxField, InputField } from "components/Form";
import { NurtureConfig } from "components/Nurture/interfaces";

type NurtureRepeatSettingsRowProps = {
  nurtureConfig: NurtureConfig;
  handleInputToggle: (id: string, nextChecked: boolean) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const NurtureRepeatSettingsRow: FC<NurtureRepeatSettingsRowProps> = ({
  nurtureConfig,
  handleInputToggle,
  handleInputChange,
}) => (
  <tr>
    <td className="p-4" colSpan={2}>
      <CheckboxField
        className="flex-row-reverse justify-end"
        label="Repeat the last email every"
        id="repeat_delay_days"
        value={nurtureConfig.repeat_delay_days !== null}
        handleToggle={handleInputToggle}
      />
    </td>
    <td className="flex flex-row p-4">
      <InputField
        id="repeat_delay_days"
        testId="repeat_delay_days"
        className="w-1/2"
        placeholder=""
        min="1"
        type="number"
        value={nurtureConfig.repeat_delay_days || ""}
        handleInputChange={handleInputChange}
        disabled={nurtureConfig.repeat_delay_days === null}
      />
      <div className="self-center w-1/2 px-4">days</div>
    </td>
  </tr>
);

export default NurtureRepeatSettingsRow;
