import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { capitalize } from "helpers/StringHelper";
import { EditorsGroup, OptionTypeBase } from "interfaces";
import { useHistory, useRouteMatch } from "react-router";

import ErrorAlert from "components/ErrorAlert";
import Picker from "components/Picker/Picker";
import { Radio, RadioGroup } from "components/Radio";

import { LocalesContext, LocalesContextType } from "context/LocalesContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type Props = {
  group: EditorsGroup;
  stepEditorPath: string;
  currentLocale: string;
  setCurrentLocale: Dispatch<SetStateAction<string>>;
  setPreviousLocale: Dispatch<SetStateAction<string>>;
};

interface MatchParams {
  editorType: string;
}

const Nav: FC<Props> = ({
  group,
  stepEditorPath,
  currentLocale,
  setCurrentLocale,
  setPreviousLocale,
}) => {
  const history = useHistory();
  const match = useRouteMatch<MatchParams>({
    path: "/:marketSubdomain/:campaignSlug/configuration/:configurationId/:flow/:group/:editorType",
    strict: true,
    sensitive: true,
  });

  const localesMap = useContext<LocalesContextType>(LocalesContext);

  const [selectedMarket] = useSelectedMarketContext();
  if (!selectedMarket) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }
  const currentEditorType = match?.params.editorType
    ? `/${match.params.editorType}`
    : group.stepEditors[0].path;

  const [radioState, setRadioState] = useState<string>(currentEditorType);

  const handleOnChange = (value: string) => setRadioState(value);

  useEffect(() => {
    history.replace(`${stepEditorPath}${radioState}`);
  }, [radioState]);

  const supportedLocales = useMemo(
    () =>
      selectedMarket.supported_locales.map((locale) => ({
        value: locale,
        label: localesMap[locale],
      })),
    [selectedMarket]
  );

  const defaultLocale: OptionTypeBase =
    supportedLocales.find((locale) => locale.value === currentLocale) ||
    supportedLocales[0];

  const onSelectedLocaleChange = (selectedLocale: OptionTypeBase) => {
    setCurrentLocale(selectedLocale.value);
    setPreviousLocale(currentLocale);
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <RadioGroup
        name={group.id}
        label={group.name}
        onChange={handleOnChange}
        value={radioState}
        id={radioState}
        isDisabled={false}
      >
        <div className="flex flex-row flex-wrap">
          {group.stepEditors.map((editor) => (
            <Radio key={editor.path} value={editor.path}>
              {capitalize(editor.type)}
            </Radio>
          ))}
        </div>
      </RadioGroup>
      <div className="w-75">
        <Picker
          id="selected-locale"
          label={"Select a locale"}
          value={defaultLocale}
          items={supportedLocales}
          onChange={onSelectedLocaleChange}
        />
      </div>
    </div>
  );
};

export default Nav;
