import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import Button from "components/Button";
import CustomFieldSettingsEditor from "components/CampaignConfigurationEditor/StepEditor/Editors/CustomFieldsEditor/CustomFieldSettingsEditor";

type CustomFieldsSettingsEditorProps = {
  customFieldTypeDropdownItems: { label: string; value: string }[];
  requiredCustomFieldConfigFor: (customFieldType: string) => string[];
  customFieldsPath: string;
  setConfigureCustomFields: Dispatch<SetStateAction<boolean>>;
};

const CustomFieldsSettingsEditor: FC<CustomFieldsSettingsEditorProps> = ({
  customFieldTypeDropdownItems,
  requiredCustomFieldConfigFor,
  customFieldsPath,
  setConfigureCustomFields,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { getValues, setValue } = useFormContext();

  const { fields, append, remove, update, move } = useFieldArray({
    name: customFieldsPath,
  });

  const watchFieldArray = useWatch({ name: customFieldsPath }) || [];

  const controlledFields = fields.map(
    (customField: Record<"id", string>, index: number) => ({
      ...customField,
      ...watchFieldArray[index],
    })
  );

  const addCustomField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    let position;

    if (!getValues(customFieldsPath)) {
      setValue(customFieldsPath, []);
      position = 1;
    } else {
      position = getValues(customFieldsPath).length + 1;
    }
    append({ key: "", type: "free_text", config: {}, position });
  };

  const updateCustomFieldsPositions = () => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fields.forEach((field: any, index: number) => {
      if (field.position != index + 1) {
        update(index, { ...field, position: index + 1 });
      }
    });
  };

  const sortCustomFieldsByPosition = () => {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    fields.forEach((field: any, index: number) => {
      if (field.position != index + 1) {
        move(index, field.position - 1);
      }
    });
  };

  const removeCustomField = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    remove(index);
    if (getValues(customFieldsPath).length === 0) {
      setConfigureCustomFields(false);
    }
  };

  useEffect(() => {
    if (isFirstRender) {
      if (watchFieldArray.length === 0) {
        append({ key: "", type: "free_text", config: {}, position: 1 });
      }
      sortCustomFieldsByPosition();
      setIsFirstRender(false);
    } else {
      updateCustomFieldsPositions();
    }
  }, [fields]);

  return (
    <>
      {controlledFields.map((customField, index) => (
        <CustomFieldSettingsEditor
          key={customField.id}
          customField={customField}
          customFieldsPath={customFieldsPath}
          index={index}
          customFieldTypeDropdownItems={customFieldTypeDropdownItems}
          requiredCustomFieldConfigFor={requiredCustomFieldConfigFor}
          removeCustomField={removeCustomField}
        />
      ))}
      <Button
        appearance="secondary"
        text="Add a custom field"
        handleOnClick={addCustomField}
      />
    </>
  );
};

export default CustomFieldsSettingsEditor;
