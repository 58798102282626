import { NurtureConfigType } from "interfaces";

export const NurtureConfigTypes: NurtureConfigType[] = [
  {
    type: "referrer_nurture",
    label: "Referrer nurture",
  },
  {
    type: "referrer_inactive_nurture",
    label: "Referrer inactive nurture",
  },
  {
    type: "friend_nurture",
    label: "Friend nurture",
  },
  {
    type: "friend_pending_referral_nurture",
    label: "Friend pending referral nurture",
  },
  {
    type: "potential_campaign_signup_nurture",
    label: "Potential campaign signup nurture",
  },
];

export const NurtureConfigAvailableTypes: string[] = NurtureConfigTypes.map(
  (nurture_config_type: NurtureConfigType) => nurture_config_type.type
);
