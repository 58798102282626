import React, { FC } from "react";

import Button from "components/Button";

type NurtureSequenceActionsProps = {
  handleAddSequenceStep: () => void;
  handleCancel: () => void;
  handleSave: () => void;
  disableSave: boolean;
};

const NurtureSequenceActions: FC<NurtureSequenceActionsProps> = ({
  handleAddSequenceStep,
  handleCancel,
  handleSave,
  disableSave,
}) => (
  <div className="flex flex-col sm:flex-row space-y-6 sm:space-y-0 justify-between mt-10">
    <div className="inline-flex flex-col w-52">
      <Button
        className="px-5"
        appearance="primary"
        handleOnClick={handleAddSequenceStep}
        text="Add email to sequence"
        role="button"
      />
    </div>
    <div>
      <div className="inline-flex flex-col w-20">
        <Button
          appearance="danger"
          handleOnClick={handleCancel}
          text="Cancel"
          role="button"
        />
      </div>
      <div className="inline-flex flex-col w-20 ml-4">
        <Button
          appearance="primary"
          handleOnClick={handleSave}
          disabled={disableSave}
          text="Save"
          role="button"
        />
      </div>
    </div>
  </div>
);

export default NurtureSequenceActions;
