import React, { FC, useContext, useEffect } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";
import { Market } from "interfaces";
import { matchPath } from "react-router";

import BodyWrapper from "components/BodyWrapper";
import RewardOptions from "components/MarketConfiguration/RewardOptions/RewardOptions";

import { MarketsContext, MarketsContextType } from "context/MarketsContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

interface Match {
  params: {
    marketSubdomain: string;
  };
}

const MarketConfiguration: FC = () => {
  const { setBreadcrumbItems } = useBreadcrumb();
  const [selectedMarket] = useSelectedMarketContext();
  const { state: markets } = useContext<MarketsContextType>(MarketsContext);

  const match: Match | null = matchPath(location.pathname, {
    path: "/:marketSubdomain/edit",
  });

  const findMarket = () =>
    match &&
    match.params &&
    markets.find((m) => m.subdomain === match.params.marketSubdomain);
  const market: Market | undefined | null = selectedMarket || findMarket();

  if (!market) {
    throw new Error("Context isn't set correctly");
  }

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: market.name,
        path: "/",
      },
      {
        label: "Reward options",
      },
    ]);
  }, [selectedMarket, setBreadcrumbItems]);

  return (
    <BodyWrapper>
      <RewardOptions market={market} />
    </BodyWrapper>
  );
};

export default MarketConfiguration;
