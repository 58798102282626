import React, { FC, RefObject, useRef } from "react";

import { TextFieldInputProps, useTextField } from "@react-aria/textfield";
import { SpectrumTextFieldProps } from "@react-types/textfield";
import cn from "classnames";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

import TooltipWrapper from "../TooltipWrapper";

export interface TextFieldProps extends SpectrumTextFieldProps {
  elementType: "input" | "textarea";
  min?: string;
  className?: string;
  tooltip?: string;
}

type TextFieldElProps = {
  isInvalid: boolean;
  inputId: string;
  inputProps: TextFieldInputProps<"input" | "textarea">;
  forwardRef: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  min?: string;
  className?: string;
};

const classnames = (isInvalid: boolean, extraClassNames?: string) =>
  cn(
    "disabled:pointer-events-none disabled:text-opacity-20",
    "text-text",
    "shadow",
    "border-transparent",
    "bg-surfacePrimary",
    "focus:border-brand-primary focus:border-2",
    "focus:bg-surfacePrimary/100",
    {
      "border-actions-error border-2": isInvalid,
    },
    extraClassNames
  );

const Input = ({
  isInvalid,
  inputId,
  inputProps,
  forwardRef,
  min,
  className,
}: TextFieldElProps) => (
  <input
    id={inputId}
    {...(inputProps as TextFieldInputProps<"input">)}
    ref={forwardRef as RefObject<HTMLInputElement>}
    data-lpignore="true"
    autoComplete="off"
    className={classnames(isInvalid, className)}
    min={min}
  />
);

const TextArea = ({
  isInvalid,
  inputId,
  inputProps,
  forwardRef,
  className,
}: TextFieldElProps) => (
  <textarea
    id={inputId}
    {...(inputProps as TextFieldInputProps<"textarea">)}
    ref={forwardRef as RefObject<HTMLTextAreaElement>}
    data-lpignore="true"
    autoComplete="off"
    className={classnames(isInvalid, className)}
  />
);

export const TextField: FC<TextFieldProps> = (props) => {
  const { errorMessage, validationState, id, elementType, min, className } =
    props;
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const { label, ...textFieldProps } = props;
  const { labelProps, inputProps, errorMessageProps } = useTextField<
    "input" | "textarea"
  >(textFieldProps, ref);

  const isInvalid = validationState === "invalid";
  const inputId = id ?? "textfield";
  const ariaLabelledby = props["aria-labelledby"] ?? "textfieldlabel";

  const icon = (
    <MdCancel className="absolute right-2 z-20 block w-6 h-6 text-actions-error" />
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row w-full justify-end">
        {label && (
          <label
            htmlFor={inputId}
            id={ariaLabelledby}
            {...labelProps}
            className="pb-1 text-text font-bold flex-1"
          >
            {label}
          </label>
        )}
        {props.tooltip && (
          <div className="flex-0 text-xl m-1">
            <TooltipWrapper
              show={!!props.tooltip}
              text={props.tooltip || ""}
              placement={"top"}
            >
              <AiOutlineInfoCircle title="More information" />
            </TooltipWrapper>
          </div>
        )}
      </div>
      <div className="relative flex flex-col justify-center w-full">
        {elementType === "input" && (
          <Input
            isInvalid={isInvalid}
            inputId={inputId}
            inputProps={inputProps}
            forwardRef={ref}
            min={min}
            className={className}
          />
        )}
        {elementType === "textarea" && (
          <TextArea
            isInvalid={isInvalid}
            inputId={inputId}
            inputProps={inputProps}
            forwardRef={ref}
            className={className}
          />
        )}
        {errorMessage && isInvalid && icon}
      </div>
      {errorMessage && isInvalid && (
        <div {...errorMessageProps} className="text-actions-error text-xs pt-1">
          {errorMessage}
        </div>
      )}
    </div>
  );
};
