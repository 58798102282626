import { AppState } from "@auth0/auth0-react";
import React from "react";

import ReactDOM from "react-dom";

import App from "components/App";
import { AuthProvider } from "components/AuthProvider";

import histroy from "../components/routers/Histroy";

document.addEventListener("DOMContentLoaded", () => {
  const onRedirectCallback = (appState: AppState) => {
    histroy.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  ReactDOM.render(
    <AuthProvider
      domain={window.env.auth0_domain}
      client_id={window.env.auth0_client_id}
      audience={window.env.auth0_audience}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
    </AuthProvider>,
    document.body.appendChild(document.createElement("div"))
  );
});
