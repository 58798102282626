import React from "react";

import { EditorComponent } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { TextField } from "components/TextField/TextField";

import ContentEditorWrapper from "../../ContentEditorWrapper/ContentEditorWrapper";

const SharedContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const { getValues, control } = useFormContext();

  const contentPath = `${flowId}.shared.content`;
  const fieldRootPath = `${contentPath}.${locale}`;

  const claimRewardErrorFieldId = `${fieldRootPath}.claim_reward_error`;
  const claimRewardErrorFieldLabel = "Claim error message";

  return (
    <ContentEditorWrapper
      nextLocale={locale}
      contentPath={contentPath}
      goToPreviousLocale={goToPreviousLocale}
    >
      <Accordion label={claimRewardErrorFieldLabel} isExpandedByDefault={true}>
        <Controller
          control={control}
          name={claimRewardErrorFieldId}
          key={claimRewardErrorFieldId}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              id={claimRewardErrorFieldId}
              aria-label={claimRewardErrorFieldLabel}
              value={getValues(claimRewardErrorFieldId)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      </Accordion>
    </ContentEditorWrapper>
  );
};

export default SharedContentEditor;
