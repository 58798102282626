import { ReactElement } from "react";

import TextAlignTypes from "constants/TextAlignTypes";

export const getDropdownItems = (dropdownType: ReactElement) =>
  Object.entries(dropdownType).map((type) => ({
    value: type[0],
    label: type[1],
  }));

export const dropdownTypes = {
  TextAlignTypes: TextAlignTypes,
};
