import React, { FC } from "react";

import { itemsToDropdowItems } from "helpers/DropdownHelper";
import { Item, OptionTypeBase, Product } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import Dropdown from "components/Dropdown/Dropdown";
import { TextField } from "components/TextField/TextField";

type RunaDenominationSelector = {
  selectedProduct: Product;
};
const RunaDenominationSelector: FC<RunaDenominationSelector> = ({
  selectedProduct,
}) => {
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const currency = selectedProduct.currency;

  if (selectedProduct.denomination_type === "open") {
    const minimumDenomination = selectedProduct.minimum_value || 0;
    const maximumDenomination = selectedProduct.maximum_value || 999999;

    return (
      <Controller
        control={control}
        name={"denomination"}
        rules={{
          required: { value: true, message: "No value set" },
          min: {
            value: minimumDenomination,
            message: `Please enter a value over ${minimumDenomination}`,
          },
          max: {
            value: maximumDenomination,
            message: `Please enter a value under ${maximumDenomination}`,
          },
        }}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            type="number"
            min={minimumDenomination.toString()}
            label={`Reward value (${currency})`}
            aria-label={`Reward value (${currency})`}
            id="reward-denomination"
            value={getValues("denomination")?.toString()}
            onChange={(e) =>
              onChange(Number.isInteger(parseFloat(e)) ? parseFloat(e) : null)
            }
            validationState={errors?.denomination ? "invalid" : "valid"}
            errorMessage={errors?.denomination?.message}
          />
        )}
      />
    );
  }

  const availableValues: Item[] | undefined =
    selectedProduct.available_denominations?.map((number) => {
      return { name: number.toString(), id: number.toString() };
    });
  const dropdownItems = itemsToDropdowItems(availableValues || []);

  return (
    <Controller
      control={control}
      name="denomination"
      rules={{ required: { value: true, message: "Required" } }}
      render={({ field: { onChange, value } }) => (
        <Dropdown
          id="reward-denomination"
          value={value}
          items={dropdownItems}
          onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
          label={`Reward value (${currency})`}
          aria-label={`Reward value (${currency})`}
          tooltip="Select from available values for this reward"
          validationState={errors?.denomination ? "invalid" : "valid"}
          errorMessage={errors?.denomination?.message}
        />
      )}
    />
  );
};

export default RunaDenominationSelector;
