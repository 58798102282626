import React, { Dispatch, FC, SetStateAction } from "react";

import { CancelTokenSource } from "axios";
import { DraftConfiguration } from "interfaces";
import toast from "react-hot-toast";

import { useAuth } from "components/AuthProvider";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import ErrorToast from "components/ErrorToast";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type DeleteCampaignConfigurationModalProps = {
  configuration: DraftConfiguration | null;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  sourceToken: CancelTokenSource | null;
  loadConfigurations: (source: CancelTokenSource | null) => Promise<void>;
};

const DeleteCampaignConfigurationModal: FC<
  DeleteCampaignConfigurationModalProps
> = ({
  configuration,
  showDeleteModal,
  setShowDeleteModal,
  sourceToken,
  loadConfigurations,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  const deleteConfiguration = async () => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      throw new Error("Something went wrong - context not set correctly");
    }

    try {
      await axios.delete(`/api/campaign_configurations/${configuration?.id}`, {
        data: {
          region_key: selectedMarket?.region_key,
          campaign_id: selectedCampaign?.id,
          version: configuration?.version,
        },
      });
    } finally {
      loadConfigurations(sourceToken);
      setShowDeleteModal(false);
    }
  };

  const handleDelete = () => {
    const deletingConfiguration = deleteConfiguration();

    toast.promise(deletingConfiguration, {
      loading: "Deleting a draft...",
      success: "Draft successfully deleted",
      error: function MyToast(e) {
        return <ErrorToast error={e} />;
      },
    });
  };

  return (
    <ConfirmationModal
      title="Are you sure you want to delete this draft?"
      positiveButtonText="No"
      negativeButtonText="Yes, delete"
      showModal={showDeleteModal}
      setToggle={setShowDeleteModal}
      handlePositiveAction={() => setShowDeleteModal(false)}
      handleNegativeAction={handleDelete}
    >
      <p className="text-text">
        Doing so will remove any changes made on the last edit page
      </p>
    </ConfirmationModal>
  );
};

export default DeleteCampaignConfigurationModal;
