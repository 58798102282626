import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import useBreadcrumb from "hooks/useBreadcrumb";
import {
  EmailTemplate,
  FlowId,
  RewardOptionsListItem,
  RewardType,
  VoucherPool,
} from "interfaces";
import { get, set } from "lodash";
import { Redirect, Route, useRouteMatch } from "react-router-dom";

import { useAuth } from "components/AuthProvider";
import FormWrapper from "components/CampaignConfigurationEditor/FormWrapper/FormWrapper";
import ManageRewardOptions from "components/CampaignConfigurationEditor/RewardManagementEditor/ManageRewardOptions/ManageRewardOptions";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";
import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

interface MatchParams {
  rewardTypeId: string;
}

type RewardOptionsEditorProps = {
  flowId: FlowId;
  flowName: string;
  flowPath: string;
  groupName: string;
};
const RewardOptionsEditor: FC<RewardOptionsEditorProps> = ({
  flowId,
  flowName,
  flowPath,
  groupName,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [selectedCampaignConfiguration, setSelectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (
    !axios ||
    !selectedMarket ||
    !selectedCampaign ||
    !selectedCampaignConfiguration
  ) {
    throw new Error("Context isn't set correctly");
  }

  const { setBreadcrumbItems } = useBreadcrumb();

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;
  const campaignConfigurationEditorPath = `${campaignEditorPath}/configuration/${selectedCampaignConfiguration.id}`;
  const rewardTypesEditorPath = `${campaignConfigurationEditorPath}${flowPath}/reward-management`;

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [configurableVoucherPools, setConfigurableVoucherPools] = useState<
    VoucherPool[]
  >([]);
  const [configurableEmailTemplates, setConfigurableEmailTemplates] = useState<
    EmailTemplate[]
  >([]);
  const [
    configurableManualRewardEmailTemplates,
    setConfigurableManualRewardEmailTemplates,
  ] = useState<EmailTemplate[]>([]);
  const [configurableRewardOptions, setConfigurableRewardOptions] = useState<
    RewardOptionsListItem[]
  >([]);

  const match = useRouteMatch<MatchParams>({
    path: "/:marketSubdomain/:campaignSlug/configuration/:configurationId/:flow/reward-management/:rewardTypeId/edit",
  });

  if (!match) {
    return <Route render={() => <Redirect to={rewardTypesEditorPath} />} />;
  }

  const { rewardTypeId } = match.params;

  const configurableVoucherPoolsRequest = (source: CancelTokenSource) =>
    axios.get("/api/voucher_pools", {
      params: {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        flow: flowId,
      },
      cancelToken: source.token,
    });

  const configurableEmailTemplatesRequest = (source: CancelTokenSource) => {
    const type =
      flowId === "click_to_claim_referrer"
        ? "referrer_reward"
        : "friend_reward";

    return axios.get("/api/email_templates", {
      params: {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        flow: flowId,
        type: type,
      },
      cancelToken: source.token,
    });
  };

  const configurableManualRewardEmailTemplatesRequest = (
    source: CancelTokenSource
  ) =>
    axios.get("/api/email_templates", {
      params: {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        flow: flowId,
        type: "manual_reward",
      },
      cancelToken: source.token,
    });

  const configurableRunaRewardOptionsRequest = (source: CancelTokenSource) =>
    axios.get(`/api/markets/${selectedMarket.id}/reward_options`, {
      params: {
        region_key: selectedMarket.region_key,
      },
      cancelToken: source.token,
    });

  const loadConfigurables = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { voucher_pools },
        },
        {
          data: { email_templates },
        },
        {
          data: { reward_options },
        },
        {
          data: { email_templates: manual_reward_email_templates },
        },
      ] = await Promise.all([
        configurableVoucherPoolsRequest(source),
        configurableEmailTemplatesRequest(source),
        configurableRunaRewardOptionsRequest(source),
        configurableManualRewardEmailTemplatesRequest(source),
      ]);

      setConfigurableVoucherPools(voucher_pools);
      setConfigurableEmailTemplates(email_templates);
      setConfigurableRewardOptions(reward_options);
      setConfigurableManualRewardEmailTemplates(manual_reward_email_templates);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Configuration",
        value: selectedCampaignConfiguration.name,
        path: campaignConfigurationEditorPath,
      },
      {
        label: flowName,
        value: groupName,
        path: rewardTypesEditorPath,
      },
    ]);

    const pathToRewardTypesArray = `value.${flowId}.shared.settings.reward_types`;

    const rewardTypesArray = get(
      selectedCampaignConfiguration,
      pathToRewardTypesArray
    );
    const rewardTypeItem = rewardTypesArray.find(
      (rewardType: RewardType) => rewardType.id === rewardTypeId
    );

    if (!rewardTypeItem) {
      setSelectedCampaignConfiguration(
        set(
          selectedCampaignConfiguration,
          `${pathToRewardTypesArray}.${rewardTypesArray.length}`,
          { id: rewardTypeId, reward_options: [] }
        )
      );
    }

    const source = Axios.CancelToken.source();
    loadConfigurables(source);

    return () => {
      setBreadcrumbItems([
        {
          label: "Market",
          value: selectedMarket.name,
          path: "/",
        },
        {
          label: "Campaign",
          value: selectedCampaign.slug,
          path: campaignEditorPath,
        },
        {
          label: "Configuration",
          value: selectedCampaignConfiguration.name,
          path: campaignConfigurationEditorPath,
        },
        {
          label: flowName,
          value: groupName,
        },
      ]);
      source.cancel();
    };
  }, []);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <FormWrapper>
      <ManageRewardOptions
        flowId={flowId}
        rewardTypeId={rewardTypeId}
        emailTemplates={configurableEmailTemplates}
        manualRewardEmailTemplates={configurableManualRewardEmailTemplates}
        voucherPools={configurableVoucherPools}
        rewardOptions={configurableRewardOptions}
      />
    </FormWrapper>
  );
};

export default RewardOptionsEditor;
