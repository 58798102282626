import React, { FC } from "react";

const MobileIcon: FC = () => (
  <svg width="14" height="22" viewBox="0 0 14 22" fill="none">
    <path
      d="M12 0.00999999L2 0C0.9 0 0 0.9 0 2V20C0 21.1 0.9 22 2 22H12C13.1 22 14 21.1 14 20V2C14 0.9 13.1 0.00999999 12 0.00999999ZM12 18H2V4H12V18Z"
      fill="currentColor"
    />
  </svg>
);

export default MobileIcon;
