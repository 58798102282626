import React, { FC } from "react";

import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";

import Button from "components/Button";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

const EditMarketButton: FC = () => {
  const [selectedMarket] = useSelectedMarketContext();

  return (
    selectedMarket && (
      <div className="w-60">
        <Link className="w-full" to={`/${selectedMarket.subdomain}/edit`}>
          <Button
            appearance="primary"
            handleOnClick={() => {}}
            PrefixIcon={FiSettings}
          >
            Configure reward options
          </Button>
        </Link>
      </div>
    )
  );
};

export default EditMarketButton;
