import React from "react";

import {
  UseSelectedCampaign,
  useSelectedCampaign,
} from "hooks/useSelectedCampaign";
import { Campaign } from "interfaces";

import { createGenericContext } from "./createGenericContext";

interface Props {
  children: React.ReactNode;
  value?: Campaign;
}

// Generate context
const [useSelectedCampaignContext, SelectedCampaignContextProvider] =
  createGenericContext<UseSelectedCampaign>();

// Generate provider
const SelectedCampaignProvider = ({
  children,
  value,
}: Props): React.ReactElement => {
  const [selectedCampaign, setSelectedCampaign] = useSelectedCampaign(
    value || null
  );

  return (
    <SelectedCampaignContextProvider
      value={[selectedCampaign, setSelectedCampaign]}
    >
      {children}
    </SelectedCampaignContextProvider>
  );
};

export { useSelectedCampaignContext, SelectedCampaignProvider };
