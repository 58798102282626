import { useContext } from "react";

import {
  PreviewStates,
  PreviewStatesContext,
} from "context/PreviewStatesContext";

const usePreviewStates = (): PreviewStates => {
  const { previewStates, setPreviewStates } = useContext(PreviewStatesContext);

  return { previewStates, setPreviewStates };
};

export default usePreviewStates;
