import React, { FC } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";
import { BreadcrumbItem } from "interfaces";
import { IoIosArrowForward } from "react-icons/io";
import { useHistory } from "react-router";

const Breadcrumbs: FC = () => {
  const { breadcrumbItems } = useBreadcrumb();
  const history = useHistory();

  if (breadcrumbItems.length === 0) return null;

  const isLastItem = (index: number): boolean =>
    index === breadcrumbItems.length - 1;

  const handleOnClick = (item: BreadcrumbItem) => {
    if (!item.path) return;

    history.push(item.path);
  };

  const crumbWithValue = (item: BreadcrumbItem) =>
    `${item.label}: ${item.value}`;

  return (
    <div className="flex flex-row bg-brand-secondary w-full py-3">
      <div className="container mx-auto flex flex-row items-center">
        {breadcrumbItems.map((item, index) => (
          <div
            className="flex flex-row items-center text-button-text"
            key={item.label}
          >
            <div
              className={`text-sm ${
                item.path ? "cursor-pointer hover:underline" : ""
              }`}
              onClick={() => handleOnClick(item)}
            >
              {item.value ? crumbWithValue(item) : item.label}
            </div>

            {!isLastItem(index) && (
              <IoIosArrowForward
                className={"text-button-text stroke-1 h-4 w-auto px-2.5"}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
