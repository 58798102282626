import React from "react";

import { EditorComponent } from "interfaces";

import "components/CampaignConfigurationEditor/StepEditor/Editors";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";
import LookupUserEditorWrapper from "components/CampaignConfigurationEditor/StepEditor/Editors/UserLookupJourney/LookupUserEditor/LookupUserEditorWrapper/LookupUserEditorWrapper";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import schema from "../schema.json";

const LookupUserContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "lookup_user";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <LookupUserEditorWrapper>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <SchemaRenderer
          schema={schema}
          flowId={flowId}
          journeyId={journeyId}
          section={"content"}
          locale={locale}
        />
      </ContentEditorWrapper>
    </LookupUserEditorWrapper>
  );
};

export default LookupUserContentEditor;
