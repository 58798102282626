import React from "react";

import { EditorComponent } from "interfaces";

import schema from "components/CampaignConfigurationEditor/StepEditor/Editors/FriendJourney/schema.json";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import CaptureAdditionalFriendDetailsEditorWrapper from "../CaptureAdditionalFriendDetailsEditorWrapper/CaptureAdditionalFriendDetailsEditorWrapper";

const CaptureAdditionalFriendDetailsContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "capture_additional_friend_details";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <CaptureAdditionalFriendDetailsEditorWrapper>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <SchemaRenderer
          schema={schema}
          flowId={flowId}
          journeyId={journeyId}
          section={"content"}
          locale={locale}
        />
      </ContentEditorWrapper>
    </CaptureAdditionalFriendDetailsEditorWrapper>
  );
};

export default CaptureAdditionalFriendDetailsContentEditor;
