import React, { FC } from "react";

type FlowHeaderProps = {
  name: string;
};

const FlowHeader: FC<FlowHeaderProps> = ({ name }) => (
  <h2 className="text-left text-2xl font-semibold text-text flex-grow">
    {name}
  </h2>
);

export default FlowHeader;
