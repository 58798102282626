import React, { Dispatch, FC, SetStateAction, useEffect } from "react";

import { useFieldArray, useWatch } from "react-hook-form";

import CustomFieldContentEditor from "components/CampaignConfigurationEditor/StepEditor/Editors/CustomFieldsEditor/CustomFieldContentEditor";

type CustomFieldsContentEditorProps = {
  customFieldsPath: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  configuredCustomFields: any[];
  setShowConfiguredCustomFields: Dispatch<SetStateAction<boolean>>;
  allowTooltipConfiguration: boolean;
};

const CustomFieldsContentEditor: FC<CustomFieldsContentEditorProps> = ({
  customFieldsPath,
  configuredCustomFields,
  setShowConfiguredCustomFields,
  allowTooltipConfiguration,
}) => {
  const { fields, replace } = useFieldArray({
    name: customFieldsPath,
  });

  const watchFieldArray = useWatch({ name: customFieldsPath }) || [];

  const controlledFields = fields.map(
    (customField: Record<"id", string>, index: number) => ({
      ...customField,
      ...watchFieldArray[index],
    })
  );

  useEffect(() => {
    const configuredCustomFieldKeys =
      configuredCustomFields?.map((field) => field.key) || [];

    const customFieldContentSets = configuredCustomFieldKeys.map(
      (configuredCustomFieldKey: string) => {
        const config = configuredCustomFields.find(
          (f) => f.key === configuredCustomFieldKey
        ).config;
        const validationErrors = [
          {
            key: "default",
            value: "",
          },
          ...Object.keys(config || {}).map((m) => ({ key: m, value: "" })),
        ];

        const existing = watchFieldArray.find(
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          (existingItem: any) => existingItem.key === configuredCustomFieldKey
        );

        return {
          key: configuredCustomFieldKey,
          ...existing,
          content: {
            label: "",
            placeholder: "",
            validation_errors: validationErrors,
            ...existing?.content,
          },
        };
      }
    );

    if (customFieldContentSets.length === 0) {
      setShowConfiguredCustomFields(false);
    } else {
      replace(customFieldContentSets);
    }
  }, []);

  return (
    <>
      {controlledFields.map((customField, index) => (
        <CustomFieldContentEditor
          key={customField.id}
          allowTooltipConfiguration={allowTooltipConfiguration}
          customField={customField}
          customFieldsPath={customFieldsPath}
          index={index}
          config={
            configuredCustomFields.find((f) => f.key === customField.key)
              ?.config
          }
        />
      ))}
    </>
  );
};

export default CustomFieldsContentEditor;
