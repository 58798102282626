import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { IconContext } from "react-icons";
import { FaCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import { WrapperSiteDomain } from "components/WrapperSite/interfaces";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const WrapperSiteList: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [wrapperSiteDomain, setWrapperSiteDomain] =
    useState<WrapperSiteDomain>();

  const getWrapperSiteDomain = async (source: CancelTokenSource) => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      return <ErrorAlert message="Context isn't set correctly" />;
    }

    try {
      const { data } = await axios.get("/api/wrapper_site", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setWrapperSiteDomain(data.wrapper_site.domain);
      setLoading(false);
    } catch (e) {
      if (Axios.isCancel(e)) {
        // do nothing
      } else {
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getWrapperSiteDomain(source);

    return () => source && source.cancel();
  }, []);

  if (!selectedMarket || !selectedCampaign) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  const iconColor = wrapperSiteDomain ? "#3199cd" : "rgba(49, 153, 205, 0.3)";

  const domain = wrapperSiteDomain ? wrapperSiteDomain : "Not configured";

  return (
    <div className="mb-10 p-7 rounded-xl shadow bg-backgroundPrimary text-text">
      <h2 className="pb-5 text-xl font-medium">Wrapper site</h2>

      <li className="flex flex-row content-items-stretch border-b  border-opacity-20 border-text">
        <div className="flex flex-row flex-grow items-center">
          <IconContext.Provider
            value={{
              className: "mr-2",
              color: iconColor,
              size: "0.7em",
            }}
          >
            <div>
              <FaCircle />
            </div>
          </IconContext.Provider>
          {domain}
        </div>
        <div className="flex items-center w-1/4 p-4">
          <Link
            className="w-full"
            to={`/${selectedMarket.subdomain}/${selectedCampaign.slug}/wrapper_site`}
          >
            <Button text="Edit" appearance="primary" />
          </Link>
        </div>
      </li>
    </div>
  );
};

export default WrapperSiteList;
