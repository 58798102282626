import { Transition } from "@headlessui/react";
import React, { FC, useState } from "react";

import { getActionTypeClass } from "helpers/ClassHelper";
import { ActionsType } from "interfaces";
import { FaCircle } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type ConfigurationsGroupProps = {
  circleColor: ActionsType;
  title: string;
  loading: boolean;
  showTotal: boolean;
  total: number | null;
  defaultShowState: boolean;
  children: React.ReactNode;
};

const ConfigurationsGroup: FC<ConfigurationsGroupProps> = ({
  children,
  circleColor,
  title,
  loading,
  showTotal,
  total,
  defaultShowState,
}) => {
  const [isShowing, setIsShowing] = useState<boolean>(defaultShowState);

  const ArrowIcon = isShowing ? IoIosArrowUp : IoIosArrowDown;

  const circleColorClass = getActionTypeClass("text", circleColor);

  return (
    <div className="w-full p-7 rounded-xl shadow bg-backgroundPrimary">
      <div className="flex flex-row items-center">
        <FaCircle className={`mr-5 h-full ${circleColorClass} w-4`} />
        <h2 className="flex-grow text-xl font-medium text-text">
          {title} <span>{showTotal ? ` (${total})` : ""}</span>
        </h2>
        <button
          onClick={() => setIsShowing((isShowing) => !isShowing)}
          className="focus:outline-none focus:ring-0 text-text"
          aria-label={isShowing ? "collapse" : "expand"}
        >
          <ArrowIcon />
        </button>
      </div>
      <Transition
        show={isShowing}
        as="div"
        className="font-bold"
        enter="transition duration-150 ease-out"
        enterFrom="scale-y-95 opacity-0"
        enterTo="scale-y-100 opacity-100"
        leave="transition duration-150 ease-out"
        leaveFrom="scale-y-100 opacity-100"
        leaveTo="scale-y-95 opacity-0"
      >
        {loading ? (
          <div className="text-medium text-text flex flex-col items-center mt-5">
            <p>Loading the configurations</p>
          </div>
        ) : (
          children
        )}
      </Transition>
    </div>
  );
};

export default ConfigurationsGroup;
