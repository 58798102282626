import { FC, ReactElement, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";

type SplashPageEditorWrapperProps = {
  children: ReactElement;
};

const SplashPageEditorWrapper: FC<SplashPageEditorWrapperProps> = ({
  children,
}) => {
  const { setPreviewStates } = usePreviewStates();

  useEffect(() => {
    setPreviewStates([
      {
        label: "Default",
        value: {},
      },
      {
        label: "Show eligibility check rejection message",
        value: { show_eligibility_check_rejection_message: true },
      },
    ]);

    return () => setPreviewStates([]);
  }, []);

  return children;
};

export default SplashPageEditorWrapper;
