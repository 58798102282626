import { AppState } from "@auth0/auth0-react";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import createAuth0Client, { Auth0Client } from "@auth0/auth0-spa-js";
import { Auth0ClientOptions } from "@auth0/auth0-spa-js/dist/typings/global";
import Axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import PropTypes from "prop-types";

export interface ContextValue {
  isAuthenticated: boolean;
  loading: boolean;
  axios: AxiosInstance | null;
  handleRedirectCallback: () => Promise<void>;
  loginWithRedirect: () => Promise<void>;
  getTokenSilently: () => Promise<void>;
  logout: () => Promise<void>;
}

const token = document.querySelector('[name="csrf-token"]') as HTMLMetaElement;
const content = token ? token.content : "no-csrf-token";

const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

interface Auth0ProviderProps {
  onRedirectCallback?: (appState: AppState) => void;
  children: React.ReactNode;
  initOptions?: Auth0ClientOptions;
}

export const AuthContext = React.createContext<ContextValue>(
  {} as ContextValue
);
export const useAuth = (): ContextValue => {
  const result = useContext(AuthContext);
  if (!result) {
    throw new Error(
      "components using useAuth must be wrapped in a AuthProvider at some point"
    );
  }

  return result;
};

export const AuthProvider = ({
  children,
  onRedirectCallback,
  ...initOptions
}: Auth0ProviderProps): ReactNode => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [axios, setAxios] = useState(null);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        if (onRedirectCallback) {
          onRedirectCallback(appState);
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const token = await auth0FromHook.getTokenSilently();
        setAxios(() => createAxiosInstance(token, auth0FromHook));
      }
      setLoading(false);
    };

    initAuth0();
  }, []);

  const createAxiosInstance = (token: string, auth0FromHook: Auth0Client) => {
    const axiosInstance = Axios.create({
      headers: {
        common: {
          "X-CSRF-Token": content,
        },
        Authorization: `Bearer ${token}`,
      },
    });

    axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response) {
          if (error.response.status === 401) {
            auth0FromHook.logout();
          }
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const token = await auth0Client.getTokenSilently();
    setAxios(() => createAxiosInstance(token));
    setLoading(false);
    setIsAuthenticated(true);
  };

  if (loading) {
    const rejectPromise = () => Promise.reject("In loading state");

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated,
          loading: true,
          handleRedirectCallback: rejectPromise,
          loginWithRedirect: rejectPromise,
          getTokenSilently: rejectPromise,
          logout: rejectPromise,
        }}
      ></AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        axios,
        loading,
        handleRedirectCallback,
        loginWithRedirect: () => auth0Client.loginWithRedirect(),
        getTokenSilently: () => auth0Client.getTokenSilently(),
        logout: () => auth0Client.logout(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
  onRedirectCallback: PropTypes.func,
};

AuthProvider.defaultProps = {
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
};
