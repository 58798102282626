import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import { FieldWithColorOptions } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

const FormEditor: FC = () => (
  <Group>
    <GroupHeader text="Form" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.form.border_color"
            label="Border color"
            showDefaultOption={true}
          />
          <FieldWithColorOptions
            id="style.form.caption_color"
            label="Caption font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Error state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.form.error.color"
            label="Font color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default FormEditor;
