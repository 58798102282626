import React, { FC } from "react";

type InputFieldProps = {
  useNewStyle?: boolean;
  label?: string;
  id?: string;
  testId?: string;
  placeholder: string;
  value: string | number | undefined;
  className?: string;
  min?: string | number;
  max?: string | number;
  type?: "text" | "number";
  handleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

const InputField: FC<InputFieldProps> = ({
  useNewStyle = false,
  label,
  id,
  testId,
  placeholder,
  value,
  className,
  min,
  max,
  type = "text",
  handleInputChange,
  disabled,
}) => {
  const oldLabelStyle = "block text-sm font-medium text-gray-700";
  const newLabelStyle = "text-text block text-sm font-medium";
  const oldInputStyle =
    "p-2 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm shadow-sm border border-gray-200 rounded-md disabled:bg-gray-100";
  const newInputStyle =
    "mt-2 focus-outline-none focus:ring-0 bg-light-surfacePrimary dark:bg-dark-surfacePrimary text-text h-10 p-2 block w-full sm:text-sm shadow-sm border-none rounded-md";

  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={useNewStyle ? newLabelStyle : oldLabelStyle}
      >
        {label}
      </label>
      <input
        autoComplete="off"
        id={id}
        data-testid={testId}
        className={useNewStyle ? newInputStyle : oldInputStyle}
        onChange={handleInputChange}
        type={type}
        value={value}
        min={min}
        max={max}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={disabled}
      />
    </div>
  );
};

export default InputField;
