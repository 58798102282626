import React, { FC, createContext, useEffect, useState } from "react";

const themeValues = ["light", "dark"];

const isThemeValue = (preference: string): boolean =>
  themeValues.includes(preference);

const getInitialTheme = () => {
  if (window && typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("bp-cms-theme-preference");

    if (storedPrefs && isThemeValue(storedPrefs)) {
      return storedPrefs;
    }

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    }
  }

  return "light";
};

export const ThemeContext = createContext({
  theme: "light",
  setTheme: (value: string) =>
    console.error(
      `No setTheme function provided to set ${value} as the prefered theme`
    ),
});

type Props = {
  children: React.ReactNode;
};

export const ThemeProvider: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme);

  const setThemePreference = (themePreference: string) => {
    const root = window.document.documentElement;

    root.classList.remove(themePreference === "dark" ? "light" : "dark");
    root.classList.add(themePreference);
    localStorage.setItem("bp-cms-theme-preference", themePreference);

    document.body.style.backgroundColor = "rgb(var(--surface-primary))";
  };

  useEffect(() => {
    setThemePreference(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
