import React, { ChangeEvent, FC } from "react";

import { GoSearch } from "react-icons/go";

type Props = {
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const SearchBar: FC<Props> = ({ placeholder, handleChange }) => (
  <div className="relative flex items-center w-full h-12 text-opacity-50 text-text dark:text-opacity-50">
    <GoSearch className="absolute left-0 z-20 block w-4 h-4 ml-4 pointer-events-none" />
    <input
      type="text"
      className="block w-full h-full py-1.5 pl-10 pr-4 rounded-full border-0 focus:outline-none focus:ring-transparent bg-light-backgroundSecondary dark:bg-dark-backgroundSecondary"
      onChange={handleChange}
      placeholder={placeholder}
    />
  </div>
);

export default SearchBar;
