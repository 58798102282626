import React, { FC } from "react";

import { findItemById } from "helpers/ArrayHelper";
import { EmailTemplate, PaypalRewardSettings, RewardOption } from "interfaces";

type PaypalRewardOptionProps = {
  rewardOption: RewardOption<PaypalRewardSettings>;
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
};

const PaypalRewardOption: FC<PaypalRewardOptionProps> = ({
  rewardOption,
  emailTemplates,
  manualRewardEmailTemplates,
}) => {
  const rewardValue = rewardOption.settings.reward_cents / 100;
  const emailTemplate = findItemById(
    emailTemplates,
    rewardOption.settings.email_template_id
  );

  const manualEmailTemplate = findItemById(
    manualRewardEmailTemplates,
    rewardOption.settings.manual_email_template_id
  );

  return (
    <div className="flex-grow">
      <div>
        <span>Option identifier: </span>
        {rewardOption.key}
      </div>
      <div>
        <span>Subtype: </span>
        {rewardOption.reward_class}
      </div>
      <div>
        <span>Value: </span>
        <span className="uppercase">
          {rewardValue} {rewardOption.settings.reward_currency}
        </span>
      </div>
      <div>
        <span>Reward email template: </span>
        {emailTemplate ? emailTemplate.name : "Not set"}
      </div>
      <div>
        <span>Manual Reward email template: </span>
        {manualEmailTemplate ? manualEmailTemplate.name : "Not set"}
      </div>
    </div>
  );
};

export default PaypalRewardOption;
