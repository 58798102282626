import React, { FC, ReactNode, useState } from "react";

import { ArrowDownIcon, ArrowUpIcon } from "components/Icon";
import Transition from "components/Transition";

export type AccordionProps = {
  label: string;
  isExpandedByDefault: boolean;
  children: ReactNode;
};

export const Accordion: FC<AccordionProps> = ({
  label,
  isExpandedByDefault,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedByDefault);

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsExpanded((isExpanded) => !isExpanded);
  };

  return (
    <div className="flex flex-col bg-backgroundPrimary py-7.5 px-5 rounded-xl">
      <div className="flex flex-row">
        <button
          onClick={onClick}
          className="flex flex-grow justify-between items-center focus:outline-none focus:ring-0 text-color"
          aria-label={
            isExpanded
              ? `Collapse ${label} accordion`
              : `Expand ${label} accordion`
          }
        >
          <h2 className="font-medium text-color text-2xl">{label}</h2>
          {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
      </div>
      <Transition showChildren={isExpanded}>
        <div className="flex flex-col gap-y-6 mt-10">{children}</div>
      </Transition>
    </div>
  );
};
