import { useContext } from "react";

import {
  WrapperSiteEditorContext,
  WrapperSiteEditorState,
} from "context/WrapperSiteEditorContext";

const useWrapperSiteEditor = (): WrapperSiteEditorState => {
  const { reloadDomain, setReloadDomain, domainConfig, setDomainConfig } =
    useContext(WrapperSiteEditorContext);

  return { reloadDomain, setReloadDomain, domainConfig, setDomainConfig };
};

export default useWrapperSiteEditor;
