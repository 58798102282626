import React, { FC, createContext, useState } from "react";

import { BreadcrumbItem } from "interfaces";

export type BreadcrumbState = {
  breadcrumbItems: BreadcrumbItem[];
  setBreadcrumbItems: (state: BreadcrumbItem[]) => void;
};

export const BreadcrumbContext = createContext<BreadcrumbState>({
  breadcrumbItems: [],
  setBreadcrumbItems: () => console.warn("default context"),
});

export const BreadcrumbProvider: FC = ({ children }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbItems,
        setBreadcrumbItems,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
