import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { FlowId, RewardType } from "interfaces";

import { useAuth } from "components/AuthProvider";
import RewardTypesTable from "components/CampaignConfigurationEditor/RewardManagementEditor/RewardTypesTable/RewardTypesTable";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type RewardTypesListProps = {
  flowId: FlowId;
};

const RewardTypesList: FC<RewardTypesListProps> = ({ flowId }) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!axios || !selectedMarket || !selectedCampaign) {
    throw new Error("Context isn't set correctly");
  }

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [configurableRewardTypes, setConfigurableRewardTypes] = useState<
    RewardType[]
  >([]);

  const loadRewardTypes = async (source: CancelTokenSource) => {
    try {
      const {
        data: { reward_types },
      } = await axios.get("/api/reward_types", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          flow: flowId,
        },
        cancelToken: source.token,
      });

      setConfigurableRewardTypes(reward_types);
      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setLoading(false);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadRewardTypes(source);

    return () => source && source.cancel();
  }, []);

  return (
    <>
      <p className="text-2xl font-semibold">Rewards list</p>
      <p className="text-sm">
        As defined in Co-Buying backend under the Campaign {">>"} Rewards,
        incentives &amp; leaderboards
      </p>

      {error && <ErrorAlert message={error} />}
      {loading && <Loader />}
      {!error && !loading && (
        <RewardTypesTable flowId={flowId} items={configurableRewardTypes} />
      )}
    </>
  );
};

export default RewardTypesList;
