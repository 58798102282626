import React from "react";

import { BreadcrumbProvider, HeaderDrawerProvider } from "context";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import CampaignRouter from "components/CampaignRouter";
import CampaignSelector from "components/CampaignSelector/CampaignSelector";
import Header from "components/Header";
import MarketConfiguration from "components/MarketConfiguration/MarketConfiguration";
import ThemeToggle from "components/ThemeToggle";
import PrivateRoute from "components/routers/PrivateRoute";

import { SelectedCampaignConfigurationProvider } from "context/SelectedCampaignConfigurationContext";
import { ThemeProvider } from "context/ThemeContext";

const App = (): React.ReactElement => (
  <BrowserRouter>
    <ThemeProvider>
      <div className="bg-surfacePrimary min-h-screen flex flex-col">
        <SelectedCampaignConfigurationProvider>
          <HeaderDrawerProvider>
            <BreadcrumbProvider>
              <Header />
              <Switch>
                <PrivateRoute exact path="/" component={CampaignSelector} />
                <PrivateRoute
                  path="/:market_subdomain/edit"
                  component={MarketConfiguration}
                />
                <PrivateRoute
                  path="/:market_subdomain/:campaign_slug"
                  component={CampaignRouter}
                />
                <Route render={() => <Redirect to="/" />} />
              </Switch>
            </BreadcrumbProvider>
          </HeaderDrawerProvider>
        </SelectedCampaignConfigurationProvider>
        <ThemeToggle />
        <Toaster
          toastOptions={{
            style: {
              minWidth: "min-content",
            },
          }}
        />
      </div>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
