import React, { FC, useState } from "react";

import { Campaign } from "interfaces";
import { useFormContext } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { Fieldset } from "components/Fieldset/Fieldset";
import { Switch } from "components/Switch/Switch";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";

import ControlRenderer from "./Renderers/ControlRenderer";
import labels from "./display-schema.json";

// TODO: specify types instead of using any
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getDisplaySettings = (path: string): any => {
  const paths = [...path.split(".")];

  return paths.reduce((response, current) => {
    if (!response) return undefined;

    return response[current] || response["locale"];
  }, labels as any); /* eslint-disable-line @typescript-eslint/no-explicit-any */
};

const SchemaRenderer: FC<{
  schema: {
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
  flowId: string;
  journeyId: string;
  section: string;
  locale: string;
  includeShared?: boolean;
}> = ({ schema, flowId, journeyId, locale, section, includeShared = true }) => {
  const [selectedCampaign] = useSelectedCampaignContext();

  const { unregister, getValues, setValue, register } = useFormContext();

  const [propToggleState, setPropToggleState] = useState<{
    [key: string]: boolean;
  }>({});
  const [propToggleValues, setPropToggleValues] = useState<{
    [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }>({});

  const toggleProp = (
    path: string,
    currentState: boolean,
    paths: { path: string; removeKey: boolean }[]
  ) => {
    setPropToggleState({
      ...propToggleState,
      [path]: !currentState,
    });

    // if hiding, null the paths contained within
    if (currentState) {
      // reduce the paths list to call getValues for each path and store in an object with the path as the key
      setPropToggleValues({
        ...propToggleValues,
        ...paths.reduce(
          (response, current) => {
            response[current.path] = getValues(current.path);
            return response;
          },
          {} as Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
        ),
      });

      paths.forEach((f) =>
        f.removeKey ? unregister(f.path) : setValue(f.path, null)
      );
    } else {
      // restore any stored values
      paths.forEach((f) => setValue(f.path, propToggleValues[f.path]));
    }
  };

  const output: JSX.Element[] = [];

  const checkDisplayConditions = (
    displayConditions: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    selectedCampaign: Campaign | null
  ) => {
    if (!displayConditions) return true;

    const options = Object.keys(displayConditions);

    if (options.length === 0) return true;

    for (const option of options) {
      switch (option) {
        case "program_type":
          if (!selectedCampaign?.program_type) break;
          if (selectedCampaign?.program_type !== displayConditions[option])
            return false;
          break;
        default:
          break;
      }
    }

    return true;
  };

  const renderProps = (
    props: {
      [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    },
    fieldPath: string,
    isGroup?: boolean,
    isDisabled?: boolean,
    groups?: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
  ): JSX.Element[] => {
    const results: JSX.Element[] = [];

    if (!groups) groups = [];

    for (const prop in props) {
      const property = props[prop];
      const propPath = `${fieldPath}.${prop}`;

      const displaySettings = getDisplaySettings(propPath);

      // try and resolve a friendly label for this path
      const label = displaySettings?._label || prop;

      const propType = Array.isArray(property) ? "array" : property.type;

      // check for any conditional display settings that are present
      if (
        !checkDisplayConditions(
          displaySettings?._display_conditions,
          selectedCampaign
        )
      )
        continue;

      if (!propType) {
        // if no type this is a sub-group of properties
        const content = renderProps(
          property,
          propPath,
          true,
          isDisabled,
          groups
        );

        // if it's a group, but only container one item then add the fields at the root
        if (isGroup && content.length === 1 && !displaySettings?._group) {
          content.forEach((f) => results.push(f));
        } else if (isGroup) {
          if (content.length > 0) {
            const isSelected =
              propToggleState[propPath] ||
              (propToggleState[propPath] === undefined && getValues(propPath));

            const toggle = property.key_optional ? (
              <Switch
                key={`prop-toggle-${propPath}`}
                isSelected={!!isSelected}
                isDisabled={isDisabled}
                {...register(propPath)}
                onChange={() =>
                  toggleProp(propPath, isSelected, [
                    { path: propPath, removeKey: property.key_optional },
                  ])
                }
              >
                {displaySettings?._toggle || "Toggle"}
              </Switch>
            ) : null;

            const showContent = toggle ? isSelected : true;

            results.push(
              <Fieldset legend={label} key={`fieldset-${propPath}`}>
                {toggle}
                {showContent && content}
              </Fieldset>
            );
          }
        } else {
          // not a group then wrap in an accordion
          if (content.length > 0) {
            results.push(
              <Accordion
                label={label}
                isExpandedByDefault={false}
                key={`group-${propPath}`}
              >
                {content}
              </Accordion>
            );
          }
        }
      } else {
        // property has a type so render it
        let hasToggle = false;
        let group: any; // eslint-disable-line @typescript-eslint/no-explicit-any

        // check whether this is in a display group (defined in the display-schema.json)
        if (displaySettings?._group && displaySettings?._group !== true) {
          const groupPath = displaySettings._group;
          const groupSettings = getDisplaySettings(groupPath);

          // see if this group has already been created
          group = groups.find((f) => f.key === groupPath);

          if (!group) {
            const paths =
              groupSettings?._paths?.map((m: string) => ({ path: m })) || [];

            // no group so create one
            group = {
              key: groupPath,
              paths,
              contents: [],
            };

            // save to the groups global so we can add stuff to it later
            // but inside the recursion tree
            groups.push(group);

            // add a toggle to the group if we need one, for display groups the presence of a _toggle
            // property means we need one
            const isSelected =
              propToggleState[groupPath] ||
              (propToggleState[groupPath] === undefined &&
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                group.paths.some((a: any) => getValues(a.path)));

            const groupToggle = groupSettings?._toggle ? (
              <Switch
                key={`prop-toggle-${groupPath}`}
                isSelected={isSelected}
                onChange={() => toggleProp(groupPath, isSelected, group.paths)}
                isDisabled={isDisabled}
              >
                {groupSettings?._toggle || "Toggle"}
              </Switch>
            ) : null;

            // tell the props rendering later if they are in a toggle or not (unregister)
            hasToggle = hasToggle || !!groupToggle;

            // if the renderProps call doesn't have the isGroup keyword but it's a group, we're in the unusual case of
            // a set of schema properties that are not structured in a tree but need to be displayed in an
            // accordion.  But if it isGroup, we know it's a structural grouping and create a FieldSet
            results.push(
              !isGroup ? (
                <Accordion
                  key={groupPath}
                  label={groupSettings._label}
                  isExpandedByDefault={false}
                >
                  {groupToggle}
                  {!groupToggle || isSelected ? group.contents : null}
                </Accordion>
              ) : (
                <Fieldset legend={groupSettings?._label} key={groupPath}>
                  {groupToggle}
                  {/*display the fields if no toggle or toggle selected*/}
                  {!groupToggle || isSelected ? group.contents : null}
                </Fieldset>
              )
            );
          } else {
            hasToggle = hasToggle || !!groupSettings?._toggle;
          }
        }

        // check if the toggle is activated or not
        const isSelected =
          propToggleState[propPath] ||
          (propToggleState[propPath] === undefined && getValues(propPath));

        // if we have a toggle then add it
        const toggle = property.key_optional ? (
          <Switch
            key={`prop-toggle-${propPath}`}
            isSelected={!!isSelected}
            isDisabled={isDisabled}
            onChange={() =>
              toggleProp(propPath, isSelected, [
                { path: propPath, removeKey: property.key_optional },
              ])
            }
          >
            {displaySettings?._toggle || "Toggle"}
          </Switch>
        ) : null;

        // update our toggle flag
        hasToggle = hasToggle || !!toggle;

        // get the form control content to display
        const content = (
          <ControlRenderer
            key={`render-${propPath}`}
            flowId={flowId}
            propType={propType}
            propPath={propPath}
            isGroup={isGroup}
            toggle={toggle}
            label={label}
            property={property}
            isDisabled={isDisabled}
            displaySettings={displaySettings}
            fieldPath={fieldPath}
            prop={prop}
          />
        );

        // container for assembling the bits and pieces together
        const displayContent = [];

        if (toggle) displayContent.push(toggle);

        // check if we're a toggle and if we're selected
        if (content && (!toggle || isSelected)) displayContent.push(content);

        if (!isGroup) {
          if (!group) {
            results.push(
              <Accordion
                label={label}
                isExpandedByDefault={false}
                key={`accordion-${propPath}`}
              >
                {displayContent}
              </Accordion>
            );
          } else {
            // we're in display group without a schema hierarchy:
            displayContent.forEach((f) => group.contents.push(f));
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            const groupPath = group.paths.find((f: any) => f.path === propPath);

            if (groupPath) {
              groupPath.removeKey = !!property.key_optional;
            } else {
              group.paths.push({
                path: propPath,
                removeKey: !!property.key_optional,
              });
            }
          }
        } else {
          // check if we're in a display group
          if (group) {
            // add the controls to the existing group
            displayContent.forEach((f) => group.contents.push(f));

            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            const groupPath = group.paths.find((f: any) => f.path === propPath);
            if (groupPath) {
              groupPath.removeKey = !!property.key_optional;
            } else {
              group.paths.push({
                path: propPath,
                removeKey: !!property.key_optional,
              });
            }
          } else {
            // just push the controls directly
            displayContent.forEach((f) => results.push(f));
          }
        }
      }
    }

    return results;
  };

  const flow = schema[flowId];

  if (flow) {
    // top level is flows (ie lookup_user_) and there can be a journeyless flow:
    const journey = journeyId ? flow[journeyId] : flow;

    const path = `${flowId}${journeyId ? `.${journeyId}` : ""}.${section}${locale ? `.${locale}` : ""}`;

    const configSection = journey[section];
    const content = locale ? configSection.locale : configSection;
    const shared = flow.shared?.content?.locale;

    // shared settings first
    if (includeShared && shared) {
      output.push(
        <Accordion
          label="Shared fields"
          isExpandedByDefault={false}
          key="group-shared-fields"
        >
          {renderProps(
            shared,
            `${flowId}.shared.content.${locale}`,
            false,
            true
          )}
        </Accordion>
      );
    }

    // call out to the recursive property renderer
    renderProps(content, path).forEach((f) => output.push(f));
  }

  return output;
};
export default SchemaRenderer;
