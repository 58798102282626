import React, { FC } from "react";

type ErrorToastProps = {
  error: {
    response?: {
      status: number;
      data: {
        payload: {
          error: string;
          errors: {
            value: [];
          };
        };
      };
    };
  };
};

const ErrorToast: FC<ErrorToastProps> = ({ error }) => {
  let title;
  let body = null;

  if (error.response && error.response.status === 400) {
    title = "Error when saving, please review the following errors:";

    if (!!error.response.data.payload.error) {
      body = (
        <ul className="list-disc pl-10">
          <li>{error.response.data.payload.error}</li>
        </ul>
      );
    } else {
      body = (
        <ul className="list-disc pl-10">
          {error.response.data.payload.errors.value.map(
            (err: string, index: number) => (
              <li key={index}>{err}</li>
            )
          )}
        </ul>
      );
    }
  } else {
    title = `Error: ${error.message}`;
  }

  return (
    <div>
      <div>{title}</div>
      {body}
    </div>
  );
};

export default ErrorToast;
