import React, { FC } from "react";

import cn from "classnames";
import useSelectedPreviewDevice from "hooks/useSelectedPreviewDevice";

const Container: FC = ({ children }) => {
  const { selectedPreviewDevice } = useSelectedPreviewDevice();

  return (
    <div
      className={cn(
        "flex flex-grow flex-col text-text items-end mt-8 mx-auto",
        {
          "w-93.75 mb-16": selectedPreviewDevice === "mobile",
        }
      )}
    >
      {children}
    </div>
  );
};

export default Container;
