import React, { FC, ReactNode } from "react";

export type ListContainerProps = {
  header: string;
  children: ReactNode;
};

const ListContainer: FC<ListContainerProps> = ({ header, children }) => (
  <div className="w-full md:w-1/2 px-7 pt-7 pb-5 bg-backgroundPrimary rounded-xl">
    <div className="text-left text-2xl font-medium pb-5 text-text">
      {header}
    </div>
    <ul className="text-text text-opacity-90 max-h-96 md:h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-surfaceSecondary scrollbar-track-surfacePrimary scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
      {children}
    </ul>
  </div>
);

export default ListContainer;
