import { createContext } from "react";

import { EligibilityCheck } from "interfaces";

export type FormHelpersContextType = {
  campaign_configurations?: {
    referrer_journey: {
      eligibility_check: EligibilityCheck[];
    };
    friend_journey: {
      eligibility_check: EligibilityCheck[];
    };
  };
};

export const FormHelpersContext = createContext<FormHelpersContextType>({});
