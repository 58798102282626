import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  CreatableSelectField,
  FieldWithColorOptions,
  FieldWithFontSizeOptions,
  FieldWithFontWeightOptions,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

export type TitleEditorProps = {
  customFonts: { value: string; label: string }[];
};

const TitleEditor: FC<TitleEditorProps> = ({ customFonts }) => (
  <Group>
    <GroupHeader text="Title" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <CreatableSelectField
            fieldId="style.title.font_family"
            label="Font family"
            options={customFonts}
          />
          <FieldWithFontSizeOptions
            id="style.title.font_size"
            label="Font size"
          />
          <FieldWithFontWeightOptions
            id="style.title.font_weight"
            label="Font weight"
          />
          <FieldWithColorOptions
            id="style.title.color"
            label="Font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default TitleEditor;
