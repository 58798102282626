import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";

import { NurtureConfigTypes } from "constants/NurtureConfigsConstant";

import { useAuth } from "components/AuthProvider";
import ErrorAlert from "components/ErrorAlert";
import Loader from "components/Loader";
import NurtureListItem from "components/Nurture/NurtureList/NurtureListItem/NurtureListItem";
import { NurtureConfig } from "components/Nurture/interfaces";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const NurtureList: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [nurtureConfigs, setNurtureConfigs] = useState<NurtureConfig[]>([]);

  const getNurtureConfigurations = async (source: CancelTokenSource) => {
    if (!selectedMarket || !selectedCampaign || !axios) {
      return <ErrorAlert message="Context isn't set correctly" />;
    }

    try {
      const { data } = await axios.get("/api/nurture_configs", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setNurtureConfigs(data.nurture_configs);
      setLoading(false);
    } catch (e) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getNurtureConfigurations(source);

    return () => source && source.cancel();
  }, []);

  const nurtureConfigByType = (type: string) => {
    return nurtureConfigs?.find((t) => t.type == type);
  };

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="my-10 p-7 rounded-xl shadow bg-backgroundPrimary text-text">
      <h2 className="pb-5 text-xl font-medium">Nurture sequences</h2>
      <ul>
        {NurtureConfigTypes.map((nurtureConfigType) => (
          <NurtureListItem
            key={nurtureConfigType.type}
            nurtureConfig={nurtureConfigByType(nurtureConfigType.type)}
            type={nurtureConfigType.type}
            label={nurtureConfigType.label}
          />
        ))}
      </ul>
    </div>
  );
};

export default NurtureList;
