import React, { FC } from "react";

import { CreatableSelectField } from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

type FieldWithFontSizeOptionsProps = {
  id: string;
  label: string;
};

const fontSizes = [
  "extra_small",
  "small",
  "medium",
  "large",
  "extra_large",
].map((value) => ({
  label: value,
  value: value,
}));

const FieldWithFontSizeOptions: FC<FieldWithFontSizeOptionsProps> = ({
  id,
  label,
}) => {
  return (
    <CreatableSelectField options={fontSizes} fieldId={id} label={label} />
  );
};

export default FieldWithFontSizeOptions;
