import React, { FC } from "react";

import {
  findDropdownItemByValue,
  itemsToDropdowItems,
} from "helpers/DropdownHelper";
import { OptionTypeBase, VoucherPool } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import Dropdown from "components/Dropdown/Dropdown";

type VoucherSubtypeFieldsProps = {
  voucherPools: VoucherPool[];
  emailTemplateDropdownItems: OptionTypeBase[];
  manualEmailTemplateDropdownItems: OptionTypeBase[];
};

const VoucherSubtypeFields: FC<VoucherSubtypeFieldsProps> = ({
  voucherPools,
  emailTemplateDropdownItems,
  manualEmailTemplateDropdownItems,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const selectedEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(emailTemplateDropdownItems, value);

  const voucherPoolDropdownItems = itemsToDropdowItems(voucherPools);
  const selectedVoucherPoolItem = (value: string) =>
    findDropdownItemByValue(voucherPoolDropdownItems, value);

  const selectedManualEmailTemplateItem = (value: string) =>
    findDropdownItemByValue(manualEmailTemplateDropdownItems, value);

  return (
    <>
      <Controller
        control={control}
        name={"settings.email_template_id"}
        rules={{ required: { value: true, message: "Required" } }}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"email_template_id"}
            value={selectedEmailTemplateItem(value)}
            items={emailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item.value)}
            label="Reward email template"
            validationState={
              errors?.settings?.email_template_id ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.email_template_id?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.voucher_pool_id"}
        rules={{ required: { value: true, message: "Required" } }}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"voucher_pool_id"}
            value={selectedVoucherPoolItem(value)}
            items={voucherPoolDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item.value)}
            label="Voucher pool"
            validationState={
              errors?.settings?.voucher_pool_id ? "invalid" : "valid"
            }
            errorMessage={errors?.settings?.voucher_pool_id?.message}
          />
        )}
      />
      <Controller
        control={control}
        name={"settings.manual_email_template_id"}
        shouldUnregister={true}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            id={"manual_email_template_id"}
            value={selectedManualEmailTemplateItem(value)}
            items={manualEmailTemplateDropdownItems}
            onChange={(item: OptionTypeBase) => onChange(item?.value || null)}
            label="Manual Reward email template"
            isClearable={true}
          />
        )}
      />
    </>
  );
};

export default VoucherSubtypeFields;
