import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  CreatableSelectField,
  FieldWithColorOptions,
  FieldWithFontSizeOptions,
  FieldWithFontWeightOptions,
  InputField,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

export type ButtonEditorProps = {
  customFonts: { value: string; label: string }[];
};

const ButtonEditor: FC<ButtonEditorProps> = ({ customFonts }) => (
  <Group>
    <GroupHeader text="Button" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <CreatableSelectField
            fieldId="style.button.font_family"
            label="Font family"
            options={customFonts}
          />
          <FieldWithFontSizeOptions
            id="style.button.font_size"
            label="Font size"
          />
          <FieldWithFontWeightOptions
            id="style.button.font_weight"
            label="Font weight"
          />
          <InputField id="style.button.height" label="Height" />
          <InputField id="style.button.border_radius" label="Border radius" />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Primary"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.button.primary.background_color"
            label="Background color"
            showDefaultOption={false}
          />
          <FieldWithColorOptions
            id="style.button.primary.color"
            label="Font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Secondary"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.button.secondary.background_color"
            label="Background color"
            showDefaultOption={true}
          />
          <FieldWithColorOptions
            id="style.button.secondary.color"
            label="Font color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default ButtonEditor;
