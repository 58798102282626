import React, { Dispatch, FC, SetStateAction } from "react";

import Button from "components/Button";
import ModalContainer from "components/ModalContainer/ModalContainer";

type MigrateConfirmationModalProps = {
  setShow: Dispatch<SetStateAction<boolean>>;
  handleConfirm: () => void;
};

const MigrateConfirmationModal: FC<MigrateConfirmationModalProps> = ({
  setShow,
  handleConfirm,
}) => {
  const onConfirm = () => {
    setShow(false);
    handleConfirm();
  };

  return (
    <ModalContainer
      showModal={true}
      setShowModal={setShow}
      title="Confirmation"
    >
      <span className="flex text-text mb-5">
        Before migrating this wrapper site, you must configure the wrapper site
        and publish that configuration. Are you sure you have done this and are
        ready to migrate?
      </span>
      <div className="flex justify-end">
        <div className="w-20">
          <Button
            testId="modal-cancel"
            appearance="danger"
            handleOnClick={() => setShow(false)}
            text="Cancel"
            role="button"
          />
        </div>
        <div className="w-40 ml-4">
          <Button
            testId="modal-confirm"
            appearance="primary"
            handleOnClick={onConfirm}
            text="Migrate"
            role="button"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default MigrateConfirmationModal;
