import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import { Market, Product, RunaRewardOption } from "interfaces";

import { useAuth } from "components/AuthProvider";
import ErrorAlert from "components/ErrorAlert";
import RewardOptionCreator from "components/MarketConfiguration/RewardOptionCreator/RewardOptionCreator";
import RewardOptionList from "components/MarketConfiguration/RewardOptionList/RewardOptionList";

type RewardOptionsProps = {
  market: Market;
};

const RewardOptions: FC<RewardOptionsProps> = ({ market }) => {
  const { axios } = useAuth();
  const [products, setProducts] = useState<Product[]>();
  const [marketRewardOptions, setMarketRewardOptions] = useState<
    RunaRewardOption[]
  >([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [reloadData, setReloadData] = useState<boolean>(true);

  if (!axios) {
    throw new Error("Context isn't set correctly");
  }

  const loadProducts = (source: CancelTokenSource) =>
    axios.get(`/api/markets/${market.id}/products`, {
      params: {
        region_key: market.region_key,
      },
      cancelToken: source.token,
    });

  const loadMarketRewardOptions = (source: CancelTokenSource) =>
    axios.get(`/api/markets/${market.id}/reward_options`, {
      params: {
        region_key: market.region_key,
      },
      cancelToken: source.token,
    });

  const loadData = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { products },
        },
        {
          data: { reward_options },
        },
      ] = await Promise.all([
        loadProducts(source),
        loadMarketRewardOptions(source),
      ]);

      setProducts(products);
      setMarketRewardOptions(reward_options);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadData(source);

    return () => source.cancel();
  }, [reloadData]);

  if (loading) return null;
  if (error) return <ErrorAlert message={error} />;
  if (!products) return <ErrorAlert message={"No products found"} />;

  return (
    <div className="flex flex-col p-7.5 space-y-7.5 bg-backgroundPrimary text-text rounded-xl">
      <p className="text-2xl font-semibold">Market reward options</p>
      <div className="flex flex-row space-x-10">
        <RewardOptionCreator
          id="reward-option-creator"
          rewardType="Create reward options"
          products={products}
          market={market}
          onSave={() => setReloadData(!reloadData)}
        />

        <RewardOptionList
          market={market}
          rewardOptions={marketRewardOptions}
          onDelete={() => setReloadData(!reloadData)}
        />
      </div>
    </div>
  );
};

export default RewardOptions;
