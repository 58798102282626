import React, { FC } from "react";

import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ControlProps, GroupBase, InputProps, components } from "react-select";
import CreatableSelect from "react-select/creatable";

import {
  FieldLabel,
  FieldWrapper,
  HexPreview,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";
import {
  customStyles,
  theme,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields/utils";

type OptionType = { value: string; label: string };

type CreatableSelectFieldProps = {
  options: OptionType[];
  fieldId: string;
  label: string;
};

const Control: FC<ControlProps<unknown, boolean, GroupBase<unknown>>> = ({
  children,
  ...props
}) => {
  if (!props.selectProps.fieldId) return null;
  const value = useWatch({ name: props.selectProps.fieldId });
  const valueIsHexColor = value.match(/^#[A-Fa-f0-9]{6}$/);

  return (
    <components.Control {...props}>
      {valueIsHexColor && <HexPreview id={props.selectProps.fieldId} />}
      {children}
    </components.Control>
  );
};

const Input = (
  props: JSX.IntrinsicAttributes &
    InputProps<unknown, boolean, GroupBase<unknown>>
) => <components.Input {...props} data-lpignore="true" />;

const CreatableSelectField: FC<CreatableSelectFieldProps> = (props) => {
  const { options, fieldId, label } = props;
  const { getValues, control } = useFormContext();

  return (
    <FieldWrapper>
      <FieldLabel text={label} />
      <div className="w-fit">
        <Controller
          control={control}
          name={fieldId}
          render={({ field: { onChange } }) => (
            <CreatableSelect
              {...props}
              name={fieldId}
              aria-label={label}
              isClearable={false}
              theme={theme}
              styles={customStyles}
              components={{ Input, Control }}
              options={options}
              defaultValue={{
                value: getValues(fieldId),
                label: getValues(fieldId),
              }}
              formatCreateLabel={(inputValue) => {
                return `Set value to: ${inputValue}`;
              }}
              placeholder="Select or enter a value"
              onChange={(e: OptionType) => onChange(e.value)}
            />
          )}
        />
      </div>
    </FieldWrapper>
  );
};

export default CreatableSelectField;
