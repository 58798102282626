import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import {
  CustomField,
  EligibilityCheck,
  EmailTemplate,
  OptionTypeBase,
} from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import { useAuth } from "components/AuthProvider";
import Dropdown from "components/Dropdown/Dropdown";
import Loader from "components/Loader";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

type SchemaEmailTemplateEditorProps = {
  fieldPath: string;
  flowId: string;
  emailTemplateType: string;
};

const SchemaEmailTemplateEditor: FC<SchemaEmailTemplateEditorProps> = ({
  fieldPath,
  flowId,
  emailTemplateType,
}) => {
  const { axios } = useAuth();

  const { control, getValues } = useFormContext();

  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  const [, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (emailTemplateType) {
      const source = Axios.CancelToken.source();
      loadEmailTemplates(source);

      return () => source.cancel();
    }
  }, [emailTemplateType]);

  const [configurableEmailTemplates, setConfigurableEmailTemplates] = useState<
    EmailTemplate[]
  >([]);

  const configurableEmailTemplatesRequest = (
    source: CancelTokenSource,
    type: string
  ) => {
    return axios.get("/api/email_templates", {
      params: {
        region_key: selectedMarket?.region_key,
        campaign_id: selectedCampaign?.id,
        flow: flowId,
        type: type,
      },
      cancelToken: source.token,
    });
  };

  const loadEmailTemplates = async (source: CancelTokenSource) => {
    try {
      const [
        {
          data: { email_templates },
        },
      ] = await Promise.all([
        configurableEmailTemplatesRequest(source, emailTemplateType),
      ]);

      setConfigurableEmailTemplates(email_templates);

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
        setLoading(false);
      }
    }
  };

  const configurableToDropdowItems = (
    configurables: EmailTemplate[] | CustomField[] | EligibilityCheck[]
  ) =>
    configurables.map(
      (configurable: EmailTemplate | CustomField | EligibilityCheck) => ({
        label: configurable.name,
        value: configurable.id,
      })
    );

  const emailTemplateDropdownItems = configurableToDropdowItems(
    configurableEmailTemplates
  );
  const selectedEmailTemplateDropdownItem =
    emailTemplateDropdownItems.find(
      (configurableEmailTemplate) =>
        configurableEmailTemplate.value === getValues(fieldPath)
    ) || null;

  if (loading) return <Loader />;

  return (
    <Controller
      control={control}
      key={fieldPath}
      name={fieldPath}
      render={({ field: { onChange } }) => (
        <Dropdown
          id={fieldPath}
          value={selectedEmailTemplateDropdownItem}
          items={emailTemplateDropdownItems}
          onChange={(e) => onChange((e as OptionTypeBase).value)}
          label={"Select an email template"}
        />
      )}
    />
  );
};

export default SchemaEmailTemplateEditor;
