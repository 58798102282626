import React, { useEffect, useState } from "react";

import { capitalize } from "helpers/StringHelper";
import {
  EditorComponent,
  RewardOption,
  RewardOptionSettings,
  RewardType,
} from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { useAuth } from "components/AuthProvider";
import { Radio, RadioGroup } from "components/Radio";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import OptionSelectorEditorWrapper from "../OptionSelectorEditorWrapper/OptionSelectorEditorWrapper";
import RewardOptionContentEditor from "./RewardOptionContentEditor";

const OptionSelectorContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!axios || !selectedMarket || !selectedCampaign) {
    throw new Error("Context isn't set correctly");
  }

  const { getValues, control, unregister } = useFormContext();

  const contentPath = `${flowId}.options_selector.content`;
  const fieldRootPath = `${contentPath}.${locale}`;
  const stepFields = {
    title: {
      id: `${fieldRootPath}.title`,
      label: "Title",
    },
    body: {
      id: `${fieldRootPath}.body`,
      label: "Body",
    },
    linkText: {
      id: `${fieldRootPath}.intro_link.text`,
      label: "Text",
    },
    linkUrl: {
      id: `${fieldRootPath}.intro_link.url`,
      label: "URL",
    },
    expiryInfo: {
      id: `${fieldRootPath}.expiry_info`,
      label: "Expiry info",
    },
  };

  const sharedFieldRootPath = `${flowId}.shared.content.${locale}`;
  const sharedFields = {
    claimRewardError: {
      id: `${sharedFieldRootPath}.claim_reward_error`,
      label: "Claim error message",
    },
  };
  const [configurableRewardTypes, setConfigurableRewardTypes] = useState<
    RewardType[]
  >([]);

  const [showExpiryInfoTextField, setShowExpiryInfoTextField] = useState(
    typeof getValues(stepFields.expiryInfo.id) === "string"
  );

  const rewardTypesWithRewardOption = getValues(
    `${flowId}.shared.settings.reward_types`
  ).filter(
    (rewardTypes: { reward_options: RewardOption<RewardOptionSettings>[] }) =>
      rewardTypes.reward_options.length > 0
  );

  const [selectedRewardTypeId, setSelectedRewardTypeId] = useState<string>(
    rewardTypesWithRewardOption[0]?.id
  );

  const handleOnChange = (value: string) => setSelectedRewardTypeId(value);

  const rewardOptionsFor = (selectedRewardTypeId: string) => {
    const rewardType = getValues(`${flowId}.shared.settings.reward_types`).find(
      (rewardType: RewardType) => rewardType.id === selectedRewardTypeId
    );
    if (!rewardType) return [];
    return rewardType.reward_options;
  };

  useEffect(() => {
    if (!showExpiryInfoTextField) {
      unregister(stepFields.expiryInfo.id);
    }
  }, [showExpiryInfoTextField, unregister]);

  return (
    <OptionSelectorEditorWrapper
      flowId={flowId}
      setConfigurableRewardTypes={setConfigurableRewardTypes}
    >
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <Accordion label={stepFields.title.label} isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.title.id}
            key={stepFields.title.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="textarea"
                id={stepFields.title.id}
                aria-label={stepFields.title.label}
                value={getValues(stepFields.title.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion label={stepFields.body.label} isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.body.id}
            key={stepFields.body.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="textarea"
                id={stepFields.body.id}
                aria-label={stepFields.body.label}
                value={getValues(stepFields.body.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion label="Intro link" isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.linkText.id}
            key={stepFields.linkText.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.linkText.id}
                label={stepFields.linkText.label}
                aria-label={stepFields.linkText.label}
                value={getValues(stepFields.linkText.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
          <Controller
            control={control}
            name={stepFields.linkUrl.id}
            key={stepFields.linkUrl.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.linkUrl.id}
                label={stepFields.linkUrl.label}
                aria-label={stepFields.linkUrl.label}
                value={getValues(stepFields.linkUrl.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion
          label={sharedFields.claimRewardError.label}
          isExpandedByDefault={false}
        >
          <Controller
            control={control}
            name={sharedFields.claimRewardError.id}
            key={sharedFields.claimRewardError.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={sharedFields.claimRewardError.id}
                aria-label={sharedFields.claimRewardError.label}
                value={getValues(sharedFields.claimRewardError.id)}
                onChange={(e) => onChange(e)}
                isDisabled={true}
              />
            )}
          />
        </Accordion>
        <Accordion label="Reward options" isExpandedByDefault={false}>
          {rewardTypesWithRewardOption.length > 0 ? (
            <RadioGroup
              className="p-5 rounded-xl border border-backgroundSecondary"
              name={"reward_types"}
              label={"Reward types"}
              onChange={handleOnChange}
              value={selectedRewardTypeId}
              id={selectedRewardTypeId}
              isDisabled={false}
            >
              <div className="flex flex-row flex-wrap">
                {rewardTypesWithRewardOption.map(
                  (rewardType: { id: string }) => (
                    <Radio key={rewardType.id} value={rewardType.id}>
                      {capitalize(
                        configurableRewardTypes.find(
                          (configurableRewardType) =>
                            configurableRewardType.id === rewardType.id
                        )?.name || rewardType.id
                      )}
                    </Radio>
                  )
                )}
              </div>
            </RadioGroup>
          ) : (
            <p>Go to reward managment to configure reward options</p>
          )}
          {rewardOptionsFor(selectedRewardTypeId).map(
            (rewardOption: { key: string }) => (
              <RewardOptionContentEditor
                key={rewardOption.key}
                flowId={flowId}
                locale={locale}
                rewardOptionKey={rewardOption.key}
              />
            )
          )}
        </Accordion>
        <Accordion label="Expiry info" isExpandedByDefault={false}>
          <Switch
            id="show_expiry_info"
            isSelected={showExpiryInfoTextField}
            onChange={() =>
              setShowExpiryInfoTextField(!showExpiryInfoTextField)
            }
          >
            Show expiry info at the bottom of the page?
          </Switch>
          {showExpiryInfoTextField && (
            <Controller
              control={control}
              name={stepFields.expiryInfo.id}
              key={stepFields.expiryInfo.id}
              defaultValue={""}
              render={({ field: { onChange } }) => (
                <TextField
                  elementType="textarea"
                  id={stepFields.expiryInfo.id}
                  label={stepFields.expiryInfo.label}
                  aria-label={stepFields.expiryInfo.label}
                  value={getValues(stepFields.expiryInfo.id)}
                  onChange={(e) => onChange(e)}
                />
              )}
            />
          )}
        </Accordion>
      </ContentEditorWrapper>
    </OptionSelectorEditorWrapper>
  );
};

export default OptionSelectorContentEditor;
