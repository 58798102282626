import { useContext } from "react";

import {
  SelectedPreviewDevice,
  SelectedPreviewDeviceContext,
} from "context/SelectedPreviewDeviceContext";

const useSelectedPreviewDevice = (): SelectedPreviewDevice => {
  const { selectedPreviewDevice, setSelectedPreviewDevice } = useContext(
    SelectedPreviewDeviceContext
  );

  return { selectedPreviewDevice, setSelectedPreviewDevice };
};

export default useSelectedPreviewDevice;
