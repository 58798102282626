import React, { FC } from "react";

import { useWatch } from "react-hook-form";

type HexPreviewProps = {
  id: string;
};

const HexPreview: FC<HexPreviewProps> = ({ id }) => {
  const value = useWatch({ name: id });

  return (
    <div className="flex flex-row gap-x-1.25">
      <div
        style={{ backgroundColor: `${value}` }}
        className="h-5 w-5 ml-2.5 rounded-full border border-text shrink-0"
      />
      <div>Hex</div>
    </div>
  );
};

export default HexPreview;
