import { useContext } from "react";

import {
  BreadcrumbContext,
  BreadcrumbState,
} from "context/BreadcrumbContext/BreadcrumbContext";

const useBreadcrumb = (): BreadcrumbState => {
  const { breadcrumbItems, setBreadcrumbItems } = useContext(BreadcrumbContext);

  return { breadcrumbItems, setBreadcrumbItems };
};

export default useBreadcrumb;
