import React, { FC, useEffect } from "react";

import useBreadcrumb from "hooks/useBreadcrumb";

import BodyWrapper from "components/BodyWrapper";
import CampaignsList from "components/CampaignSelector/CampaignsList/CampaignsList";
import EditMarketButton from "components/CampaignSelector/EditMarketButton/EditMarketButton";
import MarketsFilter from "components/CampaignSelector/MarketsFilter/MarketsFilter";
import MarketsList from "components/CampaignSelector/MarketsList/MarketsList";

import { useSelectedMarketContext } from "context/SelectedMarketContext";

const CampaignSelector: FC = () => {
  const [selectedMarket, setSelectedMarket] = useSelectedMarketContext();
  const { setBreadcrumbItems } = useBreadcrumb();

  useEffect(() => {
    setSelectedMarket(null);
    setBreadcrumbItems([]);
  }, []);

  const showEditMarketButton =
    selectedMarket &&
    (selectedMarket.features.runa_enabled ||
      selectedMarket.features.ifeelgoods_enabled);

  return (
    <BodyWrapper>
      <div className="flex flex-col md:space-x-7 md:flex-row sm:rounded-lg w-full">
        <MarketsFilter />
        <div className="md:w-1/2 mt-2.5 mb-7 px-3.5 md:pl-0 flex justify-end items-center">
          {showEditMarketButton && <EditMarketButton />}
        </div>
      </div>

      <div className="flex flex-col md:space-x-7 md:flex-row sm:rounded-lg w-full">
        <MarketsList />
        <CampaignsList />
      </div>
    </BodyWrapper>
  );
};

export default CampaignSelector;
