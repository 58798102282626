import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  CreatableSelectField,
  FieldWithColorOptions,
  FieldWithFontWeightOptions,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

export type StrongElementEditorProps = {
  customFonts: { value: string; label: string }[];
};

const StrongElementEditor: FC<StrongElementEditorProps> = ({ customFonts }) => (
  <Group>
    <GroupHeader text="Strong HTML elements" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <CreatableSelectField
            fieldId="style.strong.font_family"
            label="Font family"
            options={customFonts}
          />
          <FieldWithFontWeightOptions
            id="style.strong.font_weight"
            label="Font weight"
          />
          <FieldWithColorOptions
            id="style.strong.color"
            label="Font color"
            showDefaultOption={true}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default StrongElementEditor;
{
  /* <div className="flex flex-col gap-y-3">{children}</div> */
}
