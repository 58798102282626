import React, { FC } from "react";

type GroupHeaderProps = {
  text: string;
};

const GroupHeader: FC<GroupHeaderProps> = ({ text }) => (
  <div className="text-text text-2xl font-medium">{text}</div>
);

export default GroupHeader;
