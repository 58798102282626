/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";

import { VisuallyHidden } from "@react-aria/visually-hidden";
import { OptionTypeBase } from "interfaces";
import Select, {
  DropdownIndicatorProps,
  Options,
  Theme,
  ThemeConfig,
  components,
} from "react-select";

import { ArrowDownIcon } from "components/Icon";

export type PickerProps = {
  label: string;
  id: string;
  items: OptionTypeBase[];
  value: OptionTypeBase | undefined;
  isClearable?: boolean;
  onChange?: (
    selectedOptions: OptionTypeBase | Options<OptionTypeBase>
  ) => void;
  /**
   * Whether or not the radio is disabled.
   */
  isDisabled?: boolean;
};

const theme: ThemeConfig = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    // All possible overrides
    // border color isFocused option is selected background color
    primary: "rgb(var(--background-primary) / 1)",

    // option is focused background color
    primary25: "rgb(var(--background-primary) / 1)",

    // active option background color not selected not disabled
    primary50: "rgb(var(--background-primary) / 1)",
    primary75: "rgb(var(--primary) / 1)",

    danger: "rgb(var(--error:) / 1)",
    dangerLight: "rgb(var(--error:) / 1)",

    // option color selected and menu background color
    neutral0: "rgb(var(--background-primary) / 1)",
    neutral5: "rgb(var(--background-primary) / 1)", // disabled control background

    // border color disabled  and option color isSelected and control background
    neutral10: "rgb(var(--background-primary) / 1)",

    // border color and option color isDisabled and indicator container color
    neutral20: "rgb(var(--background-primary) / 1)",
    neutral30: "rgb(var(--background-primary) / 1)", // control hover border

    // notices color and single value disabled
    neutral40: "rgb(var(--color-text) / 1)",

    neutral50: "rgb(var(--color-text) / 1)", // placeholder
    neutral60: "rgb(var(--color-text) / 1)", // indicator container color focus
    // neutral70: 'hsl(0, 0%, 30%)',

    // input color single value color
    neutral80: "rgb(var(--color-text) / 1)",
    // neutral90: 'hsl(0, 0%, 10%)',
  },
  // Other options you can use
  borderRadius: 8,
  // Used to calculate consistent margin/padding on elements
  baseUnit: 4,
  // The minimum height of the control
  controlHeight: 38,
  // The amount of space between the control and menu */
  menuGutter: 0,
});

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    marginTop: "0px",
    marginBottom: "0px",
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "none",
    borderWidth: "2px",
    borderColor: "transparent",
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: "15px 20px 20px",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: "1px solid",
    borderColor: "rgb(var(--color-text) / 0.2)",
    fontWeight: state.isSelected ? "bold" : "normal",
    color: "rgb(var(--color-text) / 1)",
    padding: "10px 0px",
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    borderWidth: "2px",
    borderBottomColor:
      state.isFocused && state.selectProps.menuIsOpen
        ? "transparent"
        : "undefined",
    borderBottom:
      state.isFocused && state.selectProps.menuIsOpen ? "unset" : "undefined",
    marginBottom:
      state.isFocused && state.selectProps.menuIsOpen ? "2px" : "undefined",
  }),
  placeholder: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingLeft: "20px",
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled || state.selectProps.menuIsOpen ? 0.5 : 1;
    const marginLeft = 0;
    return { ...provided, opacity, marginLeft };
  },
  indicatorContainer: (provided: any) => ({
    ...provided,
  }),
  indicatorSeparator: () => ({}),
  clearIndicator: () => ({}),
  dropdownIndicator: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...provided,
      color: `rgb(var(--color-text) / ${opacity})`,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
    };
  },
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );
};

const Picker: FC<PickerProps> = ({
  label,
  id,
  items,
  value,
  onChange,
  isDisabled,
  isClearable = false,
}) => {
  const labelId = `${id} ${label}`;

  return (
    <div className="col-span-6 sm:col-span-3">
      <VisuallyHidden>
        <label htmlFor={id} id={labelId}>
          {label}
        </label>
      </VisuallyHidden>
      <Select
        styles={customStyles}
        theme={theme}
        value={value}
        isDisabled={isDisabled}
        closeMenuOnSelect={true}
        isClearable={isClearable}
        isSearchable={false}
        name={id}
        options={items}
        inputId={id}
        onChange={(selectedOptions) =>
          selectedOptions &&
          onChange &&
          onChange(selectedOptions as OptionTypeBase)
        }
        placeholder={label}
        aria-label={label}
        aria-labelledby={labelId}
        classNamePrefix="react-select-five"
        className="react-select-five"
        components={{ DropdownIndicator }}
      />
    </div>
  );
};

export default Picker;
