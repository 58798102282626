import React, { useContext } from "react";

import { RadioGroupState } from "@react-stately/radio";

interface RadioGroupContext {
  state: RadioGroupState;
}

export const RadioContext = React.createContext<RadioGroupContext | undefined>(
  undefined
);

export function useRadioProvider(): RadioGroupContext {
  const context = useContext(RadioContext);
  if (context === undefined) {
    throw Error(
      "Radio must be used inside of a RadioGroup, " +
        "otherwise it will not function correctly."
    );
  }
  return context;
}
