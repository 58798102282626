import React from "react";

import { EditorComponent } from "interfaces";

import SplashPageEditorWrapper from "components/CampaignConfigurationEditor/StepEditor/Editors/ReferrerJourney/SplashPageEditor/SplashPageEditorWrapper/SplashPageEditorWrapper";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import schema from "../../schema.json";

const SplashPageContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "splash_page";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <SplashPageEditorWrapper>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <SchemaRenderer
          schema={schema}
          flowId={flowId}
          journeyId={journeyId}
          section={"content"}
          locale={locale}
        />
      </ContentEditorWrapper>
    </SplashPageEditorWrapper>
  );
};

export default SplashPageContentEditor;
