import React from "react";

import { EditorComponent } from "interfaces";
import { Controller, useFormContext } from "react-hook-form";

import { Accordion } from "components/Accordion/Accordion";
import { TextField } from "components/TextField/TextField";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import OptionConfirmationEditorWrapper from "../OptionConfirmationEditorWrapper/OptionConfirmationEditorWrapper";

const OptionConfirmationContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const { getValues, control } = useFormContext();

  const contentPath = `${flowId}.option_confirmation.content`;
  const fieldRootPath = `${contentPath}.${locale}`;
  const stepFields = {
    title: {
      id: `${fieldRootPath}.title`,
      label: "Title",
    },
    cancelButton: {
      id: `${fieldRootPath}.cancel_button_text`,
      label: "Cancel button",
    },
    confirmButton: {
      id: `${fieldRootPath}.confirm_button_text`,
      label: "Confirm button",
    },
    linkText: {
      id: `${fieldRootPath}.footer_link.text`,
      label: "Text",
    },
    linkUrl: {
      id: `${fieldRootPath}.footer_link.url`,
      label: "URL",
    },
  };

  const sharedFieldRootPath = `${flowId}.shared.content.${locale}`;
  const sharedFields = {
    claimRewardError: {
      id: `${sharedFieldRootPath}.claim_reward_error`,
      label: "Claim error message",
    },
  };

  return (
    <OptionConfirmationEditorWrapper flowId={flowId} locale={locale}>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <Accordion label={stepFields.title.label} isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.title.id}
            key={stepFields.title.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="textarea"
                id={stepFields.title.id}
                aria-label={stepFields.title.label}
                value={getValues(stepFields.title.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion label="Call to actions" isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.cancelButton.id}
            key={stepFields.cancelButton.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.cancelButton.id}
                label={stepFields.cancelButton.label}
                aria-label={stepFields.cancelButton.label}
                value={getValues(stepFields.cancelButton.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
          <Controller
            control={control}
            name={stepFields.confirmButton.id}
            key={stepFields.confirmButton.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.confirmButton.id}
                label={stepFields.confirmButton.label}
                aria-label={stepFields.confirmButton.label}
                value={getValues(stepFields.confirmButton.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion label="Footer link" isExpandedByDefault={false}>
          <Controller
            control={control}
            name={stepFields.linkText.id}
            key={stepFields.linkText.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.linkText.id}
                label={stepFields.linkText.label}
                aria-label={stepFields.linkText.label}
                value={getValues(stepFields.linkText.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
          <Controller
            control={control}
            name={stepFields.linkUrl.id}
            key={stepFields.linkUrl.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={stepFields.linkUrl.id}
                label={stepFields.linkUrl.label}
                aria-label={stepFields.linkUrl.label}
                value={getValues(stepFields.linkUrl.id)}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        </Accordion>
        <Accordion
          label={sharedFields.claimRewardError.label}
          isExpandedByDefault={false}
        >
          <Controller
            control={control}
            name={sharedFields.claimRewardError.id}
            key={sharedFields.claimRewardError.id}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={sharedFields.claimRewardError.id}
                aria-label={sharedFields.claimRewardError.label}
                value={getValues(sharedFields.claimRewardError.id)}
                onChange={(e) => onChange(e)}
                isDisabled={true}
              />
            )}
          />
        </Accordion>
      </ContentEditorWrapper>
    </OptionConfirmationEditorWrapper>
  );
};

export default OptionConfirmationContentEditor;
