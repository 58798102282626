import React, { FC, useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

import {
  FormActions,
  FormBody,
  FormColumn,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  ButtonEditor,
  ColorsEditor,
  FontEditor,
  FormEditor,
  LinkEditor,
  StrongElementEditor,
  SwitchEditor,
  TextInputEditor,
  TitleEditor,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Editors";
import ErrorAlert from "components/ErrorAlert";

import { useSelectedCampaignConfigurationContext } from "context/SelectedCampaignConfigurationContext";

type GlobalStyleFormProps = {
  customFonts: {
    value: string;
    label: string;
  }[];
  handleSave: (formData: Record<string, unknown>) => void;
};

const GlobalStyleForm: FC<GlobalStyleFormProps> = ({
  customFonts,
  handleSave,
}) => {
  const [selectedCampaignConfiguration] =
    useSelectedCampaignConfigurationContext();

  if (!selectedCampaignConfiguration) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const methods = useForm({
    defaultValues: selectedCampaignConfiguration.value,
  });

  const {
    formState: { isSubmitted },
  } = methods;

  const customFontsWithDefault = [
    { value: "default", label: "default" },
    ...customFonts,
  ];

  const canEditStyle = !selectedCampaignConfiguration.published_at;

  useEffect(() => {
    methods.reset(selectedCampaignConfiguration.value, {
      keepDirtyValues: !isSubmitted,
    });
  }, [selectedCampaignConfiguration]);

  return (
    <FormProvider {...methods}>
      <form role="form" onSubmit={methods.handleSubmit(handleSave)}>
        <FormBody>
          <FormColumn>
            <FontEditor customFonts={customFonts} />
            <StrongElementEditor customFonts={customFontsWithDefault} />
            <ButtonEditor customFonts={customFontsWithDefault} />
          </FormColumn>
          <FormColumn>
            <ColorsEditor />
            <FormEditor />
            <TextInputEditor />
          </FormColumn>
          <FormColumn>
            <TitleEditor customFonts={customFontsWithDefault} />
            <SwitchEditor />
            <LinkEditor customFonts={customFontsWithDefault} />
          </FormColumn>
        </FormBody>
        {canEditStyle && <FormActions />}
      </form>
    </FormProvider>
  );
};

export default GlobalStyleForm;
