import React, { FC, useEffect } from "react";

import { OptionTypeBase } from "interfaces";
import { pick } from "lodash";
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

import Button from "components/Button";
import Dropdown from "components/Dropdown/Dropdown";
import { Fieldset } from "components/Fieldset/Fieldset";
import { TextField } from "components/TextField/TextField";

type CustomFieldSettingsEditorProps = {
  customField: Record<"id", string>;
  index: number;
  customFieldsPath: string;
  customFieldTypeDropdownItems: { label: string; value: string }[];
  requiredCustomFieldConfigFor: (customFieldType: string) => string[];
  removeCustomField: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
};

const CustomFieldSettingsEditor: FC<CustomFieldSettingsEditorProps> = ({
  customField,
  index,
  customFieldsPath,
  customFieldTypeDropdownItems,
  requiredCustomFieldConfigFor,
  removeCustomField,
}) => {
  const { getValues, control, setValue } = useFormContext();

  const customFieldPath = `${customFieldsPath}.${index}`;
  const customFieldItemsPath = `${customFieldPath}.config.items`;

  const { fields, append, remove } = useFieldArray({
    name: customFieldItemsPath,
  });

  const showConfigField = (key: string) =>
    requiredCustomFieldConfigFor(getValues(`${customFieldPath}.type`)).includes(
      key
    );

  const removeIrrelevantConfigKeys = () => {
    const existingConfig = getValues(`${customFieldPath}.config`);
    const requiredConfig = requiredCustomFieldConfigFor(
      getValues(`${customFieldPath}.type`)
    );

    setValue(`${customFieldPath}.config`, pick(existingConfig, requiredConfig));
  };

  const type = useWatch({
    name: `${customFieldPath}.type`,
  });

  const selectedCustomFieldTypeDropdownItem = () =>
    customFieldTypeDropdownItems.find(
      (customFieldTypeDropdownItem) =>
        customFieldTypeDropdownItem.value ===
        getValues(`${customFieldPath}.type`)
    ) || null;

  useEffect(() => {
    removeIrrelevantConfigKeys();
  }, [type]);

  const addDropdownItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!getValues(customFieldItemsPath)) {
      setValue(customFieldItemsPath, []);
    }

    append("");
  };

  const removeDropdownItem = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    remove(index);
  };

  return (
    <Fieldset
      key={customField.id}
      legend={`Custom field #${getValues(`${customFieldPath}.position`)}`}
    >
      <Controller
        control={control}
        name={`${customFieldPath}.key`}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={`${customFieldPath}.key`}
            label={"Identifier"}
            aria-label={"Identifier"}
            value={getValues(`${customFieldPath}.key`)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Controller
        control={control}
        name={`${customFieldPath}.type`}
        render={({ field: { onChange } }) => (
          <Dropdown
            id={`${customFieldPath}.type`}
            value={selectedCustomFieldTypeDropdownItem()}
            items={customFieldTypeDropdownItems}
            onChange={(e) => onChange((e as OptionTypeBase).value)}
            label={"Type"}
          />
        )}
      />
      {showConfigField("number_of_characters") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${customFieldPath}.config.number_of_characters`}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${customFieldPath}.config.number_of_characters`}
              label="Exact number of characters (eg. 3) or a range (eg. 2-6)"
              aria-label="Exact number of characters (eg. 3) or a range (eg. 2-6)"
              value={getValues(
                `${customFieldPath}.config.number_of_characters`
              )}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showConfigField("type_of_characters", index) && (
        <Controller
          control={control}
          defaultValue=""
          name={`${customFieldPath}.config.type_of_characters`}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${customFieldPath}.config.type_of_characters`}
              label="Type of characters if restricted to 'numbers' or 'letters'"
              aria-label="Type of characters if restricted to 'numbers' or 'letters'"
              value={getValues(`${customFieldPath}.config.type_of_characters`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showConfigField("min_age", index) && (
        <Controller
          control={control}
          defaultValue=""
          name={`${customFieldPath}.config.min_age`}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              type="number"
              id={`${customFieldPath}.config.min_age`}
              label="Is there a minimum age a user can be?"
              aria-label="Is there a minimum age a user can be?"
              value={getValues(`${customFieldPath}.config.min_age`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showConfigField("max_age", index) && (
        <Controller
          control={control}
          defaultValue=""
          name={`${customFieldPath}.config.max_age`}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              type="number"
              id={`${customFieldPath}.config.max_age`}
              label="Is there a maximum age a user can be?"
              aria-label="Is there a maximum age a user can be?"
              value={getValues(`${customFieldPath}.config.max_age`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showConfigField("items", index) && (
        <>
          <label className="pb-1 text-text font-bold flex-1"> Options</label>

          {fields.map((m, i) => (
            <Controller
              control={control}
              key={i}
              defaultValue=""
              name={`${customFieldItemsPath}.${i}`}
              render={({ field: { onChange } }) => (
                <div className="flex">
                  <div className="flex-1">
                    <TextField
                      elementType="input"
                      type="text"
                      id={`${customFieldItemsPath}.${i}`}
                      placeholder={`Option ${i + 1}`}
                      aria-label={`Option ${i + 1}`}
                      value={getValues(`${customFieldItemsPath}.${i}`)}
                      onChange={(e) => onChange(e)}
                    />
                  </div>

                  <div className="flex-0 ml-3">
                    <Button
                      appearance="danger"
                      text="Remove"
                      handleOnClick={(e) => removeDropdownItem(e, i)}
                    />
                  </div>
                </div>
              )}
            />
          ))}
          <Button handleOnClick={addDropdownItem} text="Add option" />
        </>
      )}
      <Button
        testId={`remove-cf-${index}`}
        appearance="danger"
        text="Remove"
        handleOnClick={(e) => removeCustomField(e, index)}
      />
    </Fieldset>
  );
};

export default CustomFieldSettingsEditor;
