import React, { FC, useEffect, useState } from "react";

import _ from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { Fieldset } from "components/Fieldset/Fieldset";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";

type CustomFieldContentEditorProps = {
  allowTooltipConfiguration: boolean;
  customField: Record<string, unknown>;
  index: number;
  customFieldsPath: string;
  config?: { items: string[] };
};

const CustomFieldContentEditor: FC<CustomFieldContentEditorProps> = ({
  allowTooltipConfiguration,
  customFieldsPath,
  customField,
  index,
  config,
}) => {
  const { getValues, control, unregister } = useFormContext();

  const [showTooltipTextField, setShowTooltipTextField] = useState(
    typeof getValues(`${customFieldsPath}.${index}.content.tooltip`) ===
      "string"
  );

  useEffect(() => {
    if (!showTooltipTextField) {
      unregister(`${customFieldsPath}.${index}.content.tooltip`);
    }
  }, [showTooltipTextField]);

  return (
    <Fieldset legend={`Custom field: ${customField.key}`}>
      <Controller
        control={control}
        key={`${customFieldsPath}.${index}.content.label`}
        name={`${customFieldsPath}.${index}.content.label`}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={`${customFieldsPath}.${index}.content.label`}
            label="Label"
            aria-label="Label"
            value={getValues(`${customFieldsPath}.${index}.content.label`)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Controller
        control={control}
        key={`${customFieldsPath}.${index}.content.placeholder`}
        name={`${customFieldsPath}.${index}.content.placeholder`}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={`${customFieldsPath}.${index}.content.placeholder`}
            label="Placeholder"
            aria-label="Placeholder"
            value={getValues(
              `${customFieldsPath}.${index}.content.placeholder`
            )}
            onChange={(e) => onChange(e)}
          />
        )}
      />

      {getValues(`${customFieldsPath}.${index}.content.validation_errors`)?.map(
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        (m: any, validationIndex: number) => (
          <Controller
            control={control}
            key={`${customFieldsPath}.${index}.content.validation_errors.${validationIndex}`}
            name={`${customFieldsPath}.${index}.content.validation_errors.${validationIndex}.value`}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={`${customFieldsPath}.${index}.content.validation_errors.${validationIndex}.key`}
                label={`Validation error (${_.startCase(m.key)})`}
                aria-label={`Validation error (${_.startCase(m.key)})`}
                value={getValues(
                  `${customFieldsPath}.${index}.content.validation_errors.${validationIndex}.value`
                )}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        )
      )}

      {allowTooltipConfiguration && (
        <>
          <Switch
            id={`show_tooltip_for_cf_${index}`}
            isSelected={showTooltipTextField}
            onChange={() => setShowTooltipTextField(!showTooltipTextField)}
          >
            Show info icon with tooltip?
          </Switch>
          {showTooltipTextField && (
            <Controller
              control={control}
              key={`${customFieldsPath}.${index}.content.tooltip`}
              name={`${customFieldsPath}.${index}.content.tooltip`}
              defaultValue=""
              render={({ field: { onChange } }) => (
                <TextField
                  elementType="input"
                  id={`${customFieldsPath}.${index}.content.tooltip`}
                  label="Tooltip"
                  aria-label="Tooltip"
                  value={getValues(
                    `${customFieldsPath}.${index}.content.tooltip`
                  )}
                  onChange={(e) => onChange(e)}
                />
              )}
            />
          )}
        </>
      )}

      {config &&
        config?.items &&
        config.items.map((item: string, i: number) => (
          <Controller
            control={control}
            key={`${customFieldsPath}.${index}.content.items.${i}`}
            name={`${customFieldsPath}.${index}.content.items.${i}`}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                id={`${customFieldsPath}.${index}.content.items.${i}`}
                label={`Label for item: ${item}`}
                aria-label={`Label for item: ${item}`}
                value={getValues(
                  `${customFieldsPath}.${index}.content.items.${i}`
                )}
                onChange={(e) => onChange(e)}
              />
            )}
          />
        ))}
    </Fieldset>
  );
};

export default CustomFieldContentEditor;
