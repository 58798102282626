import { FC, ReactElement, useEffect } from "react";

import usePreviewStates from "hooks/usePreviewStates";
import { RewardOptionContent } from "interfaces";
import { useFormContext } from "react-hook-form";

type OptionConfirmationEditorWrapperProps = {
  flowId: string;
  locale: string;
  children: ReactElement;
};

const OptionConfirmationEditorWrapper: FC<
  OptionConfirmationEditorWrapperProps
> = ({ flowId, locale, children }) => {
  const { setPreviewStates } = usePreviewStates();
  const { getValues } = useFormContext();

  useEffect(() => {
    const rewardOptionsConfigured = getValues(
      `${flowId}.options_selector.content.${locale}.reward_options`
    ) as { [key: string]: RewardOptionContent };

    if (!rewardOptionsConfigured) return;

    setPreviewStates(
      Object.entries(rewardOptionsConfigured).map(
        ([rewardOptionKey, rewardOptionContent]) => ({
          label: `Reward option selected: ${rewardOptionKey}`,
          value: { selected_reward_title: rewardOptionContent.title },
        })
      )
    );

    return () => setPreviewStates([]);
  }, [locale]);

  return children;
};

export default OptionConfirmationEditorWrapper;
