import React, { FC, useEffect, useState } from "react";

import Axios, { AxiosError, CancelTokenSource } from "axios";
import useBreadcrumb from "hooks/useBreadcrumb";
import { EmailTemplate } from "interfaces";
import { set } from "lodash";
import toast from "react-hot-toast";
import {
  Prompt,
  Redirect,
  Route,
  matchPath,
  useHistory,
} from "react-router-dom";

import { NurtureConfigTypes } from "constants/NurtureConfigsConstant";

import { useAuth } from "components/AuthProvider";
import BodyWrapper from "components/BodyWrapper";
import ErrorAlert from "components/ErrorAlert";
import ErrorToast from "components/ErrorToast/ErrorToast";
import Loader from "components/Loader";
import {
  ConfirmationModal,
  NurtureSequenceActions,
  NurtureSequenceTable,
} from "components/Nurture/NurtureEditor";
import { NurtureConfig } from "components/Nurture/interfaces";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

interface MatchedPath {
  params: {
    nurtureType: string;
  };
}

const NurtureEditor: FC = () => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!selectedMarket || !selectedCampaign || !axios) {
    return <ErrorAlert message="Context isn't set correctly" />;
  }

  const history = useHistory();
  const { setBreadcrumbItems } = useBreadcrumb();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [isDeactivatingSequence, setIsDeactivatingSequence] =
    useState<boolean>(false);
  const [showSavePrompt, setShowSavePrompt] = useState<boolean>(false);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const emptyNurtureConfig: NurtureConfig = {
    id: "",
    active: true,
    type: "",
    sequence: [],
    repeat_delay_days: null,
    referrer_inactive_days: 60,
    existing_referrer_max_days_since_signup: null,
    existing_user_max_days_since_event: null,
  };
  const [nurtureConfig, setNurtureConfig] =
    useState<NurtureConfig>(emptyNurtureConfig);
  const [includedUserCount, setIncludedUserCount] = useState<number | string>(
    "[Loading...]"
  );

  const campaignEditorPath = `/${selectedMarket.subdomain}/${selectedCampaign.slug}`;

  const goBack = () => history.push(campaignEditorPath);

  const matchedPath: MatchedPath | null = matchPath(location.pathname, {
    path: "/:marketSubdomain/:campaignSlug/:nurtureType",
    exact: true,
    strict: true,
  });

  const nurtureType: string | null = matchedPath?.params.nurtureType || null;

  if (!nurtureType) {
    return <Route render={() => <Redirect to={campaignEditorPath} />} />;
  }

  const source = Axios.CancelToken.source();

  const loadData = async (source: CancelTokenSource) => {
    if (!selectedMarket || !selectedCampaign || !nurtureType) return;

    try {
      const {
        data: { email_templates },
      } = await axios.get("/api/email_templates", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
          type: nurtureType,
        },
        cancelToken: source.token,
      });
      setEmailTemplates(email_templates);

      const {
        data: { nurture_config },
      } = await axios.get(`/api/nurture_configs/${nurtureType}`, {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });
      if (Object.keys(nurture_config).length > 0) {
        setNurtureConfig(nurture_config);
      }

      setLoading(false);
      /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (Axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        setError(e.message);
      }
    }
  };

  const saveNurtureConfig = async (source: CancelTokenSource) => {
    const {
      data: { nurture_config },
    } = await axios.put(`/api/nurture_configs/${nurtureType}`, {
      region_key: selectedMarket.region_key,
      campaign_id: selectedCampaign.id,
      active: nurtureConfig.active,
      sequence: nurtureConfig.sequence,
      repeat_delay_days: nurtureConfig.repeat_delay_days,
      referrer_inactive_days: nurtureConfig.referrer_inactive_days,
      existing_referrer_max_days_since_signup:
        nurtureConfig.existing_referrer_max_days_since_signup,
      existing_user_max_days_since_event:
        nurtureConfig.existing_user_max_days_since_event,
      cancelToken: source.token,
    });

    if (Object.keys(nurture_config).length > 0) {
      setNurtureConfig(nurture_config);
    } else {
      throw new Error("Unable to save nurture config");
    }

    setHasUnsavedChanges(false);
  };

  const getIncludedUserCount = async (source: CancelTokenSource) => {
    const {
      data: { included_user_count },
    } = await axios.post(
      `/api/nurture_configs/${nurtureType}/included_user_count`,
      {
        region_key: selectedMarket.region_key,
        campaign_id: selectedCampaign.id,
        active: nurtureConfig.active,
        sequence: nurtureConfig.sequence,
        repeat_delay_days: nurtureConfig.repeat_delay_days,
        referrer_inactive_days: nurtureConfig.referrer_inactive_days,
        existing_referrer_max_days_since_signup:
          nurtureConfig.existing_referrer_max_days_since_signup,
        existing_user_max_days_since_event:
          nurtureConfig.existing_user_max_days_since_event,
        cancelToken: source.token,
      }
    );

    setIncludedUserCount(included_user_count);
  };

  const handleSave = async () => {
    setShowSavePrompt(false);
    setIsDeactivatingSequence(false);

    toast.promise(saveNurtureConfig(source), {
      loading: "Saving...",
      success: "Saved",
      error: function MyToast(e: AxiosError) {
        return <ErrorToast error={e} />;
      },
    });
  };

  const nurtureConfigTitle: string =
    NurtureConfigTypes.find((t) => t.type == nurtureType)?.label || "";

  const handleEmailTemplateChange = (
    e: React.ChangeEvent<HTMLSelectElement> | Event
  ) => {
    const target = e.target as HTMLInputElement;
    const updatedNurtureConfig = set(
      { ...nurtureConfig },
      target.id,
      target.value
    );

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedNurtureConfig = set(
      { ...nurtureConfig },
      e.target.id,
      parseInt(e.target.value) || 1
    );

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  const handleInputToggle = (id: string, value: boolean) => {
    const updatedNurtureConfig = set(
      { ...nurtureConfig },
      id,
      value ? 1 : null
    );

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  const newSequenceStep = () => {
    return {
      email_template_id: emailTemplates[0].id,
      delay_days: 1,
    };
  };

  const handleAddSequenceStep = () => {
    if (emailTemplates.length < 1) {
      toast.error(
        "No email templates found. You must first create email templates in co-buying."
      );
      return;
    }

    const updatedNurtureConfig = set(
      { ...nurtureConfig },
      `sequence[${nurtureConfig.sequence.length || 0}]`,
      newSequenceStep()
    );

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  const handleRemoveSequenceStep = (index: number) => {
    if (nurtureConfig.sequence.length < 2) {
      toast.error("You must have at least one sequence step!");
      return;
    }

    const sequenceClone = nurtureConfig.sequence.slice();
    sequenceClone.splice(index, 1);
    const updatedNurtureConfig = set(
      { ...nurtureConfig },
      "sequence",
      sequenceClone
    );

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  const handleActiveToggle = (id: string, value: boolean) => {
    setIsDeactivatingSequence(!value);

    const updatedNurtureConfig = set({ ...nurtureConfig }, id, value);

    setNurtureConfig(updatedNurtureConfig);
    setHasUnsavedChanges(true);
  };

  useEffect(() => {
    setBreadcrumbItems([
      {
        label: "Market",
        value: selectedMarket.name,
        path: "/",
      },
      {
        label: "Campaign",
        value: selectedCampaign.slug,
        path: campaignEditorPath,
      },
      {
        label: "Nurture email sequence",
        value: nurtureConfigTitle,
      },
    ]);
    loadData(source);

    return () => source && source.cancel();
  }, []);

  useEffect(() => {
    setIncludedUserCount("[Loading...]");

    if (showSavePrompt) {
      getIncludedUserCount(source);

      return () => source && source.cancel();
    }
  }, [showSavePrompt]);

  if (error) {
    return <ErrorAlert message={error} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <BodyWrapper>
      <Prompt
        data-testid="unsaved-prompt"
        when={hasUnsavedChanges}
        message="There are unsaved changes, if you leave this page your changes will be lost. Are you sure you want to leave this page?"
      />
      {showSavePrompt && (
        <ConfirmationModal
          setShowSavePrompt={setShowSavePrompt}
          handleSave={handleSave}
          includedUserCount={includedUserCount}
          isDeactivatingSequence={isDeactivatingSequence}
        />
      )}
      <div className="flex flex-row justify-center">
        <div className="lg:w-1/2 w-full my-10 px-4 py-5 rounded-xl shadow bg-white">
          <div className="mx-4">
            <NurtureSequenceTable
              nurtureType={nurtureType}
              nurtureConfig={nurtureConfig}
              emailTemplates={emailTemplates}
              handleEmailTemplateChange={handleEmailTemplateChange}
              handleInputChange={handleInputChange}
              handleInputToggle={handleInputToggle}
              handleRemoveSequenceStep={handleRemoveSequenceStep}
              handleActiveToggle={handleActiveToggle}
            />
            <NurtureSequenceActions
              handleAddSequenceStep={handleAddSequenceStep}
              handleCancel={goBack}
              handleSave={() => setShowSavePrompt(true)}
              disableSave={!hasUnsavedChanges}
            />
          </div>
        </div>
      </div>
    </BodyWrapper>
  );
};

export default NurtureEditor;
