import React, { FC, useContext } from "react";

import { Market } from "interfaces";
import { IoIosArrowForward } from "react-icons/io";

import ListContainer from "components/CampaignSelector/ListContainer/ListContainer";

import { MarketsContext, MarketsContextType } from "context/MarketsContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

const MarketsTable: FC = () => {
  const { state: markets } = useContext<MarketsContextType>(MarketsContext);
  const [selectedMarket, setSelectedMarket] = useSelectedMarketContext();

  return (
    <ListContainer header="Markets">
      {markets.map((market: Market) => (
        <li
          key={`${market.region_key}-${market.subdomain}`}
          onClick={(): void => setSelectedMarket(market)}
          className={`generic-list-item cursor-pointer hover:bg-brand-secondary hover:text-white dark:hover:bg-brand-secondary dark:hover:text-white ${
            selectedMarket === market
              ? "bg-brand-secondary text-white"
              : "bg-surfacePrimary"
          }`}
        >
          <div className={"flex-grow flex flex-col justify-center"}>
            <div className={"text-md font-medium"}>{market.name}</div>
            <div className={"text-sm"}>{market.subdomain}</div>
          </div>
          <IoIosArrowForward className={"w-6 h-auto"} />
        </li>
      ))}
    </ListContainer>
  );
};

export default MarketsTable;
