import React, { FC } from "react";

import { Controller, useFormContext } from "react-hook-form";
import Select, { GroupBase, InputProps, components } from "react-select";

import {
  FieldLabel,
  FieldWrapper,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";
import {
  customStyles,
  theme,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields/utils";

type OptionType = { value: string; label: string };

type SelectFieldProps = {
  options: { value: string; label: string }[];
  label: string;
  fieldId: string;
};

const Input = (
  props: JSX.IntrinsicAttributes &
    InputProps<unknown, boolean, GroupBase<unknown>>
) => <components.Input {...props} data-lpignore="true" />;

const SelectField: FC<SelectFieldProps> = ({ options, label, fieldId }) => {
  const { getValues, control } = useFormContext();

  return (
    <FieldWrapper>
      <FieldLabel text={label} />
      <Controller
        control={control}
        name={fieldId}
        render={({ field: { onChange } }) => (
          <Select
            name={fieldId}
            aria-label={label}
            isClearable={false}
            theme={theme}
            styles={customStyles}
            components={{ Input }}
            options={options}
            defaultValue={{
              value: getValues(fieldId),
              label: getValues(fieldId),
            }}
            onChange={(e: OptionType) => onChange(e.value)}
          />
        )}
      />
    </FieldWrapper>
  );
};

export default SelectField;
