import { ItemWithRequiredConfig } from "interfaces";

export const requiredConfigFor = (
  type: string,
  configurableItems: ItemWithRequiredConfig[]
) => {
  let result: string[] = [];

  const configurableItem = configurableItems.find(
    (configurableItem) => configurableItem.id === type
  );

  if (configurableItem) {
    result = configurableItem.required_config;
  }

  return result;
};
