import React, { FC, useEffect, useState } from "react";

import Axios, { CancelTokenSource } from "axios";
import cn from "classnames";
import { itemsToDropdowItems } from "helpers/DropdownHelper";
import {
  EmailTemplate,
  OptionsBasedReward,
  RewardOption,
  RewardOptionSettings,
  VoucherPool,
} from "interfaces";
import {
  Controller,
  FieldArrayMethodProps,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";

import { useAuth } from "components/AuthProvider";
import Button from "components/Button";
import HyperwalletSubtypeFields from "components/CampaignConfigurationEditor/RewardManagementEditor/AddRewardOption/HyperWalletSubtypeFields/HyperWalletSubtypeFields";
import OptionBasedSubtypeFields from "components/CampaignConfigurationEditor/RewardManagementEditor/AddRewardOption/OptionBasedSubtypeFields/OptionBasedSubtypeFields";
import PaypalSubtypeFields from "components/CampaignConfigurationEditor/RewardManagementEditor/AddRewardOption/PaypalSubtypeFields/PaypalSubtypeFields";
import VoucherSubtypeFields from "components/CampaignConfigurationEditor/RewardManagementEditor/AddRewardOption/VoucherSubtypeFields/VoucherSubtypeFields";
import ErrorAlert from "components/ErrorAlert";
import { TextField } from "components/TextField/TextField";
import { Ticker, TickerGroup } from "components/Ticker";

import { useSelectedCampaignContext } from "context/SelectedCampaignContext";
import { useSelectedMarketContext } from "context/SelectedMarketContext";

export type AddRewardOptionProps = {
  pathToRewardTypesArray: string;
  voucherPools: VoucherPool[];
  emailTemplates: EmailTemplate[];
  manualRewardEmailTemplates: EmailTemplate[];
  rewards: OptionsBasedReward[];
  rewardOptions: Record<"id", string>[];
  appendRewardOption: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
};

type AddRewardOptionFormValues = {
  key: string;
  reward_class: "voucher" | "paypal" | "hyperwallet" | "runa" | "ifeelgoods";
  settings: {
    email_template_id: string | null;
    manual_email_template_id: string | null;
    voucher_pool_id: string | null;
    reward_option_id: string | null;
    reward_cents: number | null;
    reward_currency: string;
  };
};

const AddRewardOption: FC<AddRewardOptionProps> = ({
  pathToRewardTypesArray,
  voucherPools,
  emailTemplates,
  manualRewardEmailTemplates,
  rewardOptions,
  rewards,
  appendRewardOption,
}) => {
  const { axios } = useAuth();
  const [selectedMarket] = useSelectedMarketContext();
  const [selectedCampaign] = useSelectedCampaignContext();

  if (!axios || !selectedMarket || !selectedCampaign) {
    throw new Error("Context isn't set correctly");
  }

  const { getValues } = useFormContext();
  const position = rewardOptions.length + 1;
  const [availableSubtypes, setAvailableSubtypes] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const loadAvailableSubtypes = async (source: CancelTokenSource) => {
    try {
      const {
        data: { click_to_claim_subtypes },
      } = await axios.get("/api/reward_types/click_to_claim_subtypes", {
        params: {
          region_key: selectedMarket.region_key,
          campaign_id: selectedCampaign.id,
        },
        cancelToken: source.token,
      });

      setAvailableSubtypes(click_to_claim_subtypes);
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      if (!Axios.isCancel(e)) {
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    const source = Axios.CancelToken.source();
    loadAvailableSubtypes(source);
  }, []);

  const addVoucherRewardOption = (formData: AddRewardOptionFormValues) => {
    appendRewardOption({
      key: formData.key,
      position: position,
      reward_class: formData.reward_class,
      settings: {
        email_template_id: formData.settings.email_template_id,
        manual_email_template_id: formData.settings.manual_email_template_id,
        voucher_pool_id: formData.settings.voucher_pool_id,
      },
    });
  };

  const addPaypalRewardOption = (formData: AddRewardOptionFormValues) => {
    appendRewardOption({
      key: formData.key,
      position: position,
      reward_class: formData.reward_class,
      settings: {
        email_template_id: formData.settings.email_template_id,
        manual_email_template_id: formData.settings.manual_email_template_id,
        reward_cents: formData.settings.reward_cents,
        reward_currency: formData.settings.reward_currency,
      },
    });
  };

  const addHyperwalletRewardOption = (formData: AddRewardOptionFormValues) => {
    appendRewardOption({
      key: formData.key,
      position: position,
      reward_class: formData.reward_class,
      settings: {
        email_template_id: formData.settings.email_template_id,
        manual_email_template_id: formData.settings.manual_email_template_id,
        reward_cents: formData.settings.reward_cents,
        reward_currency: formData.settings.reward_currency,
      },
    });
  };

  const addOptionBasedRewardOption = (formData: AddRewardOptionFormValues) => {
    appendRewardOption({
      key: formData.key,
      position: position,
      reward_class: formData.reward_class,
      settings: {
        email_template_id: formData.settings.email_template_id,
        manual_email_template_id: formData.settings.manual_email_template_id,
        reward_option_id: formData.settings.reward_option_id,
      },
    });
  };

  const addRewardOption = (formData: AddRewardOptionFormValues) => {
    switch (formData.reward_class) {
      case "voucher":
        addVoucherRewardOption(formData);
        break;
      case "paypal":
        addPaypalRewardOption(formData);
        break;
      case "hyperwallet":
        addHyperwalletRewardOption(formData);
        break;
      case "runa":
      case "ifeelgoods":
        addOptionBasedRewardOption(formData);
        break;
      default:
        throw new Error("Invalid reward_class");
    }

    methods.reset();
  };

  const methods = useForm<AddRewardOptionFormValues>({
    mode: "onChange",
    defaultValues: {
      key: "",
      reward_class: "voucher",
      settings: {
        email_template_id: null,
        manual_email_template_id: null,
        voucher_pool_id: null,
        reward_option_id: null,
        reward_cents: null,
        reward_currency: "gbp",
      },
    },
  });

  const emailTemplateDropdownItems = itemsToDropdowItems(emailTemplates);
  const manualEmailTemplateDropdownItems = itemsToDropdowItems(
    manualRewardEmailTemplates
  );

  const isAvailableOptionKey = (key: string) =>
    !getValues(pathToRewardTypesArray)
      .map(
        (rewardType: {
          reward_options: RewardOption<RewardOptionSettings>[];
        }) => rewardType.reward_options.map((rewardOption) => rewardOption.key)
      )
      .flat()
      .includes(key);

  methods.watch("reward_class");

  const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  if (error) {
    return <ErrorAlert message={error} />;
  }

  return (
    <div
      className={cn(
        "p-5 space-y-7.5",
        "border border-backgroundSecondary rounded-xl",
        "w-87.5"
      )}
    >
      <h2 className={cn("px-0 pt-2.5", "text-text text-xl font-bold")}>
        Add a new reward option
      </h2>

      <FormProvider {...methods}>
        <div className="flex flex-col space-y-5">
          <Controller
            control={methods.control}
            name={"key"}
            rules={{
              required: { value: true, message: "Required" },
              validate: (value) =>
                isAvailableOptionKey(value) ||
                "This option identifier is already taken, please enter a different one",
            }}
            render={({ field: { onChange } }) => (
              <TextField
                elementType="input"
                label="Option identifier"
                aria-label="Option identifier"
                id={"key"}
                value={methods.getValues("key")}
                onChange={(e) => onChange(e)}
                validationState={
                  methods.formState.errors?.key ? "invalid" : "valid"
                }
                errorMessage={methods.formState.errors?.key?.message}
              />
            )}
          />

          <Controller
            control={methods.control}
            name={"reward_class"}
            render={({ field: { onChange } }) => (
              <TickerGroup
                value={methods.getValues("reward_class")}
                onChange={(e) => onChange(e)}
                label="Subtype"
                name={"reward_class"}
                id={"reward_class"}
              >
                {availableSubtypes.map((subtype) => {
                  return (
                    <Ticker key={subtype} value={subtype}>
                      {capitalize(subtype)}
                    </Ticker>
                  );
                })}
              </TickerGroup>
            )}
          />
          {methods.getValues("reward_class") === "paypal" && (
            <PaypalSubtypeFields
              emailTemplateDropdownItems={emailTemplateDropdownItems}
              manualEmailTemplateDropdownItems={
                manualEmailTemplateDropdownItems
              }
            />
          )}

          {methods.getValues("reward_class") === "hyperwallet" && (
            <HyperwalletSubtypeFields
              emailTemplateDropdownItems={emailTemplateDropdownItems}
              manualEmailTemplateDropdownItems={
                manualEmailTemplateDropdownItems
              }
            />
          )}

          {methods.getValues("reward_class") === "voucher" && (
            <VoucherSubtypeFields
              voucherPools={voucherPools}
              emailTemplateDropdownItems={emailTemplateDropdownItems}
              manualEmailTemplateDropdownItems={
                manualEmailTemplateDropdownItems
              }
            />
          )}

          {["runa", "ifeelgoods"].includes(
            methods.getValues("reward_class")
          ) && (
            <OptionBasedSubtypeFields
              rewards={rewards}
              emailTemplateDropdownItems={emailTemplateDropdownItems}
              manualEmailTemplateDropdownItems={
                manualEmailTemplateDropdownItems
              }
            />
          )}
        </div>

        <div className="w-32 mt-5">
          <Button
            appearance="secondary"
            text="Add"
            description="Add reward option"
            type="submit"
            handleOnClick={methods.handleSubmit(addRewardOption)}
          />
        </div>
      </FormProvider>
    </div>
  );
};

export default AddRewardOption;
