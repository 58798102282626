import React, { FC } from "react";

import {
  Group,
  GroupBody,
  GroupHeader,
  SubGroup,
  SubGroupBody,
  SubGroupHeader,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor";
import {
  FieldWithColorOptions,
  FieldWithFontSizeOptions,
  FieldWithFontWeightOptions,
  InputField,
} from "components/CampaignConfigurationEditor/GlobalStyleEditor/Fields";

const TextInputEditor: FC = () => (
  <Group>
    <GroupHeader text="Text input" />
    <GroupBody>
      <SubGroup>
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.text_input.background_color"
            label="Background color"
            showDefaultOption={true}
          />
          <FieldWithColorOptions
            id="style.text_input.color"
            label="Font color"
            showDefaultOption={true}
          />
          <FieldWithFontSizeOptions
            id="style.text_input.font_size"
            label="Font size"
          />
          <InputField id="style.text_input.height" label="Height" />
          <InputField
            id="style.text_input.border_radius"
            label="Border radius"
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Label"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.text_input.label.color"
            label="Font color"
            showDefaultOption={true}
          />
          <FieldWithFontSizeOptions
            id="style.text_input.label.font_size"
            label="Font size"
          />
          <FieldWithFontWeightOptions
            id="style.text_input.label.font_weight"
            label="Font weight"
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Focus state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.text_input._focus.border_color"
            label="Border color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
      <SubGroup>
        <SubGroupHeader text={"Error state"} />
        <SubGroupBody>
          <FieldWithColorOptions
            id="style.text_input.error.color"
            label="Color"
            showDefaultOption={false}
          />
        </SubGroupBody>
      </SubGroup>
    </GroupBody>
  </Group>
);

export default TextInputEditor;
