import React from "react";

import { useRadioGroup } from "@react-aria/radio";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { useRadioGroupState } from "@react-stately/radio";
import { SpectrumRadioGroupProps } from "@react-types/radio";

import { RadioContext } from "components/Radio/context";

interface RadioGroupProps extends SpectrumRadioGroupProps {
  className?: string;
}

export const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const { id, label, children, className } = props;
  const radioState = { ...props };
  radioState.orientation = "horizontal";
  const state = useRadioGroupState(radioState);
  const { radioGroupProps, labelProps } = useRadioGroup(radioState, state);
  const labelId = `${id}_label`;

  return (
    <fieldset
      {...radioGroupProps}
      className={className}
      aria-labelledby={labelId}
    >
      <VisuallyHidden>
        <label {...labelProps} id={labelId}>
          {label}
        </label>
      </VisuallyHidden>

      <RadioContext.Provider value={{ state }}>
        {children}
      </RadioContext.Provider>
    </fieldset>
  );
};
