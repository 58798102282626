import React, { Dispatch, FC, SetStateAction, useContext } from "react";

import Button from "components/Button";
import ModalContainer from "components/ModalContainer/ModalContainer";

import { LocalesContext, LocalesContextType } from "context/LocalesContext";

type ModalProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  localesAvailable: string[];
  nextLocale: string;
  goToPreviousLocale: () => void;
  initializeContent: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
};

const Modal: FC<ModalProps> = ({
  setShowModal,
  showModal,
  localesAvailable,
  nextLocale,
  initializeContent,
  goToPreviousLocale,
}) => {
  const localesMap = useContext<LocalesContextType>(LocalesContext);

  const handleInitialize = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    baseLocale: string
  ) => {
    e.preventDefault();
    initializeContent(baseLocale);
    setShowModal(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    goToPreviousLocale();
    setShowModal(false);
  };

  return (
    <ModalContainer
      title={`Initialize step content in ${localesMap[nextLocale]}`}
      showModal={showModal}
      setShowModal={setShowModal}
      onCloseModal={goToPreviousLocale}
    >
      <div className="flex flex-col gap-y-5">
        <p>There is no content for this step in {localesMap[nextLocale]}</p>
        <div className="flex flex-col gap-y-2.5">
          <p>
            Select the locale to use as a base to start drafting content for
            this step in {localesMap[nextLocale]}
          </p>
          {localesAvailable.map((localeAvailable) => (
            <Button
              key={localeAvailable}
              appearance="primary"
              text={`Copy from ${localesMap[localeAvailable]}`}
              handleOnClick={(e) => handleInitialize(e, localeAvailable)}
            />
          ))}
        </div>

        <Button
          appearance="danger"
          text="Cancel"
          handleOnClick={handleCancel}
        />
      </div>
    </ModalContainer>
  );
};

export default Modal;
