import { useContext } from "react";

import { HeaderDrawerContext } from "context";

import { HeaderState } from "context/HeaderDrawerContext/HeaderDrawerContext";

const useHeaderDrawer = (): HeaderState => {
  const { showDrawer, setShowDrawer, headerDrawer, setHeaderDrawer } =
    useContext(HeaderDrawerContext);

  return { showDrawer, setShowDrawer, headerDrawer, setHeaderDrawer };
};

export default useHeaderDrawer;
