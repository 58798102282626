import React from "react";

import { EditorComponent } from "interfaces";

import SchemaRenderer from "../../SchemaRenderer/SchemaRenderer";
import schema from "../schema.json";

const SharedFlowSettingsEditor: EditorComponent = ({ flowId }) => {
  const section = "settings";

  return (
    <>
      <SchemaRenderer
        schema={schema}
        flowId={flowId}
        section={section}
        locale={""}
        includeShared={false}
        journeyId={""}
      />
    </>
  );
};

export default SharedFlowSettingsEditor;
