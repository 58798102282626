import React, { FC } from "react";

type Props = {
  headers: string[];
};

const TableHead: FC<Props> = ({ headers }) => (
  <thead className="text-left text-text bg-light-surfacePrimary dark:bg-dark-surfacePrimary">
    <tr>
      {headers.map((header, index) => (
        <th key={`table-header-${index}`} className="px-4 py-2 font-medium">
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHead;
