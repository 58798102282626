import React from "react";

import { EditorComponent } from "interfaces";

import "components/CampaignConfigurationEditor/StepEditor/Editors";
import CaptureReferrerDetailsEditorWrapper from "components/CampaignConfigurationEditor/StepEditor/Editors/ReferrerJourney/CaptureReferrerDetailsEditor/CaptureReferrerDetailsEditorWrapper/CaptureReferrerDetailsEditorWrapper";
import SchemaRenderer from "components/CampaignConfigurationEditor/StepEditor/Editors/SchemaRenderer/SchemaRenderer";

import ContentEditorWrapper from "../../../ContentEditorWrapper/ContentEditorWrapper";
import schema from "../../schema.json";

const CaptureReferrerDetailsContentEditor: EditorComponent = ({
  flowId,
  locale,
  goToPreviousLocale,
}) => {
  const journeyId = "capture_referrer_details";
  const contentPath = `${flowId}.${journeyId}.content`;

  return (
    <CaptureReferrerDetailsEditorWrapper>
      <ContentEditorWrapper
        nextLocale={locale}
        contentPath={contentPath}
        goToPreviousLocale={goToPreviousLocale}
      >
        <SchemaRenderer
          schema={schema}
          flowId={flowId}
          journeyId={journeyId}
          section={"content"}
          locale={locale}
        />
      </ContentEditorWrapper>
    </CaptureReferrerDetailsEditorWrapper>
  );
};

export default CaptureReferrerDetailsContentEditor;
