import React, { FC, useContext } from "react";

import { IoMdSunny } from "react-icons/io";
import { IoMoon } from "react-icons/io5";

import { ThemeContext } from "context/ThemeContext";

const ThemeToggle: FC = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const isDark = theme === "dark";
  const ToggleIcon = isDark ? IoMdSunny : IoMoon;
  const toggleLabel = isDark ? "Toggle light theme" : "Toggle dark theme";

  return (
    <div
      className="fixed text-center bg-brand-primary w-12 h-12 bottom-10 right-10 rounded-full cursor-pointer hover:bg-brand-secondary"
      onClick={() => setTheme(isDark ? "light" : "dark")}
    >
      <ToggleIcon
        className="m-auto h-full text-button-text w-6"
        aria-label={toggleLabel}
      />
    </div>
  );
};

export default ThemeToggle;
