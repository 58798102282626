import React, { FC, useContext, useEffect, useState } from "react";

import {
  CustomField,
  EligibilityCheck,
  EmailTemplate,
  ItemWithRequiredConfig,
  OptionTypeBase,
} from "interfaces";
import { pick } from "lodash";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import Button from "components/Button";
import { requiredConfigFor } from "components/CampaignConfigurationEditor/StepEditor/Editors/utils";
import Dropdown from "components/Dropdown/Dropdown";
import { Fieldset } from "components/Fieldset/Fieldset";
import { CancelIcon } from "components/Icon";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";
import { Ticker, TickerGroup } from "components/Ticker";

import {
  FormHelpersContext,
  FormHelpersContextType,
} from "../../../../../context/FormHelpersContext";

type EligibilityCheckSettingsEditorProps = {
  eligibilityChecksPath: string;
  configurableEligibilityChecks: EligibilityCheck[];
  eligibilityChecksConfigurationPath?: string;
};

const EligibilityCheckSettingsEditor: FC<
  EligibilityCheckSettingsEditorProps
> = ({
  eligibilityChecksPath,
  configurableEligibilityChecks,
  eligibilityChecksConfigurationPath,
}) => {
  const { getValues, control, setValue } = useFormContext();

  const formHelpers = useContext<FormHelpersContextType>(FormHelpersContext);

  if (eligibilityChecksConfigurationPath) {
    // fetch the config if the path is supplied
    const parts = eligibilityChecksConfigurationPath.split(".");

    configurableEligibilityChecks = (parts.reduce(
      (response, current) => {
        return response && response[current];
      },
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      formHelpers.campaign_configurations as { [key: string]: any }
    ) || []) as ItemWithRequiredConfig[];
  }

  const [headers, setHeaders] = useState<{ [key: string]: string }>(
    getValues(`${eligibilityChecksPath}.config.headers`) || {}
  );

  const [newHeaderKey, setNewHeaderKey] = useState("");
  const [newHeaderValue, setNewHeaderValue] = useState("");

  useWatch({
    name: [`${eligibilityChecksPath}.type`],
  });

  const configurableToDropdowItems = (
    configurables: EmailTemplate[] | CustomField[] | EligibilityCheck[]
  ) =>
    configurables.map(
      (configurable: EmailTemplate | CustomField | EligibilityCheck) => ({
        label: configurable.name,
        value: configurable.id,
      })
    );

  const showEligibilityCheckConfigField = (key: string) =>
    requiredEligibilityCheckConfigFor(
      getValues(`${eligibilityChecksPath}.type`)
    ).includes(key);

  const eligibilityCheckDropdownItems = configurableToDropdowItems(
    configurableEligibilityChecks
  );
  const selectedEligibilityCheckDropdownItem =
    eligibilityCheckDropdownItems.find(
      (eligibilityCheck) =>
        eligibilityCheck.value === getValues(`${eligibilityChecksPath}.type`)
    ) || null;

  const requiredEligibilityCheckConfigFor = (type: string) =>
    requiredConfigFor(type, configurableEligibilityChecks);

  const removeIrrelevantConfigKeys = () => {
    const existingConfig = getValues(`${eligibilityChecksPath}.config`);
    const requiredConfig = requiredEligibilityCheckConfigFor(
      getValues(`${eligibilityChecksPath}.type`)
    );

    setValue(
      `${eligibilityChecksPath}.config`,
      pick(existingConfig, requiredConfig)
    );
  };

  const type = useWatch({
    name: `${eligibilityChecksPath}.type`,
  });

  const resetNewHeaderForm = () => {
    setNewHeaderKey("");
    setNewHeaderValue("");
  };

  const addHeader = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const newHeaders = { ...headers };
    newHeaders[newHeaderKey] = newHeaderValue;
    setHeaders(newHeaders);
    resetNewHeaderForm();
  };

  const removeHeader = (key: string) => {
    const newHeaders = { ...headers };
    delete newHeaders[key];
    setHeaders(newHeaders);
  };

  const handleNewHeaderKeyChange = (value: string) => {
    setNewHeaderKey(value);
  };

  const handleNewHeaderValueChange = (value: string) => {
    setNewHeaderValue(value);
  };

  const emailDomainsItems = () =>
    getValues(`${eligibilityChecksPath}.config.email_domains`)?.map(
      (emailDomain: string) => {
        return { value: emailDomain, label: emailDomain };
      }
    ) || null;

  useEffect(() => {
    removeIrrelevantConfigKeys();
  }, [type]);

  useEffect(() => {
    setValue(`${eligibilityChecksPath}.config.headers`, headers);
  }, [headers]);

  return (
    <>
      <Controller
        control={control}
        name={`${eligibilityChecksPath}.type`}
        rules={{
          required: {
            value: true,
            message:
              "Please either select an eligibility check type or disable the eligible check",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <Dropdown
            id={`${eligibilityChecksPath}.type`}
            value={selectedEligibilityCheckDropdownItem}
            items={eligibilityCheckDropdownItems}
            onChange={(e) => onChange((e as OptionTypeBase).value)}
            label="Eligibility check type"
            validationState={error ? "invalid" : "valid"}
            errorMessage={error?.message}
          />
        )}
      />
      {showEligibilityCheckConfigField("url") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.url`}
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message:
                "Please make sure the endpoint is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.url`}
              label="Endpoint URL"
              aria-label="Endpoint URL"
              value={getValues(`${eligibilityChecksPath}.config.url`)}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("login_url") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.login_url`}
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message:
                "Please make sure the endpoint is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.login_url`}
              label="Login URL"
              aria-label="Login URL"
              value={getValues(`${eligibilityChecksPath}.config.login_url`)}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("customer_profiles_url") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.customer_profiles_url`}
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message:
                "Please make sure the endpoint is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.customer_profiles_url`}
              label="Customer profiles URL"
              aria-label="Customer profiles URL"
              value={getValues(
                `${eligibilityChecksPath}.config.customer_profiles_url`
              )}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("sales_history_base_url") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.sales_history_base_url`}
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message:
                "Please make sure the endpoint is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.sales_history_base_url`}
              label="Sales history base URL"
              aria-label="Sales history base URL"
              value={getValues(
                `${eligibilityChecksPath}.config.sales_history_base_url`
              )}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("resource") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.resource`}
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message:
                "Please make sure the endpoint is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.resource`}
              label="Resource URL"
              aria-label="Resource URL"
              value={getValues(`${eligibilityChecksPath}.config.resource`)}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("headers") && (
        <>
          {Object.keys(headers).length > 0 && (
            <Fieldset legend="Headers">
              <ul>
                {Object.entries(headers).map(([headerKey, headerValue]) => (
                  <li
                    key={`header_${headerKey}`}
                    className="flex flex-row items-center gap-x-5"
                  >
                    <div>
                      {headerKey}: {headerValue}
                    </div>
                    <div
                      data-testid={`remove-${headerKey}`}
                      onClick={() => removeHeader(headerKey)}
                      className="cursor-pointer opacity-40 hover:opacity-100"
                    >
                      <CancelIcon size={24} />
                    </div>
                  </li>
                ))}
              </ul>
            </Fieldset>
          )}
          <div className="flex flex-row items-end gap-x-5">
            <TextField
              elementType="input"
              type="text"
              id="new_header_key"
              value={newHeaderKey}
              label="Header key"
              aria-label="Enter the new header key"
              onChange={(e) => handleNewHeaderKeyChange(e)}
            />
            {": "}
            <TextField
              elementType="input"
              type="text"
              id="new_header_value"
              value={newHeaderValue}
              label="Header value"
              aria-label="Enter the new header value"
              onChange={(e) => handleNewHeaderValueChange(e)}
            />
            <Button
              appearance="secondary"
              text="Add header"
              handleOnClick={addHeader}
            />
          </div>
        </>
      )}
      {showEligibilityCheckConfigField("proxy") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.proxy`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.proxy`}
              isSelected={getValues(`${eligibilityChecksPath}.config.proxy`)}
              onChange={onChange}
            >
              <span className="font-bold">Use proxy?</span>
            </Switch>
          )}
        />
      )}
      {showEligibilityCheckConfigField("email_checker_class") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.email_checker_class`}
          defaultValue="EmailCheckers::AllowDomains"
          render={({ field: { onChange } }) => (
            <TickerGroup
              value={getValues(
                `${eligibilityChecksPath}.config.email_checker_class`
              )}
              onChange={(e) => onChange(e)}
              label="Would you like to allow or prevent the users with the specified email address domains to register?"
              name={`${eligibilityChecksPath}.config.email_checker_class`}
              id={`${eligibilityChecksPath}.config.email_checker_class`}
            >
              <Ticker value={"EmailCheckers::AllowDomains"}>
                Only allow users to register if their email address matches one
                of the specified email address domains
              </Ticker>
              <Ticker value={"EmailCheckers::DisallowDomains"}>
                Prevent users from registering if their email address matches
                one of the specified email address domains
              </Ticker>
            </TickerGroup>
          )}
        />
      )}
      {showEligibilityCheckConfigField("email_domains") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.email_domains`}
          defaultValue={[]}
          rules={{
            required: {
              value: true,
              message: "Please specify at least one email domain",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <Dropdown
              isMulti={true}
              isCreatable={true}
              label="List of email address domains"
              id={`${eligibilityChecksPath}.config.email_domains`}
              items={[]}
              createLabel="Add email domain:"
              placeholder="Enter a new email domain to add it to the list"
              noOptionsMessage="Enter a new email domain to add it to the list"
              value={emailDomainsItems}
              onChange={(emailDomainsItems) =>
                onChange(
                  emailDomainsItems.map(
                    (emailDomainsItem: { label: string; value: string }) =>
                      emailDomainsItem.value
                  )
                )
              }
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("always_allow_preregistered") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.always_allow_preregistered`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.always_allow_preregistered`}
              isSelected={getValues(
                `${eligibilityChecksPath}.config.always_allow_preregistered`
              )}
              onChange={onChange}
            >
              Always allow pre-registered referrers to sign up?
            </Switch>
          )}
        />
      )}
      {showEligibilityCheckConfigField("scoped_to_campaign") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.scoped_to_campaign`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.scoped_to_campaign`}
              isSelected={getValues(
                `${eligibilityChecksPath}.config.scoped_to_campaign`
              )}
              onChange={onChange}
            >
              Should the eligibility check be scoped to the campaign?
            </Switch>
          )}
        />
      )}
      {showEligibilityCheckConfigField("validate_identifier") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.validate_identifier`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.validate_identifier`}
              isSelected={getValues(
                `${eligibilityChecksPath}.config.validate_identifier`
              )}
              onChange={onChange}
            >
              Should the eligibility check validate the identifier?
            </Switch>
          )}
        />
      )}
      {showEligibilityCheckConfigField("swagger_environment") && (
        <Controller
          control={control}
          defaultValue=""
          name={`${eligibilityChecksPath}.config.swagger_environment`}
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              type="text"
              id={`${eligibilityChecksPath}.config.swagger_environment`}
              label="Swagger environment"
              aria-label="Swagger environment"
              value={getValues(
                `${eligibilityChecksPath}.config.swagger_environment`
              )}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showEligibilityCheckConfigField("include_additional_friend_details") && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.include_additional_friend_details`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.include_additional_friend_details`}
              isSelected={getValues(
                `${eligibilityChecksPath}.config.include_additional_friend_details`
              )}
              onChange={onChange}
            >
              <span className="font-bold">
                Include Friend&apos;s name and custom field values in the
                request?
              </span>
            </Switch>
          )}
        />
      )}
      {showEligibilityCheckConfigField(
        "include_additional_referrer_details"
      ) && (
        <Controller
          control={control}
          name={`${eligibilityChecksPath}.config.include_additional_referrer_details`}
          defaultValue={false}
          render={({ field: { onChange } }) => (
            <Switch
              id={`${eligibilityChecksPath}.config.include_additional_referrer_details`}
              isSelected={getValues(
                `${eligibilityChecksPath}.config.include_additional_referrer_details`
              )}
              onChange={onChange}
            >
              <span className="font-bold">
                Include Referrer&apos;s email in the request?
              </span>
            </Switch>
          )}
        />
      )}
    </>
  );
};

export default EligibilityCheckSettingsEditor;
