import React, { FC, useEffect, useState } from "react";

import { Controller, useFormContext, useWatch } from "react-hook-form";

import Button from "components/Button";
import { Fieldset } from "components/Fieldset/Fieldset";
import { Switch } from "components/Switch/Switch";
import { TextField } from "components/TextField/TextField";
import { Ticker, TickerGroup } from "components/Ticker";

type CallToActionContentEditorProps = {
  field: Record<"id", string>;
  index: number;
  contentPath: string;
  bpToggleDisplayed: boolean;
  removeCallToAction: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => void;
};

const CallToActionContentEditor: FC<CallToActionContentEditorProps> = ({
  contentPath,
  field,
  index,
  removeCallToAction,
  bpToggleDisplayed,
}) => {
  const { getValues, control, unregister } = useFormContext();

  const format = useWatch({
    name: `${contentPath}.${index}.button_class`,
  });

  const [showTileFormatFields, setShowTileFormatFields] = useState<boolean>(
    format === "tile"
  );

  useEffect(() => {
    if (!showTileFormatFields) {
      unregister(`${contentPath}.${index}.heading`);
      unregister(`${contentPath}.${index}.description`);
      unregister(`${contentPath}.${index}.image`);
      unregister(`${contentPath}.${index}.action_id`);
    }
  }, [showTileFormatFields]);

  return (
    <Fieldset key={field.id} legend={`Call to action #${index + 1}`}>
      <Controller
        control={control}
        key={`${contentPath}.${index}.button_class`}
        name={`${contentPath}.${index}.button_class`}
        defaultValue="basic"
        render={({ field: { onChange } }) => (
          <TickerGroup
            id={`${contentPath}.${index}.button_class`}
            label={"Format"}
            aria-label={"Format"}
            value={getValues(`${contentPath}.${index}.button_class`)}
            onChange={(e) => {
              onChange(e);
              setShowTileFormatFields(e === "tile");
            }}
          >
            <Ticker value={"basic"}>Simple button</Ticker>
            <Ticker value={"tile"}>Button within a tile</Ticker>
          </TickerGroup>
        )}
      />
      {showTileFormatFields && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.image.url`}
          name={`${contentPath}.${index}.image.url`}
          defaultValue=""
          rules={{
            required: { value: true, message: "Required" },
            pattern: {
              value: /^https/,
              message: "Please make sure the URL is using the https protocol",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              id={`${contentPath}.${index}.image.url`}
              label={"Tile image URL"}
              aria-label={"Tile image URL"}
              value={getValues(`${contentPath}.${index}.image.url`)}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {showTileFormatFields && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.image.alt_text`}
          name={`${contentPath}.${index}.image.alt_text`}
          defaultValue=""
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              id={`${contentPath}.${index}.image.alt_text`}
              label={"Tile image alt text"}
              aria-label={"Tile image alt text"}
              value={getValues(`${contentPath}.${index}.image.alt_text`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showTileFormatFields && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.heading`}
          name={`${contentPath}.${index}.heading`}
          defaultValue=""
          render={({ field: { onChange } }) => (
            <TextField
              elementType="input"
              id={`${contentPath}.${index}.heading`}
              label={"Tile heading"}
              aria-label={"Tile heading"}
              value={getValues(`${contentPath}.${index}.heading`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      {showTileFormatFields && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.description`}
          name={`${contentPath}.${index}.description`}
          defaultValue=""
          render={({ field: { onChange } }) => (
            <TextField
              elementType="textarea"
              id={`${contentPath}.${index}.description`}
              label={"Tile description"}
              aria-label={"Tile description"}
              value={getValues(`${contentPath}.${index}.description`)}
              onChange={(e) => onChange(e)}
            />
          )}
        />
      )}
      <Controller
        control={control}
        key={`${contentPath}.${index}.text`}
        name={`${contentPath}.${index}.text`}
        render={({ field: { onChange } }) => (
          <TextField
            elementType="input"
            id={`${contentPath}.${index}.text`}
            label={"Button text"}
            aria-label={"Button text"}
            value={getValues(`${contentPath}.${index}.text`)}
            onChange={(e) => onChange(e)}
          />
        )}
      />
      <Controller
        control={control}
        key={`${contentPath}.${index}.url`}
        name={`${contentPath}.${index}.url`}
        rules={{
          required: { value: true, message: "Required" },
          pattern: {
            value: /^https|tel:/,
            message:
              "Please make sure the URL is using the https protocol for links or tel for numbers",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <TextField
            elementType="input"
            id={`${contentPath}.${index}.url`}
            label={"Button URL"}
            aria-label={"Button URL"}
            value={getValues(`${contentPath}.${index}.url`)}
            onChange={(e) => onChange(e)}
            validationState={error ? "invalid" : "valid"}
            errorMessage={error?.message}
          />
        )}
      />
      {showTileFormatFields && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.action_id`}
          name={`${contentPath}.${index}.action_id`}
          defaultValue=""
          rules={{
            required: { value: true, message: "Required" },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              elementType="input"
              id={`${contentPath}.${index}.action_id`}
              label={"Tile action identifier"}
              aria-label={"Tile action identifier"}
              value={getValues(`${contentPath}.${index}.action_id`)}
              onChange={(e) => onChange(e)}
              validationState={error ? "invalid" : "valid"}
              errorMessage={error?.message}
            />
          )}
        />
      )}
      {bpToggleDisplayed && (
        <Controller
          control={control}
          key={`${contentPath}.${index}.remove_bp_parameters_from_url`}
          name={`${contentPath}.${index}.remove_bp_parameters_from_url`}
          defaultValue={false}
          render={({ field: { onChange, value } }) => {
            return (
              <Switch
                id={`${contentPath}.${index}.remove_bp_parameters_from_url`}
                isSelected={value}
                onChange={(value) => onChange(value)}
              >
                Remove BP Parameters from URL
              </Switch>
            );
          }}
        />
      )}
      {index > 0 && (
        <Button
          appearance="danger"
          text="Remove"
          handleOnClick={(e) => removeCallToAction(e, index)}
        />
      )}
    </Fieldset>
  );
};

export default CallToActionContentEditor;
